.circle-diagram {
	display: block;
	margin: 10px auto;
	max-height: 125px;
}

.circle-bg {
	fill: none;
	stroke: $main1;
}

.circle {
	fill: none;
	stroke-linecap: round;
	animation: progress 1s ease-out forwards;
}

@keyframes progress {
	0% {
		stroke-dasharray: 0 100;
	}
}

.text {
	fill: $textColor;
	text-anchor: middle;
}
