@-moz-keyframes heartbit {
	0% {
		-moz-transform: scale(0);
		opacity: 0;
	}
	25% {
		-moz-transform: scale(0.1);
		opacity: 0.1;
	}
	50% {
		-moz-transform: scale(0.5);
		opacity: 0.3;
	}
	75% {
		-moz-transform: scale(0.8);
		opacity: 0.5;
	}
	to {
		-moz-transform: scale(1);
		opacity: 0;
	}
}
@-webkit-keyframes heartbit {
	0% {
		-webkit-transform: scale(0);
		opacity: 0;
	}
	25% {
		-webkit-transform: scale(0.1);
		opacity: 0.1;
	}
	50% {
		-webkit-transform: scale(0.5);
		opacity: 0.3;
	}
	75% {
		-webkit-transform: scale(0.8);
		opacity: 0.5;
	}
	to {
		-webkit-transform: scale(1);
		opacity: 0;
	}
}

@keyframes heartbit {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	25% {
		transform: scale(0.1);
		opacity: 0.1;
	}
	50% {
		transform: scale(0.5);
		opacity: 0.3;
	}
	75% {
		transform: scale(0.8);
		opacity: 0.5;
	}
	to {
		transform: scale(1);
		opacity: 0;
	}
}

@keyframes pulse {
	0% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
	50% {
		filter: brightness(1.25);
		-webkit-filter: brightness(1.25);
	}
	100% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
}
@-webkit-keyframes pulse {
	0% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
	50% {
		filter: brightness(1.25);
		-webkit-filter: brightness(1.25);
	}
	100% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
}
@-moz-keyframes pulse {
	0% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
	50% {
		filter: brightness(1.25);
		-webkit-filter: brightness(1.25);
	}
	100% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
}
@keyframes pulseDark {
	0% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
	50% {
		filter: brightness(0.925);
		-webkit-filter: brightness(0.925);
	}
	100% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
}
@-webkit-keyframes pulseDark {
	0% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
	50% {
		filter: brightness(0.925);
		-webkit-filter: brightness(0.925);
	}
	100% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
}
@-moz-keyframes pulseDark {
	0% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
	50% {
		filter: brightness(0.925);
		-webkit-filter: brightness(0.925);
	}
	100% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
}
