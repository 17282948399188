@import "./variables.scss";

html body.theme-transition div#root {
	&,
	* {
		transition-property: color, background, fill;
		transition-duration: 250ms;
		transition-timing-function: linear;
		transition-delay: 0ms;
	}
}
.Polaris-Layout__AnnotatedSection + .Polaris-Layout__AnnotatedSection {
	border-color: $borderColor !important;
}

.Polaris-Navigation__PrimaryNavigation::-webkit-scrollbar {
	display: none;
}
.Polaris-Navigation__PrimaryNavigation {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.chat-window {
	overflow-y: auto;
	.Polaris-LegacyCard {
		height: 100%;
	}
}

.hide-search .Polaris-Filters-ConnectedFilterControl__CenterContainer {
	display: none;
}
.unlayer_container > div {
	height: 100%;
}

.player-wrapper {
	position: relative;
	padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}
.clockHolder {
	width: 100%;
	text-align: right;
	// background-color: red;
	margin-top: 10px;
	margin-bottom: 10px;
}

.clockHolder div {
	color: black;
	font-size: 25px;
	text-align: right !important;
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
}
.human1Bg {
	background-image: url("../../public/assets/images/humans1.png");
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: 70% auto;
}

.trackVisitorBg {
	background-image: url("../../public/assets/images/website_traffic.png");
	background-repeat: no-repeat;
	background-position: left;
	background-size: 25% auto;
}
.office365Bg {
	background-image: url("../../public/assets/images/Office-365-Cloud-Logo.png");
	background-repeat: no-repeat;
	background-position: right;
	background-size: 25% auto;
}

.transparentVideoHolder {
	width: 100%;
}
.greyRoundedCard {
	width: 100%;
	background-color: #efefef;
	border-radius: 7px;
	padding: 40px;

	.header {
		font-size: 30px;
		color: #25aae1;
	}

	.subHeader {
		font-size: 30px;
		margin-top: 20px;
	}

	.subHeader2 {
		font-size: 17px;
		margin-top: 20px;
		line-height: 22px;
	}
}
.mainDashboard {
	.Polaris-Tabs__Tab--selected,
	.Polaris-Tabs__Tab--active {
		.Polaris-Tabs__Title {
			transition: filter 250ms;
			-webkit-transition: filter 250ms;
			-moz-transition: filter 250ms;
			-ms-transition: filter 250ms;
			-o-transition: filter 250ms;

			border-bottom: var(--p-override-none, 0.1875rem) solid #25aae1;
		}
	}
}

.Polaris-Frame__Navigation {
	z-index: 516;
}
@media (min-width: 769px) {
	.Polaris-Frame__Navigation {
		z-index: 511;
	}
}

.Polaris-Frame__Main {
	.Polaris-Tabs__Wrapper {
		background-color: white;
	}
}

.Polaris-Navigation {
	// background-color: #363b3f !important;
	.Polaris-Navigation__Icon svg {
		fill: $sidebarColor;
	}

	.Polaris-Navigation__Item:hover .Polaris-Navigation__Icon svg {
		fill: $sidebarColor;
	}

	.Polaris-Navigation__Item:focus .Polaris-Navigation__Icon svg {
		fill: $sidebarColor;
	}

	.Polaris-Navigation__Item:active .Polaris-Navigation__Icon svg {
		fill: $sidebarColor;
	}

	.Polaris-Navigation__Action:hover {
		background: transparent;
		.Polaris-Icon__Svg {
			fill: $sidebarColor;
		}
	}

	.Polaris-Navigation__Item {
		position: relative;
		transition: 100ms;
		-webkit-transition: 100ms;
		-moz-transition: 100ms;
		-ms-transition: 100ms;
		-o-transition: 100ms;

		.Polaris-Navigation__Action {
			display: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			margin: 0;
			top: 0;
			right: 0;
			background: none;
			border: none;
			font-size: inherit;
			line-height: inherit;
			cursor: pointer;
			align-items: center;
			padding: 0.25rem 1rem;
			border-radius: var(--p-border-radius-base, 3px);
			position: absolute;
			height: 100%;

			svg {
				fill: var(--p-icon-subdued, #919eab);
				color: var(--p-override-transparent, white);
			}
		}

		&:hover {
			background: none;
			.Polaris-Navigation__Action {
				display: flex;
			}
		}
	}
}

// .Polaris-Frame {
// 	background-color: #f9f9f9 !important;
// }
iframe {
	background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%23919eab" x="50%" y="33%" font-family="-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif" font-size="24" text-anchor="middle">H&auml;mtar data...</text></svg>')
		0px 0px no-repeat;
}
//Messes upp when used wit other Sheets/modals
// body .Polaris-PositionedOverlay {
// 	z-index: 99999999;
// }
.Polaris-Stack__Item--fill {
	overflow: hidden;
	word-break: break-word;
}

.heartbit {
	position: absolute;
	top: -11px;
	right: -11px;
	height: 24px;
	width: 24px;
	z-index: 10;
	border: 5px solid #e46a76;
	border-radius: 70px;
	animation: heartbit 1s ease-out;
	animation-iteration-count: infinite;
	-moz-animation: heartbit 1s ease-out;
	-moz-animation-iteration-count: infinite;
	-o-animation: heartbit 1s ease-out;
	-o-animation-iteration-count: infinite;
	-webkit-animation: heartbit 1s ease-out;
	-webkit-animation-iteration-count: infinite;
}

.Polaris-DescriptionList__Description {
	text-align: right;
}
// @keyframes chatpulse {
// 	0% {
// 		background: #25aae1;
// 	}
// 	50% {
// 		background: #1e7094;
// 	}
// 	100% {
// 		background: #25aae1;
// 	}
// }
.chats {
	position: relative;
	// width: 56px;
	// height: 56px;
	// padding-top: 20px;
	// background-color: #25aae1;
	button {
		.Polaris-Icon {
			width: 22px;
			height: 20px;
			svg {
				fill: #cecece;
			}
		}
	}
	&.unread {
		button {
			.Polaris-Icon {
				svg {
					fill: #fff;
				}
			}
		}

		.MessageIndicator {
			width: 6px;
			height: 6px;
			-webkit-border-radius: 30px;
			-moz-border-radius: 30px;
			border-radius: 30px;
			background-color: #e46a76;
			position: absolute;
			right: -2px;
			top: -2px;
		}
		// animation: chatpulse 1s infinite linear;
		// -webkit-animation: chatpulse 1s infinite linear;
	}
}
.notifications {
	position: relative;
	button {
		.Polaris-Icon {
			width: 22px;
			height: 22px;
			svg {
				fill: #cecece;
			}
		}
	}
	&.unread {
		button {
			.Polaris-Icon {
				svg {
					fill: #fff;
				}
			}
		}
	}
	.MessageIndicator {
		width: 6px;
		height: 6px;
		-webkit-border-radius: 30px;
		-moz-border-radius: 30px;
		border-radius: 30px;
		background-color: #e46a76;
		position: absolute;
		right: -2px;
		top: -2px;
	}
}
.sel {
	&:hover {
		.selhovshow {
			display: block;
		}
	}
	.selhovshow {
		font-size: 10px;
		position: absolute;
		cursor: pointer;
		display: none;
	}
}

.upload_cont {
	.Polaris-Labelled--hidden {
		height: 100%;
		.Polaris-DropZone {
			height: 100%;
		}
	}
}

.holder_inner {
	position: relative;
	top: -40px;
	-webkit-transition: 0.5s all ease;
	transition: 0.5s all ease;
	min-height: 100px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	padding-top: 20px;

	&.loading {
		opacity: 0.5;
	}
	&:hover {
		top: -50px;
	}
}
iframe.dashy {
	height: calc(100vh - 125px);
}
.clickable {
	.Polaris-DataTable__TableRow {
		cursor: pointer;
	}
}

.holder_inner2 {
	position: relative;
	top: -40px;
	-webkit-transition: 0.5s all ease;
	transition: 0.5s all ease;
	min-height: 80px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	padding-top: 20px;
	width: 80px;
	.Polaris-Icon {
		pointer-events: none;
		.Polaris-Icon__Svg {
			fill: white;
			margin-top: 10px;
		}
	}
	&.loading {
		opacity: 0.5;
	}
	&:hover {
		top: -50px;
	}
	&.compact {
		min-height: 40px;
		height: 40px;
		width: 40px;
		top: -28px;
		padding-top: 1px;
	}
}
.holder_inner2_right {
	position: relative;
	top: -80px;
	right: 10px;
	margin-left: 100px;
	text-align: right;
	font-weight: 300;
	font-size: 30px;
	&.compact {
		font-size: 25px;
		top: -50px;
	}
}
.jss653 {
	color: #ccc;
	min-height: auto;
	font-weight: 300;
	font-size: 20px;
	margin-bottom: 3px;
	text-decoration: none;
}
.jss652 {
	color: #ccc;
	margin-top: -22px;
	min-height: auto;
	font-weight: 300;
	font-size: 20px;
	margin-bottom: 3px;
	text-decoration: none;
	&.compact {
		font-size: 16px;
		margin-top: -26px;
	}
}
.jss655 {
	color: #999;
	margin: 0;
	font-size: 14px;
	margin-top: 0;
	padding-top: 10px;
	margin-bottom: 0;
}
.dashboard_section2 {
	margin-top: 20px;
	margin-bottom: 40px;
}
.dashboard {
	.Polaris-LegacyCard {
		overflow: visible;
		margin-top: 25px;
	}
	.top_section {
		background-color: #000;
		width: 100%;
		height: 84px;
	}
	.Polaris-Tabs {
		border-bottom: 0;
		margin-top: 15px;
	}
	.Polaris-Layout {
		margin-top: 15px;
	}
}
.blue_gradient {
	background: linear-gradient(60deg, darken($blue, 3%), lighten($blue, 3%));
}
.orange_gradient {
	background: linear-gradient(60deg, darken($orange, 3%), lighten($orange, 3%));
}
.pink_gradient {
	background: linear-gradient(60deg, darken($pink, 3%), lighten($pink, 3%));
}
.purple_gradient {
	background: linear-gradient(60deg, darken($purple, 3%), lighten($purple, 3%));
}
.red_gradient {
	background: linear-gradient(60deg, darken($red, 3%), lighten($red, 3%));
}
.yellow_gradient {
	background: linear-gradient(60deg, darken($yellow, 3%), lighten($yellow, 3%));
}
.green_gradient {
	background: linear-gradient(60deg, darken($green, 3%), lighten($green, 3%));
}
.calendar-event {
	color: #fff;
	&.default {
		background-color: #637381;
	}
	&.info {
		background-color: #47c1bf;
	}
	&.critical {
		background-color: #de3618;
	}
	&.success {
		background-color: #50b83c;
	}
	&.attention {
		background-color: #eec200;
	}
}

html,
body {
	height: 100%;
}
.Polaris-Navigation__SecondaryAction {
	max-width: 50px !important;
}
.myHighlightClass {
}
.people .Polaris-ResourceList-Item {
	background-color: #fdf7e3;
}
.Polaris-Navigation__Action {
}
.Polaris-Banner {
	> div:not(.Polaris-Banner__Ribbon):not(.Polaris-Banner__Dismiss) {
		width: 100%;
	}
}
.Polaris-ResourceList__HeaderOuterWrapper + .Polaris-ResourceList {
	border-top: 0.0625rem solid $main1;
}
.Polaris-ResourceList__ItemWrapper + .Polaris-ResourceList__ItemWrapper {
	border-top: 0.0625rem solid $main1;
}

.calendarEvent {
	position: relative;
	.action {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
	}
	&:hover {
		padding-right: 20px;
		.action {
			display: block;
		}
	}
}
a.settings-item {
	max-width: 270px;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.25rem;
	text-transform: initial;
	letter-spacing: initial;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0.75rem;
	border: 0;
	color: #212b36;
	cursor: pointer;
	border-radius: 3px;
	text-decoration: none;
	.icon-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		width: 2.5rem;
		height: 2.5rem;
		margin-right: 1rem;
		border-radius: 3px;
		background-color: #f4f6f8;
		fill: #919eab;
	}
	p {
		font-weight: 600;
		margin: 0;
		color: black;
	}
	span {
		color: #637381;
		margin: 0;
		display: block;
	}
	&:hover {
		// background-color: #f9f9f9;
		// .icon-wrapper {
		// 	background-color: #eee;
		// }
	}
}
.relative {
	position: relative;
	.Polaris-Scrollable {
		padding: 1px;
		padding-bottom: 20px;
	}
}
.shadow-overlay {
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 15;
}
.nakedlist {
	.Polaris-LegacyCard {
		background-color: transparent;
		box-shadow: none;
		border: 0;
	}
	.resourcelistwrapper {
		padding-top: 15px;
	}
	margin-bottom: 20px;
}
.sales_pipe {
	> .Polaris-Stack {
		> .Polaris-Stack__Item {
			min-width: 300px;
		}
	}
}
.pipe {
	&.dark {
		> .Polaris-LegacyCard {
			> .Polaris-Card__Header {
				[class*="Polaris-Text--heading"] {
					color: #ffffff;
				}
			}
		}
	}
	.Polaris-ResourceList-CheckableButton__Label {
		margin-left: 10px;
	}
	border-radius: 3px;
	> .Polaris-LegacyCard {
		background-color: transparent;
	}
	&.empty {
		.Polaris-Card__Section,
		.Polaris-LegacyCard__Section {
			background-color: rgba(255, 255, 255, 0.5);
		}
	}
	.Polaris-ResourceList {
		background-color: rgba(255, 255, 255, 0.5);
	}
	.Polaris-ResourceList__HeaderContentWrapper {
		left: 6px;
	}
	.Polaris-ResourceList__BulkActionsWrapper {
		left: -10px;
	}
	.Polaris-ResourceList-Item__Content {
		margin-left: -20px;
	}
}
.truncate {
	position: relative;
	overflow: hidden;
	&::after {
		background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), #fff), -webkit-linear-gradient(left, rgba(255, 255, 255, 0), #fff);
		background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0), #fff), -moz-linear-gradient(left, rgba(255, 255, 255, 0), #fff);
		background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0), #fff), -ms-linear-gradient(left, rgba(255, 255, 255, 0), #fff);
		background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0), #fff), -o-linear-gradient(left, rgba(255, 255, 255, 0), #fff);

		content: " ";
		height: 100%;
		position: absolute;
		right: 0px;
		top: 0;
		width: 25px;
		z-index: 10;
	}
	* {
		white-space: nowrap;
	}
}
.Polaris-Scrollable--hasLeftShadow {
	box-shadow: inset 1.25rem 0 1.25rem -1.25rem rgba(0, 0, 0, 0.1);
	border-left: 1px solid #eee;
}
.Polaris-Scrollable--hasRightShadow {
	box-shadow: inset -1.25rem 0 1.25rem -1.25rem rgba(0, 0, 0, 0.1);
	border-right: 1px solid #eee;
}
.Polaris-Scrollable--hasRightShadow.Polaris-Scrollable--hasLeftShadow {
	box-shadow: inset -1.25rem 0 1.25rem -1.25rem rgba(0, 0, 0, 0.1), inset 1.25rem 0 1.25rem -1.25rem rgba(0, 0, 0, 0.1);
}

$vertical-padding: (control-height() - line-height(body)) / 2;
$horizontal-padding: spacing(tight);
$icon-size: rem(20px);

$difference-between-touch-area-and-backdrop: control-height() - control-slim-height();

$stacking-order: (
	focus-backdrop: 10,
	content: 20,
);

// .Polaris-Page-Header__Navigation a {
// 	// @include text-style-body;
// 	// @include text-emphasis-subdued;
// 	// @include unstyled-button;
// 	position: relative;
// 	display: inline-flex;
// 	align-items: center;
// 	min-height: control-height();

// 	// We only have to compensate for half the space on the left because
// 	// The icon has half the space to the focus backdrop as the text does
// 	// on the right side. We have to do negative margin on top and bottom
// 	// to compensate for the focus backdrop being smaller than the actual
// 	// hit area.
// 	// prettier-ignore
// 	// margin:
// 	//   (-0.5 * $difference-between-touch-area-and-backdrop)
// 	//   (-1 * $horizontal-padding)
// 	//   (-0.5 * $difference-between-touch-area-and-backdrop)
// 	//   (-0.5 * $horizontal-padding);
// 	padding: $vertical-padding $horizontal-padding;
// 	color: color("ink", "lighter");
// 	text-decoration: none;

// 	&:hover,
// 	&:active {
// 		color: color("ink");
// 		text-decoration: none;

// 		// .Polaris-Breadcrumbs__Icon {
// 		// 	@include recolor-icon(color("ink"));
// 		// }
// 	}

// 	&:focus {
// 		outline: none;

// 		&::after {
// 			background: plain-button-background();
// 		}
// 	}

// 	&::after {
// 		content: "";
// 		position: absolute;
// 		z-index: z-index(focus-backdrop, $stacking-order);
// 		top: 0.5 * $difference-between-touch-area-and-backdrop;
// 		right: 0;
// 		left: 0;
// 		display: block;
// 		height: control-slim-height();
// 		border-radius: border-radius();
// 		will-change: background;
// 		transition: background duration() easing();
// 	}
// }
.showDataList {
	.Polaris-ResourceList {
		display: block !important;
	}
	.Polaris-ResourceItem__CheckboxWrapper {
		margin-top: 28px;
	}
}
.datatable.cal {
	.Polaris-ResourceList__EmptySearchResultWrapper {
		display: none;
	}
}
.datatable {
	.Polaris-ResourceList {
		display: none;
	}
	tbody th,
	tbody td {
		border-bottom: 1px solid var(--borderColor);
	}
	.Polaris-DataTable__Cell--header {
		border-bottom: 0.0625rem solid var(--borderColor);
	}
	.Polaris-DataTable__Navigation {
		//display:none;
		justify-content: flex-end !important;
	}
	thead th {
		border-top: 0;
		min-height: 53px;
	}
	tbody tr.selected {
		// td,
		// th {
		// 	@include state(active);
		// }

		// &:hover {
		// 	td,
		// 	th {
		// 		@include state(selected, hover);
		// 	}
		// }

		// &:active {
		// 	td,
		// 	th {
		// 		@include state(selected, active);
		// 	}
		// }
	}
	.Polaris-Choice__Control {
		margin-right: 0px;
	}
	&.mm {
		.Polaris-Choice__Control {
			margin-right: 20px;
		}
	}

	.Polaris-ResourceList__HeaderOuterWrapper,
	.Polaris-ResourceList__HeaderWrapper {
		background-color: transparent;
		height: 54px;
		overflow: hidden;
		white-space: nowrap;
		z-index: 21;
	}
	&.haveactions {
		thead {
			th:first-child {
				width: 40px;
			}
		}
		tbody {
			th:first-child {
				padding-left: 25px;
				width: 40px;
			}
			td:nth-child(2) {
				white-space: normal;
			}
		}
	}
	&.havemedia:not(.haveactions) {
		td:first-child {
			width: 40px;
			padding-left: 0;
			padding-right: 0;
		}
		td:nth-child(2) {
			white-space: normal;
		}
	}
	&.havemedia.haveactions {
		td:nth-child(2) {
			width: 40px;
			padding-left: 0;
			padding-right: 0;
		}
		td:nth-child(3) {
			white-space: normal;
		}
	}
	&.haveselection {
		.Polaris-ResourceList__HeaderOuterWrapper,
		.Polaris-ResourceList__HeaderWrapper {
			background-color: white;
			width: 100%;
		}
		.Polaris-DataTable__Icon {
			visibility: hidden;
		}
	}
	.Polaris-ResourceList__HeaderWrapper--isSticky {
		background-color: #fff;
		width: auto;
	}
}
.columnSelectorWrapper {
	display: flex;
	border-bottom: 0.0625rem solid #dfe3e8;
	> div {
		display: flex;
	}
}
a {
	appearance: none;
	display: inline;
	text-align: inherit;
	padding: 0;
	background: none;
	border: none;
	font-size: inherit;
	color: $blue;
	text-decoration: none;
	cursor: pointer;

	&:hover,
	&:focus,
	&:active {
		outline: none;
		color: $blue;
	}
}
.variantList {
	margin-top: 10px;
	border-top: 1px solid #eee;
}
.variantList ul li {
	background-color: #fff;
}
.variantList ul li:hover {
	background-color: $blue;
	color: #fff;
}
.editableCell {
	position: relative;
	margin-top: -1rem;
	margin-right: -1rem;
	margin-bottom: -1rem;
	> div {
		padding-top: 1rem;
		padding-right: 1rem;
		padding-bottom: 1rem;
	}
	&:hover {
		//background-color: red;
	}
	.Polaris-TextField__Prefix {
		margin-left: 0;
	}
	.Polaris-TextField__Backdrop {
		display: none;
	}
}

.uploadContainer {
	position: relative;
	.uploadOverlay {
		-webkit-transition: opacity 0.2s ease-in-out;
		-moz-transition: opacity 0.2s ease-in-out;
		transition: opacity 0.2s ease-in-out;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #000;
		z-index: 11;
	}
	.uploadControls {
		display: none;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center;
		z-index: 20;
		padding-bottom: 10px;
		> div {
			margin: 0 auto;
			display: inline-block;
		}
	}
	&:hover {
		.uploadOverlay {
			opacity: 0.5;
		}
		.uploadControls {
			display: block;
			button {
				color: #fff;
				fill: #fff;
			}
		}
	}
}
.Polaris-DropZone__Container > .Polaris-Stack > .Polaris-Stack__Item:first-child .uploadContainer .Polaris-Thumbnail {
	width: 11.5625rem;
}
.customersel .Polaris-ResourceList__HeaderWrapper--hasSelect {
	display: none;
}
.fakeInput {
	background: var(--modals-input-bg);
	padding: 8px;
	border-radius: 0.3125rem;
	color: $textColor;
	//box-shadow: 1px 3px 4px 0px #00000030 !important;
}

.order_items {
	.Polaris-ResourceList__HeaderOuterWrapper {
		> div {
			> div {
				padding-bottom: 0 !important;
				position: relative !important;
				top: 0 !important;
				left: 0 !important;
				width: auto !important;
				.Polaris-ResourceList__HeaderWrapper--isSticky {
					box-shadow: none !important;
				}
			}
		}
	}
}
.dropshipping_catalog {
	.categories {
		li {
			white-space: nowrap;
		}
		padding-bottom: 20px;
		.after {
			clear: both;
		}
	}
	.Polaris-Layout__Section--secondary {
		flex: 1 1 6.25rem;
	}
	.Polaris-ActionList {
		padding-top: 0;
		.Polaris-ActionList__Actions {
			clear: left;
		}
		.Polaris-ActionList__Actions li {
			float: left;
			max-width: 33.333334%;
		}
	}
}
@media (max-width: 587px) {
	.rbc-toolbar {
		display: block;
		.rbc-toolbar-label {
			display: block;
			padding-top: 15px;
			padding-bottom: 15px;
		}
		.rbc-btn-group {
			display: block;
			text-align: center;
			margin: 0 auto;
			button {
				padding: 10px;
			}
		}
	}
}
@media (max-width: 587px) {
	.dropship_product {
		padding-left: 2.5rem;
		padding-right: 3.5rem;
	}
	.dropshipping_catalog {
		.Polaris-ActionList {
			.Polaris-ActionList__Actions li {
				max-width: 100% !important;
				float: none !important;
			}
		}
	}
}
.prop {
	display: inline-block;
	margin-right: 10px;
	color: lightgreen;
}
.prop:nth-child(2) {
	color: lightblue;
}
.prop:nth-child(3) {
	color: lightred;
}
.Polaris-Popover__Content:not(.Polaris-Popover__Content--fullHeight) {
	max-width: 37.5rem;
	max-height: 25rem;
}
.table {
	width: 100%;
	thead {
		tr {
			th {
				text-align: left;
				padding: 5px 20px;
			}
		}
	}
	tbody {
		tr {
			td {
				border-top: 1px solid #f9f9f9;
				padding: 5px 20px;
			}
		}
	}
}
.Polaris-DataTable__Cell--firstColumn {
	color: $textColor;
}

.CustomerListItem__Title {
	font-weight: 600;
}

.CustomerListItem__Orders {
	display: flex;
	align-items: center;
	list-style: none;
}

.CustomerListItem__Orders > *:not(:first-child)::before {
	content: "•";
	margin: 0 4px;
	color: #919eab; /* ink, lightest */
}

.CustomerListItem__OrderCount {
	white-space: nowrap;
}

.CustomerListItem__TotalSpent {
	justify-content: flex-end;
}

.CustomerListItem__Exceptions {
	margin-top: 4px;
}

.CustomerListItem__ConditionalActions {
	margin-top: 4px;
}

@media (min-width: 640px) {
	.CustomerListItem__Main {
		display: flex;
	}

	.CustomerListItem__Main > *:not(:last-child) {
		margin-right: 20px;
	}

	.CustomerListItem__Orders {
		flex: 1;
		justify-content: flex-end;
	}

	.CustomerListItem__Orders > *:not(:first-child)::before {
		display: none;
	}

	.CustomerListItem__OrderCount {
		min-width: 80px;
	}

	.CustomerListItem__TotalSpent {
		min-width: 168px;
	}
}

@media (min-width: 800px) {
	.CustomerListItem__Media {
		width: 32px;
	}

	.CustomerListItem__Profile {
		display: flex;
		flex-wrap: wrap;
	}

	.CustomerListItem__Profile > *:not(:last-child) {
		margin-right: 12px;
	}
}

@media (min-width: 48.0625em) {
	.Polaris-Navigation {
		max-width: var(--navigation-sidebar-width);
		transition: max-width 500ms, width 500ms, min-width 500ms;
	}

	.Polaris-Frame--hasNav .Polaris-Frame__Main {
		// padding-left: calc(#{$navSidebarWidth} + env(safe-area-inset-left));
		padding-left: calc(var(--navigation-sidebar-width) + env(safe-area-inset-left));
		transition: padding-left var(--mainmenu-transition-time-content, 500ms); //Increase this to make animation look abit cooler

		// > .Polaris-Frame__Content {
		// 	padding: 2.5000rem;
		// }
	}
}
.Polaris-ActionList .Polaris-ActionList__Item {
	background: none !important;
	// font-size: 0.8125rem;
}

.Polaris-Popover__Content {
	background-color: $main3 !important;
	background: $main3 !important;

	.Polaris-ActionList__Item {
		&,
		* {
			transition: color 150ms, fill 150ms;
			-webkit-transition: color 150ms, fill 150ms;
			-moz-transition: color 150ms, fill 150ms;
			-ms-transition: color 150ms, fill 150ms;
			-o-transition: color 150ms, fill 150ms;
		}

		&:hover:not(.Polaris-ActionList--disabled),
		&:active {
			background: none;

			&:not(.Polaris-ActionList--destructive) {
				*:not(.Polaris-Button--primary) {
					color: $green;
					fill: $green;
				}
			}

			*:not(.Polaris-Button--primary) {
				color: $red;
				fill: $red;
			}
		}
	}
}

.Polaris-Popover__Wrapper {
	border-radius: $borderRadius;
	-webkit-border-radius: $borderRadius;
	-moz-border-radius: $borderRadius;
	-ms-border-radius: $borderRadius;
	-o-border-radius: $borderRadius;
}

.Polaris-TopBar-SearchField__Input,
.Polaris-TopBar-SearchField__Backdrop {
	background: $main2 !important;
	border-radius: 1.25rem;
}

.attachment_files {
	.Polaris-Link {
		color: $textColor;
	}
}

.addButton {
	border-radius: 25px;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	-ms-border-radius: 25px;
	-o-border-radius: 25px;
	border: 1px solid $green;
	background: $cardbg;
	display: flex;
	align-items: center;
	color: $green;
	padding: 0.5em 1em;
	font-weight: 600;
	font-size: 0.8125rem;
	cursor: pointer;
	transition: color 250ms, filter 250ms;
	-webkit-transition: color 250ms, filter 250ms;
	-moz-transition: color 250ms, filter 250ms;
	-ms-transition: color 250ms, filter 250ms;
	-o-transition: color 250ms, filter 250ms;
	box-shadow: $boxShadow;

	.Polaris-Icon,
	.Polaris-Spinner {
		margin-left: 8px;
		color: $green !important;
		fill: $green !important;
		height: 20px;
	}

	&:hover {
		filter: brightness(1.2);
		-webkit-filter: brightness(1.2);
	}
}

.transparentButton {
	border: none;
	background: transparent;
	cursor: pointer;
	display: flex;
	align-items: center;
	color: $textColor;
	font-size: 0.8125rem;
	font-weight: 600;
	box-shadow: $boxShadow;
	border-radius: $borderRadius;
	-webkit-border-radius: $borderRadius;
	-moz-border-radius: $borderRadius;
	-ms-border-radius: $borderRadius;
	-o-border-radius: $borderRadius;

	.Polaris-Icon {
		// margin-left: 8px;
		color: $textColor;
		fill: $textColor;
		height: 15px;
		display: flex;
		align-items: center;
	}
}

.Polaris-Tabs__Tab {
	color: $textColor;
}

a.settings-item {
	.icon-wrapper {
		background-color: transparent;
	}
	p {
		color: $textColor;
	}
	&:hover {
		.icon-wrapper {
			fill: $textColor;
		}
	}
}

.Polaris-DataTable__Cell--verticalAlignTop {
	vertical-align: middle;
}

.Polaris-DatePicker__Title {
	color: $textColor;
}

.clockHolder div,
.greyRoundedCard .subHeader {
	color: $textColor;
}

div.greyRoundedCard {
	background-color: $secondaryBackgroundColor;
}

.filter {
	background-color: $main2;
	border-color: $borderColor !important;

	&.active {
		background-color: $main3;
	}
}

.Polaris-TopBar-SearchField__Backdrop {
	transition: filter 250ms;
}

// Makes the top bar search field full width
.Polaris-TopBar__SearchField {
	margin-left: 0 !important;
	// .Polaris-TopBar-SearchField {
	// 	max-width: 100%;
	// }
}
// .Polaris-TopBar-Search.Polaris-TopBar-Search--visible {
// 	max-width: unset;
// }

// .Polaris-TopBar__SearchField {
// 	.Polaris-TopBar-SearchField__Input {
// 		width: 100%;

// 		&:focus {
// 			&,
// 			~ .Polaris-TopBar-SearchField__Backdrop {
// 				filter: brightness(1.05);
// 				-webkit-filter: brightness(1.05);
// 			}
// 		}
// 	}
// }

.Polaris-ExceptionList__Item {
	color: $textColor;
}

body .Polaris-Button--plain:not(.Polaris-Button--destructive),
body .Polaris-Button--plain.Polaris-Button--iconOnly:not(.Polaris-Button--destructive) {
	&,
	svg {
		transition: color 100ms, fill 100ms;
	}

	&:hover {
		&,
		.Polaris-Icon:not(.Polaris-Icon--isColored) svg {
			color: $actionColor;
			fill: $actionColor;
		}
	}
}

.Polaris-Tabs__Tab:hover .Polaris-Tabs__Title {
	filter: brightness(1.1);
	-webkit-filter: brightness(1.1);
}

.Polaris-OptionList-Option:not(.Polaris-OptionList-Option--newDesignLanguage) .Polaris-OptionList-Option--disabled {
	opacity: 0.5;
	background-image: none;
}
.Polaris-ActionList__Item.Polaris-ActionList--disabled {
	background-image: none;
	color: rgb(100, 100, 100);
}

.fc-view-harness.fc-view-harness-active * {
	color: $textColor;
}

.Polaris-Stack > .Polaris-Stack__Item {
	fill: $textColor;
}

.Polaris-Card__Section,
.Polaris-LegacyCard__Section {
	&.company {
		[class*="Polaris-Text--heading"] {
			font-size: 25px;
			margin-bottom: 12px;
		}
	}

	&.people {
		[class*="Polaris-Text--heading"] {
			font-size: 25px;
			margin-bottom: 12px;
		}
	}
}

.invalid-modal-route {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;

	h1 {
		font-size: 2.1875rem;
		line-height: 2.1875rem;
		transform: translateY(-200%);
		-webkit-transform: translateY(-200%);
		-moz-transform: translateY(-200%);
		-ms-transform: translateY(-200%);
		-o-transform: translateY(-200%);
	}
}

body {
	// .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:checked,
	// .Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate {
	// 	+ .Polaris-Checkbox__Backdrop::after {
	// 		background: $green;
	// 	}
	// }
	.Polaris-Checkbox__Icon svg {
		fill: #fff;
	}
}

html body #root {
	button.Polaris-Navigation__Action[aria-expanded="true"] {
		display: initial;
	}

	button.Polaris-Navigation__Action,
	div:not(body):not(.mainmenu-item):not(.mainmenu-item__secondary__item):not(.Polaris-Collapsible),
	button:not(.Polaris-Button--primary):not(.Polaris-Button--destructive):not(.Polaris-Collapsible) {
		&[aria-expanded="true"],
		&.active {
			.Polaris-Navigation__Text {
				color: $green;
			}

			.Polaris-Icon svg {
				fill: $green;
			}
		}
	}
}

.Polaris-Icon--colorGreen svg {
	fill: $green;
}
.Polaris-Icon--colorRed svg {
	fill: $red;
}
.Polaris-Icon--colorYellow svg {
	fill: $yellow;
}
.Polaris-Icon--colorBlue svg {
	fill: $blue;
}
.Polaris-Icon--colorOrange svg {
	fill: $orange;
}
.Polaris-Icon--colorPink svg {
	fill: $pink;
}
.Polaris-Icon--colorPurple svg {
	fill: $purple;
}
.Polaris-Filters-ConnectedFilterControl__RightContainerWithoutMoreFilters .Polaris-Filters-ConnectedFilterControl__Item:last-child > * > * {
	border-top-right-radius: 25px !important;
	border-bottom-right-radius: 25px !important;
}
.Polaris-Filters-ConnectedFilterControl__Wrapper {
	button:not(.Polaris-Button--destructive) {
		background: $main3;
	}
}

.Polaris-Button__ConnectedDisclosure {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.github-client-version-checker {
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	z-index: 10000;
	.Polaris-Button {
		border-color: rgb(125, 125, 125) !important;
	}
	.Polaris-Banner__SecondaryAction .Polaris-Banner__Text::after {
		border: none;
	}
}

.Polaris-Modal-CloseButton {
	z-index: 10000;
}

.Polaris-Modal-CloseButton--withoutTitle {
	right: 1.1875rem;
	top: 0.875rem;
}

.Polaris-TextField__Input {
	color: $textColor;
}

.Polaris-Tabs {
	background: $main2;
}

.unreadIndicator {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	right: 0;
	top: 0;
	transform: translate(50%, -50%);

	.unreadIndicator-heartbeat {
		animation: heartbit 1s ease-out;
		animation-iteration-count: infinite;
		z-index: 10;
		border: 5px solid #e46a76;
		border-radius: 50%;
		height: 26px;
		width: 26px;
		position: absolute;
	}
	.unreadIndicator-inner {
		border-radius: 50%;
		width: 6px;
		height: 6px;
		background: #e46a76;
	}
}

.switch-line {
	background: #e5e6ee;
}

.switch-light {
	fill: #5ac57d;
	color: #5ac57d;
}

.switch-dark {
	fill: #e5e6ee;
	color: #e5e6ee;
}

.Polaris-TextField--error .Polaris-TextField__Backdrop {
	background: transparent !important;
	background-color: transparent !important;
}

.bolags-search-devider {
	display: flex;
	align-items: center;

	span {
		margin: 0 5px;
		font-size: 0.9em;
	}

	div.line {
		width: 100%;
		margin: 0 5px;
		background: $textColor;
		height: 1px;
		opacity: 0.4;
	}
}

.Polaris-Spinner {
	opacity: 0.5;
}

.email-campaign {
	input,
	textarea,
	select,
	.Polaris-Select .Polaris-Select__Content,
	.Polaris-TextField__Input,
	.Polaris-TextField,
	.Polaris-TextField__Backdrop {
		background-color: $main3;
	}

	.email-campaign__contacts__filter {
		// padding: 0.3125rem 0.0000rem;
		display: flex;
		gap: 3.125rem;
		justify-content: space-between;
		align-items: center;

		:first-child {
			flex: 1;
		}
	}
}

.Polaris-Pagination__Button,
.Polaris-Pagination__Button:disabled {
	border: none;
	box-shadow: $boxShadow;
	transition: filter 250ms, opacity 250ms;
	background: $green;

	.Polaris-Icon svg {
		fill: #ffffff !important;
	}
}
.Polaris-Pagination__Button:disabled {
	opacity: 0.75;
	filter: brightness(0.5);
}
.Polaris-Pagination__Button:not(:disabled) {
	color: #ffffff;
	filter: brightness(0.8);

	&:hover {
		filter: brightness(0.8);
		background: $green;
	}
}

.CustomerListItem__Profile .Polaris-Icon {
	margin: 0 0.625rem;
}

.email-audience {
	display: flex;
	justify-content: space-between;

	button {
		min-height: 1.25rem;
		min-width: 1.25rem;
		transition: opacity 250ms;
		opacity: 0.75;

		.Polaris-Icon {
			width: 0.9375rem;
			height: 0.9375rem;
		}

		&:hover {
			opacity: 1;
		}
	}
}

.datatable {
	.number_badge {
		font-size: 0.8em;
	}
}

.email-campaign__contacts {
	display: flex;
	align-items: center;
	gap: 0.4688rem;

	.Polaris-Spinner {
		display: flex;
	}
}

.email-campaigns-list-status {
	display: flex;
	align-items: center;
	gap: 0.625rem;

	svg {
		width: 18px;
		height: 18px;
	}
}

.Polaris-Filters__FiltersContainer {
	.new_datepicker__field {
		justify-content: center;
	}
}
