$width: 50.0000rem;
$height: 28.1250rem;
$headerHeight: 70px;
$footerHeight: 50px;

.IntroductionSlide {
	position: fixed;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;

	&::after {
		content: "";
		position: fixed;
		background: rgba(0, 0, 0, 0.5);
		inset: 0;
		backdrop-filter: blur(10px);
	}
}
.IntroductionSlide-inner {
	background: #494b4bc1 !important;
	box-shadow: $boxShadow;
	border-radius: $borderRadius;
	-webkit-border-radius: $borderRadius;
	-moz-border-radius: $borderRadius;
	-ms-border-radius: $borderRadius;
	-o-border-radius: $borderRadius;
	width: $width;
	height: $height;
	position: relative;
	overflow: hidden;
	z-index: 1;
	display: flex;
	flex-direction: column;
	backdrop-filter: blur(3px);
	max-height: 100vh;
	max-width: 100vh;

	.Polaris-Modal-Section {
		height: 100%;
	}

	.Polaris-Modal-Footer {
		border: none;
	}

	&.open {
		visibility: hidden;
		&,
		* {
			pointer-events: none;
		}
	}

	button {
		cursor: pointer;
		transition: filter 250ms;
		-webkit-transition: filter 250ms;
		-moz-transition: filter 250ms;
		-ms-transition: filter 250ms;
		-o-transition: filter 250ms;

		&:hover {
			filter: brightness(1.2);
			-webkit-filter: brightness(1.2);
		}
	}

	.prev,
	.close {
		border: none;
		background: transparent;

		.Polaris-Icon {
			width: 18px;
			height: 18px;

			svg {
				fill: #ffffff;
			}
		}
	}

	.header {
		display: flex;
		justify-content: space-between;
		padding: 0 1.2500rem;
		align-items: center;
		min-height: $headerHeight;
		max-height: $headerHeight;
	}
	.footer {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.9375rem;
		color: rgb(200, 200, 200);
		position: absolute;
		bottom: 0;
		width: 100%;
		height: $footerHeight;
		z-index: -1;

		&.navigate {
			justify-content: space-between;
		}
	}

	.slideContainer {
		display: flex;
		flex-direction: row;
		height: calc(100% - #{$headerHeight});
		transform: translateX(0);
		transition: transform 750ms cubic-bezier(0.63, 0.27, 0, 0.82);

		.slide {
			// height: calc(100% - #{$footerHeight});
			height: inherit;
			width: $width;
			flex-basis: 100%;
			flex-grow: 1;
			flex-shrink: 0;
			position: relative;
			padding: 3.7500rem;
			padding-top: 0.0000rem;
			padding-bottom: 0.6250rem;
			color: #ffffff;
			display: flex;
			flex-direction: column;
			min-height: calc(100% - 50px);

			// display: grid;
			// grid-template-areas:
			// 	"title right"
			// 	"content right";
			// grid-template-columns: auto minmax(100px, 300px);
			// grid-template-rows: max-content;

			.con {
				display: flex;
				flex-direction: row;
				column-gap: 0.6250rem;
				height: 100%;
			}

			.left {
				flex: 2;
				overflow: auto;
			}
			.right {
				position: relative;
				min-width: 270px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex: 1;
				// &.enterUserInfo {
				// 	min-width: 0;
				// }
			}

			&.no-image {
				// grid-template-areas:
				// 	"title"
				// 	"content";
				// grid-template-columns: 100%;
				.right {
					min-width: unset;
				}
			}

			.title {
				font-size: 2.0000rem;
				line-height: 2.5000rem;
				margin-bottom: 1.2500rem;
				grid-area: title;
			}

			.text {
				padding-bottom: 1.2500rem;
			}

			.content {
				grid-area: content;
				overflow: auto;
				width: 100%;
				// margin-bottom: 50px;

				p {
					white-space: pre-line;
				}

				.Polaris-FormLayout {
					margin-left: 0;
					padding: 2px;

					.Polaris-FormLayout__Item {
						margin-left: 0;
						margin-top: 0.6250rem;
					}
				}
			}

			div.fakeInput {
				background: $main2;
				background-color: $main2;
			}

			.image {
				width: 100%;
				object-fit: contain;
				// margin-left: 1.2500rem;
				grid-area: right;
			}

			.buttons {
				display: flex;
				// width: 100%;
				justify-content: space-between;
				gap: 50px;
				align-items: flex-end;
				height: max-content;
				align-self: flex-end;
				// position: absolute;
				bottom: 0.6250rem;
				grid-area: right;
				margin-top: 0.6250rem;

				.next,
				.action {
					border: none;
					padding: 0.6250rem 1.2500rem;
					border-radius: 25px;
					-webkit-border-radius: 25px;
					-moz-border-radius: 25px;
					-ms-border-radius: 25px;
					-o-border-radius: 25px;
					box-shadow: $boxShadow;
					cursor: pointer;
				}
				.next {
					background: #e7e7e7;
					color: rgb(0, 0, 0);
				}

				.action {
					transition: filter 250ms, opacity 250ms;
					background: $green;
					color: #ffffff;

					&:disabled {
						opacity: 0.5;
						filter: none;
					}
				}
			}

			.no-image {
				right: 3.7500rem;
				width: unset;
			}
		}
	}

	.checkboxes {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		padding-bottom: 1.2500rem;

		.section-title {
			width: 100%;
			font-weight: bold;
			padding: 0.6250rem;
			padding-bottom: 0;
		}

		.Polaris-Choice {
			padding: 0.6250rem;
			width: 50%;

			.Polaris-Choice__Label {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: pre;
			}
		}
	}

	@media (max-width: 1200px) {
		.image {
			display: none;
		}
	}
}
