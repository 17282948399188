@import "./variables.scss";

.number {
	padding: 0.0938rem 0.6250rem;
	border-radius: 0.3em;
	font-size: 0.7em;
	// display: flex;
	align-items: center;
	justify-items: center;
	transition: all 250ms;
	font-weight: 600;
	background-color: rgba($yellow, 0.3);
	color: $yellow;
	width: max-content;
	display: inline-flex;

	&.hover,
	&.clickable {
		&:hover {
			filter: brightness(1.2);
		}
	}

	&.clickable {
		user-select: none;
		cursor: pointer;
	}

	// &:hover {
	// 	// filter: brightness(1.25);
	// 	// -webkit-filter: brightness(1.25);
	// 	font-weight: 600;
	// }
}
