$padding: 1.875rem;

.sales-dashboard {
	// display: flex;
	// .left {
	// 	gap: $padding;

	// 	display: flex;
	// 	flex: 1;
	// 	flex-direction: column;
	// }
	// .right {
	// 	gap: $padding;

	// 	display: flex;
	// 	flex: 1;
	// 	flex-direction: column;
	// }

	display: grid;
	grid-template-areas: "lineGraph leaderboard_small" "areaGraph boxes";
	// grid-template-columns: 1fr 1fr;

	grid-auto-columns: minmax(0, 1fr);
	grid-auto-flow: column;

	.leaderboard_small {
		grid-area: leaderboard_small;

		.Polaris-LegacyCard {
			height: calc(100% - calc(1.875rem + 20px));
		}

		table {
			width: 100%;
		}
	}
	.line_graph {
		grid-area: lineGraph;

		.Polaris-Tabs__Wrapper {
			margin-bottom: 1.25rem;

			.Polaris-Tabs__Title {
				padding-top: 0rem;
			}
		}

		.details_wrapper {
			display: flex;
			gap: 2em;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;

			& > div {
				display: flex;
				gap: 2em;
				align-items: center;
			}

			.Polaris-Icon {
				margin: 0;
				margin-right: 0.625rem;
				height: 40px;
				width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				background-color: $main1;

				svg {
					width: 65%;
					height: 65%;
				}
			}
			.detail {
				display: flex;
			}
		}
	}

	.sales-dashboard-deal_boxes {
		grid-area: boxes;
	}

	gap: $padding;

	h1 {
		font-weight: bold;
		font-size: 1.5625rem;
		line-height: 1.875rem;
		margin-bottom: 20px;
	}

	b {
		font-size: 14px;
	}
}

.sales-dashboard-deal_boxs_wrapper {
	// grid-auto-columns: minmax(150, 1fr);
	// grid-auto-flow: column;
	display: flex;
	flex-wrap: wrap;
	gap: $padding;
}

.sales-dashboard-deal_box {
	flex: 1;
	position: relative;
	// min-width: 200px;
	// max-width: 350px;

	[aria-describedby^="PolarisTooltipContent"] .Polaris-Icon {
		height: 0.9375rem;
		width: 0.9375rem;
	}

	.Polaris-Card__Section,
	.Polaris-LegacyCard__Section {
		padding: 0.625rem;

		.sales-dashboard-deal_box_title {
			margin-bottom: 0.3125rem;
		}

		.sales-dashboard-deal_box_helptext {
			font-size: 12px;
			margin-bottom: 0.625rem;
			color: $textColor2;
			min-height: 20px;
		}
	}

	.sales-dashboard-deal_box_title_wrapper {
		display: flex;
		justify-content: space-between;
	}

	.sales-dashboard-deal_box_title {
		font-size: 16px;
		font-weight: 700;
	}
	.sales-dashboard-deal_box_row {
		display: flex;
		justify-content: space-between;
		font-size: 13px;

		&.first {
			margin-bottom: 4px;
			color: $textColor2;
			font-size: 12px;
		}
	}
}

.date_year {
	display: flex;
	gap: 0.625rem;
	align-items: center;
	padding: 0.625rem 1.25rem;
	transition: background-color 250ms;
	cursor: pointer;

	.date_year_icon {
		height: 0.9375rem;
		width: 0.9375rem;

		.Polaris-Icon {
			height: 100%;
			width: 100%;

			svg {
				height: 100%;
				width: 100%;
			}
		}
	}

	&:hover {
		background-color: $main1-50;
	}

	&.active {
		background-color: $main1-50;
	}
}

.popover_header {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.625rem;
	padding-block: 0.625rem;

	.Polaris-Icon {
		margin: 0;
	}
}

html body .area_graph {
	.new_datepicker {
		min-width: unset;
	}
}
