@import "../../node_modules/react-grid-layout/css/styles.css";
@import "../../node_modules/react-resizable/css/styles.css";
@import "./global.scss";
@import "./timeline.scss";
@import "./ticket.scss";
@import "./link_preview.scss";
@import "./leaderboardb.scss";
@import "./BoardViewCallList.scss";
@import "./Banner.scss";
@import "./variables.scss";

.list-wrapper > div {
	overflow: visible !important;
}

.highcharts-background {
	fill: transparent !important;
}

.Polaris-PositionedOverlay:not(.Polaris-Popover__PopoverOverlay),
.Polaris-Tooltip * {
	pointer-events: none !important;
}

.Polaris-Tooltip .Polaris-Tooltip__Wrapper .Polaris-Tooltip__Content {
	max-width: 21.875rem;
	padding: 0.3125rem;
}

.Polaris-Page-Header {
	padding-top: 0 !important;
}

// .list-header {
// 	margin-left: -10px;
// }
.highcharts-yaxis-grid .highcharts-grid-line {
	stroke: rgba(242, 242, 242, 0.1);
}

.highcharts-crosshair {
	stroke: $accent;
}

.attachment_files {
	min-height: 40px;
	margin-top: 5px;
	margin-bottom: 5px;

	.Polaris-Link {
		width: 100%;
	}
}

.upload_cont {
	position: relative;
	border-radius: 3px;

	.Polaris-DropZone {
		background-color: transparent;
		min-height: 20px;

		&:hover {
			background-color: transparent;
		}
	}
}

.dashboard_widget {
	.highcharts-area {
		opacity: 0.1;
	}
}

.Polaris-DataTable__TableRow {
	.visible-hover {
		visibility: hidden;
	}

	&:hover {
		.visible-hover {
			visibility: visible;
		}
	}
}

html body {
	.highcharts-container {
		text {
			font-size: 13px;
			font-weight: normal;
			color: $textColor2;
			fill: $textColor2;
		}
	}

	.highcharts-tooltip {
		.highcharts-tooltip-box {
			fill: $main3;
			stroke: transparent;
		}

		text {
			color: $textColor !important;
			fill: $textColor !important;
		}
	}
}

.simple-row-list {
	.simple-list-header {
		margin-left: 30px;
		margin-right: 20px;
		background-color: $cardbg;
		height: 45px;

		select.Polaris-Select__Input {
			background-color: transparent !important;
		}

		.Polaris-Select__Content {
			background-color: transparent !important;
		}

		.Polaris-Select__Backdrop {
			background-color: transparent !important;
			border-color: transparent !important;
			box-shadow: none;

			&::after {
				background: transparent !important;
			}
		}
	}

	.simple-input {
		font-size: 14px;
		display: block;
		width: 100%;
		background-color: transparent;
		border: 1px dashed #eee;
		outline: none;
	}

	.list-item-cell {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: $textColor;
		max-width: 600px;
		position: relative;

		.cell-contact {
			.contact-selector-outside {
				border: none;
				justify-content: flex-start;

				// .contact_selector {
				// 	width: 100%;
				// }
			}
		}

		&:hover {
			overflow: visible;

			.cell-contact {
				.board-display-hover {
					display: block;
					position: absolute;
					left: 0;
					transform: translateX(-100%);
				}
			}
		}
	}
}

.dashboard-header button {
	z-index: 2;
}

.board-header {
	&.not-editing {
		.react-grid-layout .title-row {
			display: none;
		}
	}

	&.editing {
		.react-grid-layout .title-row {
			display: block;
		}
	}
}

.widget_icon_container {
	position: absolute;
	top: 20px;
	left: 20px;
	-webkit-transition: 0.5s all ease;
	transition: 0.5s all ease;
	border-radius: 50%;
	padding-top: 5px;
	width: 50px;
	height: 50px;

	&.plain {
		top: -45px;
		left: -15px;
		z-index: 101;
	}

	.Polaris-Icon {
		pointer-events: none;

		.Polaris-Icon__Svg {
			margin-top: 10px;
		}
	}

	&.loading {
		opacity: 0.5;
	}

	&.compact {
		top: 26px;
		left: 15px;
		width: 36px;
		height: 36px;

		.Polaris-Icon {
			.Polaris-Icon__Svg {
				margin-top: 3px;
			}
		}
	}
}

.color-blue {
	&:not(.plain) {
		background-color: rgba($blue, 0.1);
	}

	&.selector {
		background-color: #4991cc !important;
	}

	.Polaris-Icon__Svg {
		fill: #4991cc !important;
	}
}

.color-yellow {
	&:not(.plain) {
		background-color: rgba($yellow2, 0.1);
	}

	&.selector {
		background-color: #ffd66b !important;
	}

	.Polaris-Icon__Svg {
		fill: #ffd66b !important;
	}
}

.color-green {
	&:not(.plain) {
		background-color: rgba($green, 0.1);
	}

	&.selector {
		background-color: #5ac57d !important;
	}

	.Polaris-Icon__Svg {
		fill: #5ac57d !important;
	}
}

.color-pink {
	&:not(.plain) {
		background-color: rgba($pink, 0.1);
	}

	&.selector {
		background-color: #e477b9 !important;
	}

	.Polaris-Icon__Svg {
		fill: #e477b9 !important;
	}
}

.color-orange {
	&:not(.plain) {
		background-color: rgba($orange, 0.1);
	}

	&.selector {
		background-color: #edab51 !important;
	}

	.Polaris-Icon__Svg {
		fill: #edab51 !important;
	}
}

.color-purple {
	&:not(.plain) {
		background-color: rgba($purple, 0.1);
	}

	&.selector {
		background-color: #af78f4 !important;
	}

	.Polaris-Icon__Svg {
		fill: #af78f4 !important;
	}
}

.blue_gradient {
	background: linear-gradient(60deg, darken($blue, 3%), lighten($blue, 3%));
}

.orange_gradient {
	background: linear-gradient(60deg, darken($orange, 3%), lighten($orange, 3%));
}

.pink_gradient {
	background: linear-gradient(60deg, darken($pink, 3%), lighten($pink, 3%));
}

.purple_gradient {
	background: linear-gradient(60deg, darken($purple, 3%), lighten($purple, 3%));
}

.red_gradient {
	background: linear-gradient(60deg, darken($red, 3%), lighten($red, 3%));
}

.yellow_gradient {
	background: linear-gradient(60deg, darken($yellow, 3%), lighten($yellow, 3%));
}

.green_gradient {
	background: linear-gradient(60deg, darken($green, 3%), lighten($green, 3%));
}

.board-item {
	transition: transform 0.1s ease-in-out;

	&.below-hover {
		transform: translateY(36px);
	}

	&.below-drag {
		transform: translateY(-36px);
	}

	&.below-drag.below-hover {
		transform: translateY(0px);
	}
}

.form-field {
	padding-top: 16px;
	padding-bottom: 24px;

	.form-field-controls {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		background-color: $main2;
	}

	.form-field-required {
		position: absolute;
		top: 0;
		right: 0;
		color: red;
	}

	&.editing:hover {
		.form-field-controls {
			display: initial;
		}

		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	}
}

.weektopbar {
	background-color: #fff;
	border-bottom: 1px solid #eee;
	padding: 8px;

	.Polaris-Button--outline {
		border: 0;
	}

	.thisweekbtn {
		position: absolute;
		left: 0;
		bottom: -15px;
		right: 0;

		.Polaris-Button {
			font-size: 10px;
		}
	}
}

.trigger-description {
	.Polaris-Text--root {
		font-weight: normal;
		color: #777;

		.entry {
			position: relative;
			border: 3px solid black;

			.target-title {
				font-weight: 600;
				color: #bebebe;
				cursor: pointer;
			}

			.value-title {
				font-weight: 600;
				color: #bebebe;
				cursor: pointer;
			}

			.title {
				font-weight: 600;
				color: #bebebe;
				cursor: pointer;
			}
			&.focused {
				color: #fff;
			}

			span[active="true"] {
				color: #bb7efc;
			}

			.editing-target {
				.target-title {
					color: #bb7efc;
				}
			}

			.editing-value {
				.value-title {
					color: #bb7efc;
				}
			}

			.clear-button {
				display: none;
				position: absolute;
				top: -10px;
				left: -10px;
				cursor: pointer;
				opacity: 0.5;
				background: white;

				&:hover {
					opacity: 1;
				}
			}

			&:hover {
				border: 3px dashed #fff;

				.clear-button {
					display: initial;
				}
			}
		}
	}
}

.dashboard-widget {
	background-color: #fff;
	position: relative;
	box-shadow: var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
	outline: 0.0625rem solid transparent;
	border-radius: var(--p-border-radius-wide, 10px);
}

.ReactVirtualized__Grid {
	outline: none;
	overflow: visible !important;

	.ReactVirtualized__Grid__innerScrollContainer {
		overflow: visible !important;
	}
}

iframe {
	background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%23919eab" x="50%" y="33%" font-family="-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif" font-size="24" text-anchor="middle">Hämtar data...</text></svg>')
		0px 0px no-repeat;
}

.fixed-column {
	position: -webkit-sticky;
	position: sticky;
	left: 0px;
}

.dashboard-widget {
	.dashboard-widget-hover {
		display: none;
	}

	&:hover {
		.dashboard-widget-hover {
			display: initial;
		}
	}

	.widget-title {
		cursor: default;
		font-size: 18px;
		display: inline-block;
		font-weight: 600;
		border: 1px transparent;
		color: $textColor;
	}

	.editing {
		.widget-title {
			cursor: text;

			&:hover {
				border: 1px dashed #777;
				padding: 0;
			}

			&.focused {
				border: 1px dashed #777;
				padding: 0;
			}
		}
	}
}

.kanban-card-wrapper {
	position: relative;

	// .border-top {
	// 	position: absolute;
	// 	top: 0;
	// 	width: 100%;
	// 	height: 5px;
	// 	border-radius: 2.5px;
	// }
	// .border-left {
	// 	position: absolute;
	// 	top: 0;
	// 	width: 5px;
	// 	height: 100%;
	// 	border-radius: 2.5px;
	// 	left: 0;
	// }

	// :first-child {
	// 	border-top: ;
	// }
}

.kanban-column {
	// min-width: 240px;
	width: 240px;
	border-radius: 5px;
	padding: 10px;
	color: $textColor;
	background: $main2;
	position: relative;
	box-shadow: $boxShadow;
	// overflow: hidden;

	.kanban-column__header {
		padding-bottom: 10px;
		display: flex;
		flex-direction: column;
		gap: 0.3125rem;

		> h2 {
			display: flex;
			justify-content: space-between;
		}

		.kanban-column__header__count {
			padding: 3px;
			background: $main3;
			border-radius: 3px;
			font-size: 0.9em;
		}

		.kanban-column__header__values {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			font-weight: 600;

			.kanban-column__header__sum__value {
				padding: 3px;
				background: $main3;
				border-radius: 3px;
			}
		}
	}

	.bottom-scroll-shadow {
		pointer-events: all;
	}
}

.bottom-scroll-shadow {
	transition: opacity 250ms, height 250ms;
	height: 75px;
	// background: linear-gradient(#1e282f00, #1e282f);
	background: linear-gradient($main2-0, $main2);
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-radius: 0 0 5px 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	opacity: 1;

	&.visible {
		opacity: 1;
		height: 75px;
	}

	&.invisible {
		opacity: 0;
		height: 0px;
	}
}

.timelog-row {
	&:hover {
		background-color: #f9f9f9;
	}
}

.expandedCell {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	height: auto;
	right: 0;
	// background-color: #333;
	border: 1px solid #222;
}

.bgInput {
	outline: none;
	border: 0px;
	display: block;
	width: 100%;
	padding: 15px;
	height: 40px;
	font-size: 16px;

	&:focus {
		outline: none;
	}
}

.bulkToolbar {
	pointer-events: none;
	position: fixed;
	bottom: 0px;
	left: 0;
	right: 0;
	z-index: 517;
	min-height: 100px;
	transition: 0.1s ease;
	margin-bottom: -120px;
	opacity: 0;

	&.open {
		margin-bottom: 20px;
		opacity: 1;
	}

	.bulkbar {
		pointer-events: auto;
		max-width: 700px;
		margin: 0 auto;
		background-color: #fff;
		min-height: 70px;
		border-radius: 5px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
		position: relative;

		.bulkCount {
			font-size: 30px;
			color: #fff;
			width: 70px;
			text-align: center;
			border-radius: 5px 0px 0px 5px;
			background-color: #0085ff;
			min-height: 70px;
			line-height: 70px;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.bulkActionButton {
			cursor: pointer;
			// display: block;
			padding-left: 10px;
			padding-right: 10px;
			padding-top: 15px;
			height: 70px;

			display: flex;
			flex-direction: column;
			align-content: center;
			align-items: center;
			gap: 6px;

			.Polaris-Icon {
				// margin-bottom: 6px;
				margin: 0;
			}

			&:hover {
				background-color: #f9f9f9;
			}
		}

		.bulkCancel {
			cursor: pointer;
			// display: block;
			padding: 25px;
			border-left: 1px solid #ccc;
			height: 100%;
			display: flex;
		}

		> .Polaris-Stack > .Polaris-Stack__Item:last-child {
			position: absolute;
			right: 0;
		}
	}
}

@media (min-width: 769px) {
	.Polaris-Sheet__Container:not([data-pinned="true"]) {
		width: min(85vw, var(--sheet-width));
		.Polaris-Sheet {
			width: min(85vw, var(--sheet-width));
		}
	}

	.Polaris-Sheet__Container[data-pinned="true"] {
		width: min(85vw, var(--sheet-width-pinned));
		.Polaris-Sheet {
			width: min(85vw, var(--sheet-width-pinned));
		}
	}
}

.headingOverflow {
	[class*="Polaris-Text--heading"] {
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		max-width: 100%;
		display: block;
		text-overflow: ellipsis;
		flex: 1;
	}
}

.selectbox {
	width: 8px;
	height: 36px;
	transition: width 0.07s cubic-bezier(0, 0, 0.35, 1);
	position: relative;

	> div {
		position: relative;
		width: 6px;
		height: 100%;
		overflow: hidden;
		padding-left: 10px;
		padding-top: 2px;
		transition: width 0.07s cubic-bezier(0, 0, 0.35, 1);
		will-change: width;
		transform: translate3d(0, 0, 0);
		z-index: 2;
	}

	&:before {
		content: "";
		position: absolute;
		top: 0px;
		bottom: 0px;
		left: 6px;
		width: 24px;
		z-index: 1;
	}

	&:hover {
		width: 35px;

		> div {
			width: 35px;
		}
	}
}

.have-selection .selectbox {
	width: 35px;

	> div {
		width: 35px;
	}
}

.kanban-card {
	border-radius: 5px;
	background-color: $main3;
	color: $textColor;
	overflow: hidden;
	position: relative;
	padding: 10px;
	box-shadow: $boxShadow;
	margin-bottom: 5px;
	font-size: 0.9em;
	display: flex;
	flex-direction: column;
	gap: 0.3125rem;

	&.dragging {
		transform: rotate(-3deg);
	}

	.kanban-card__people {
		display: flex;
		position: absolute;
		bottom: 5px;
		right: 5px;

		.Polaris-Avatar {
			width: 1.5625rem;
			min-width: 1.5625rem;
		}
	}

	.kanban-card__title {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.kanban-card__value {
		opacity: 0.65;
	}

	.lastline {
		position: absolute;
		left: 10px;
		right: 40px;
		bottom: 0px;
	}
}

.activeAvatar {
	.Polaris-Avatar {
		box-shadow: 0px 0px 0px 3px rgba(43, 153, 217, 0.8);
	}
}

.smallerAvatars {
	display: flex;
	align-items: center;
	justify-content: center;

	.add-btn {
		margin-left: -13px;
		position: relative;
		z-index: 1;

		.Polaris-Icon {
			width: 1.125rem;
			height: 1.125rem;
		}

		visibility: hidden;
	}

	&:hover {
		.add-btn {
			visibility: initial;
		}
	}

	.Polaris-Avatar--sizeSmall {
		margin-left: -3px;
		margin-right: -3px;
		width: 1.625rem;
		min-width: 1.625rem;
	}
}

.board-status {
	transition: opacity 250ms;

	height: 35px;
	cursor: pointer;
	text-align: center;
	line-height: 35px;
	color: #fff;

	&:focus {
		outline: none;
	}

	&:not(.disabled):hover {
		opacity: 0.8;
	}

	&.disabled {
		opacity: 0.8;
		pointer-events: none;
	}
}

.cell-clickable {
	&:not(.disabled) {
		cursor: pointer;

		&:hover {
			background-color: #ccc;
		}
	}
}

.board-editable {
	padding: 1px;

	&:not(.disabled):hover {
		border: 1px dashed #777;
		padding: 0;
	}

	&.focused {
		border: 1px dashed #777;
		padding: 0;
	}
}

.status-option {
	cursor: pointer;
	text-align: center;
	line-height: 35px;
	color: #fff;
	height: 35px;
	min-width: 100px;
	padding-inline: 5px;

	[aria-describedby^="PolarisTooltipContent"] {
		display: flex;
	}

	&.selected {
		border: 1px solid blue;
	}

	&:hover {
		opacity: 0.8;
	}
}

.edit-status-option {
	.deleteStatus {
		position: absolute;
		right: 0;
		top: 0;
		cursor: pointer;
		transform: translate(50%, -50%);
		-webkit-transform: translate(50%, -50%);
		-moz-transform: translate(50%, -50%);
		-ms-transform: translate(50%, -50%);
		-o-transform: translate(50%, -50%);
		display: none;

		&:hover {
			display: block;
			z-index: 99;
		}
	}

	.status-colorpicker-trigger {
		transition: filter 250ms;
		-webkit-transition: filter 250ms;
		-moz-transition: filter 250ms;
		-ms-transition: filter 250ms;
		-o-transition: filter 250ms;

		&:hover {
			filter: brightness(1.2) saturate(1.2);
			-webkit-filter: brightness(1.2) saturate(1.2);
		}
	}

	&:hover {
		.deleteStatus {
			display: block;
			z-index: 99;
		}
	}
}

.drag-over {
	.board-editable {
		&:not(.disabled):hover {
			border: 0;
			padding: 1px;
		}
	}
}

.overflow-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#schedule {
	thead {
		.ant-row-flex {
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 15px;
		}
	}
}

.fixed-row.stuck {
	z-index: 2;
	position: -webkit-sticky !important;
	position: sticky !important;
	top: 0px !important;
}

.row-item {
	border: 1px solid #e1e1e1;
	border-left: 4px solid #e1e1e1;
	height: 40px;
	margin-bottom: 8px;
	margin-right: 16px;
	border-radius: 4px;
	align-items: center;
	padding-left: 8px;
	position: relative;

	.row-item-hover {
		display: none;
	}

	&:hover {
		.row-item-hover {
			display: block;
		}
	}
}

.Polaris-Frame__Navigation {
	z-index: 3;
}

.board-padding-left {
	padding-left: 1.25rem;
}

.board-padding-right {
	padding-right: 1.25rem;
}

.drag-over {
	background-color: #d2e1f5;
}

.board-spacer {
	position: relative;
	height: 36px;
	width: 1.25rem;
	background-color: $main1;
}

.Polaris-Stack--vertical.Polaris-Stack--distributionFill {
	height: 100%;
}

.board-container {
	&.table {
		overflow: scroll;
	}
}

@media (min-width: 769px) {
	.board-spacer {
		width: 2rem;
	}

	.board-padding-left {
		padding-left: 2rem;
	}

	.board-padding-right {
		padding-right: 2rem;
	}
}

.board-column,
.board-header,
.title-row,
.board-cell {
	textarea,
	input {
		font-weight: inherit;
		font-family: inherit;
		font-size: inherit;
	}

	textarea:focus,
	input:focus {
		outline: none;
	}
}

.board-display-hover {
	display: none;
}

.row-sheet {
	//maybe to this for all columns in the row-sheet?
	.board-column {
		&.column-contact {
			height: unset;
		}
	}

	.Polaris-Tabs__Wrapper {
		z-index: 2;
		position: relative;
	}
}

.boardembed {
	.board-column .contact_selector {
		overflow: hidden;
	}
}

.board-column {
	position: relative;

	min-height: 36px;
	overflow: hidden;
	background-color: #eaedf1;
	border-right: 1px solid #fff;
	border-bottom: 1px solid #ccc;
	transition: filter 150ms;

	&:not(.column-text),
	&.empty {
		height: 36px;
	}

	&.focused {
		overflow: visible;
		filter: brightness(1.5);
	}

	&:hover {
		.board-display-hover {
			display: block;
		}
	}

	&.board-summary {
		border-bottom: 0;
		border-right: 0;
		border-radius: 0px 0px 3px 3px;
	}

	.clear-button {
		position: absolute;
		right: 5px;
		top: 6px;
		cursor: pointer;
		height: 14px;
		width: 14px;

		.Polaris-Icon {
			height: 14px;
			width: 14px;
			fill: #666;
		}

		&:hover {
			.Polaris-Icon {
				fill: #202020;
			}
		}
	}

	.clear-button-inline {
		cursor: pointer;
		display: inline-block;
		margin-left: 5px;
		vertical-align: center;
		height: 14px;
		width: 14px;
		opacity: 0.6;

		&:hover {
			opacity: 1;
		}

		.Polaris-Icon {
			height: 14px;
			width: 14px;
			fill: inherit;
		}
	}
}

.collapsed-group {
	position: relative;
	height: 40px;
	cursor: pointer;
	padding-left: 15px;
	padding-top: 7px;
	background-color: #eaedf1;
	border-bottom: 1px solid #dbdee4;

	&:hover {
		background-color: #dbdee4;
	}
}

.board-row:hover {
	.board-column {
		background-color: #dbdee4;
	}
}

.board-row,
.collapsed-group,
.column-title {
	.board-show-hover {
		overflow: hidden;
		white-space: nowrap;
		transition: 0.1s ease;
		max-width: 0;
	}

	&:hover {
		.board-show-hover {
			max-width: 75px;
			margin-right: 10px;
		}
	}
}

.group-title {
	font-size: 1rem;
	display: inline-block;
	font-weight: 600;
}

.column-header,
.fixed-column {
	&:hover:not(.focused) {
		.sort-button:not(.inline) {
			display: block;
		}
	}
}

html body .sort-button {
	.container {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	span.icon {
		background-color: var(--main3);
		padding: 3px 5px;
		display: inline-block;
		border-radius: 25px;
		cursor: pointer;
	}

	text-align: center;
	display: none;

	&.active {
		span.icon {
			background-color: $green;

			svg {
				fill: #ffffff !important;
			}
		}

		display: block;
	}

	z-index: 5;

	&:not(.inline) {
		position: absolute;
		left: 0;
		right: 0;
		top: -20px;
	}

	&.inline {
		display: flex;
	}

	&.simple {
		padding-top: 5px;

		span.icon {
			padding: 2px;
			background-color: transparent;
		}

		&.active {
			span.icon {
				background-color: $main2;
			}
		}
	}
}

.column-header {
	position: relative;
	height: 35px;
	padding: 5px;

	//overflow: hidden;
	.group-title {
		padding: 1px;

		&:hover {
			border: 1px dashed #777;
			padding: 0;
		}
	}

	.board-display-hover {
		display: none;
	}

	.resize-handle {
		display: none;
		position: absolute;
		right: 0;
		top: 0;
		cursor: ew-resize;
		height: 35px;
		width: 5px;
		background-color: #eaedf1;

		&:hover {
			background-color: blue;
		}
	}

	&.dragging {
		background-color: #eaedf1;
	}

	&.focused {
		background-color: #eaedf1;
	}

	&.active {
		background-color: #eaedf1;

		.board-display-hover {
			display: block;
		}
	}

	&:hover:not(.focused) {
		&.hover-bg {
			background-color: #eaedf1;
		}

		.board-display-hover {
			display: block;
		}

		.resize-handle {
			display: block;
		}
	}
}

.have-left-scroll {
	.fixed-column {
		z-index: 1;

		.board-column {
			background-color: $main2-95;
			// background-color: rgba($main2, 0.95);
			opacity: 0.9;
			border-right: 1px solid #ccc;
		}
	}
}

.stuck {
	.column-header {
		background-color: $main1-95;
		border-bottom: 1px solid #ccc;
	}

	.board-spacer {
		background-color: $main1-95;
		border-bottom: 1px solid #ccc;
	}
}

.icon-color {
	opacity: 0.8;

	svg {
		fill: inherit !important;
	}
}

button:hover {
	.icon-color {
		opacity: 1;
	}
}

.removePersonIndicator {
	position: absolute;
	right: 10px;
	background: transparent;
	background-color: transparent;

	.added {
		display: block;
	}

	.remove {
		display: none;
	}
}

.personItem {
	&:hover {
		.removePersonIndicator {
			.added {
				display: none;
			}

			.remove {
				display: block;
			}
		}
	}
}

.board-column .clear-button .Polaris-Icon {
	fill: rgb(200, 50, 50);
	opacity: 0.8;
	transition: opacity 250ms, fill 250ms;

	-webkit-transition: opacity 250ms, fill 250ms;
	-moz-transition: opacity 250ms, fill 250ms;
	-ms-transition: opacity 250ms, fill 250ms;
	-o-transition: opacity 250ms, fill 250ms;

	&:hover {
		opacity: 1;
		fill: rgb(250, 50, 50);
	}
}

.ContactsDropDown {
	padding: 5px;
	box-shadow: $boxShadow;

	.Contact {
		padding: 10px 0;
		overflow-x: hidden;

		.Polaris-Stack {
			margin-left: 0;

			// .Polaris-Stack__Item {
			// 	margin-left: 0;
			// }
		}
	}
}

.filterNrDot {
	position: absolute;
	right: 0;
	background: red;
	top: 0;
	transform: translate(100%, -50%);
	border-radius: 50%;
	width: 20px;
	display: flex;
	height: 20px;
	align-items: center;
	justify-content: center;
}

a.comment-button {
	svg {
		transition: fill 100ms;
		-webkit-transition: fill 100ms;
		-moz-transition: fill 100ms;
		-ms-transition: fill 100ms;
		-o-transition: fill 100ms;
	}

	&:hover svg {
		fill: rgb(200, 200, 200);
	}
}

div[data-portal-id^="tooltip-portal"] {
	.Polaris-PositionedOverlay {
		margin-top: 15px !important;
	}
}

[id^="visibility-check-"] {
	visibility: hidden;
}

.showingArchivedBadge {
	position: relative;

	.icon {
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(50%, -50%);
		cursor: pointer;
		opacity: 0.5;
		transition: opacity 200ms;

		-webkit-transition: opacity 200ms;
		-moz-transition: opacity 200ms;
		-ms-transition: opacity 200ms;
		-o-transition: opacity 200ms;

		&:hover {
			opacity: 1;
		}
	}

	&:hover .icon {
		opacity: 1;
	}
}

.board-main-header .Polaris-Page__Content {
	margin-top: 5px;
}

.view-selector {
	position: relative;
	background: $main2;

	.add {
		display: flex;
		align-items: center;
		height: 100%;
		justify-content: center;
		padding: 10px;
	}
}

.comment-button.no_comment svg {
	fill: $textColor2;
}

.board-column {
	background: var(--main2);

	.board-display-hover {
		a {
			.Polaris-Icon--colorInkLighter {
				svg {
					fill: $textColor2;
				}
			}
		}
	}

	&.focused {
		background-color: $main3;
		z-index: 100000;
	}
}

.board-row {
	&:hover .board-column {
		background-color: $main3;
	}
}

.fixed-row {
	background: var(--main1);
}

.boardembed {
	color: var(--textColor);
	// background: $main1;
	height: 100%;

	.Polaris-Button:not(.Polaris-Button--plain):not(.Polaris-Button--primary):not(.Polaris-Button--destructive) {
		background: var(--main3);
		color: var(--textColor);
	}

	.Polaris-Button:not(.Polaris-Button--plain) {
		box-shadow: $boxShadowSmall;
	}

	input:not(.quickadd2190),
	textarea,
	select,
	.Polaris-Select .Polaris-Select__Content,
	.Polaris-TextField__Input {
		& {
			background-color: var(--modals-input-bg);
			color: var(--textColor);
			border-right: 1px solid #fff;
			border-bottom: 1px solid #ccc;
		}
	}

	body[theme="dark"] & .timeline .timeline-rows .timeline-row::after,
	body[theme="dark"] & .timeline .timeline-rows .timeline-new::after {
		background: rgb(200, 200, 200);
	}

	.statusModal {
		background-color: $modals-bg;
	}

	.save-button button.Polaris-Button:not(.Polaris-Button--plain) {
		border-radius: 25px;
		padding-top: 20px;
		padding-bottom: 20px;

		.Polaris-Button__Text {
			font-size: 16px;
		}
	}

	.time_input {
		width: 4.375rem;
	}
}

.Polaris-ButtonGroup--segmented {
	box-shadow: $boxShadowSmall;
	border-radius: 25px;

	.Polaris-Button {
		box-shadow: none;
	}
}

#PolarisTextField1 {
	border: none;
}

.simple-contact_selector {
	.add-btn {
		opacity: 0;
		// pointer-events: none;
		transition: opacity 250ms, transform 250ms;
		position: absolute;
		transform: translateX(-80%);
		cursor: pointer;

		&:hover {
			opacity: 1;
			// pointer-events: all;
			transform: translateX(-100%);
		}
	}

	&:hover {
		.add-btn {
			opacity: 1;
			// pointer-events: all;
			transform: translateX(-100%);
		}
	}
}

.cell-contact {
	height: 100%;
	width: 100%;
	text-align: center;
	cursor: pointer;
	padding: 3px;
}

.contact-selector-outside {
	border-width: 1px 1px 1px 5px;
	border-style: solid;
	border-color: rgb(204, 204, 204) rgb(204, 204, 204) rgb(204, 204, 204) rgb(0, 111, 187);
	border-image: initial;
	border-radius: 3px 0px 0px 3px;
	padding: 3px;
	position: relative;
	display: flex;
	justify-content: center;
}

.import-fields-missing-wrapper {
	display: flex;
	justify-content: flex-end;
	gap: 0.625rem;

	> span {
		color: $red;
		fill: $red;
		margin: 0;
		font-weight: 600;
	}
}

.board-header-search-wrapper {
	margin-left: 32px;
	margin-right: 32px;
	margin-bottom: 15px;

	> .Polaris-LegacyCard > .Polaris-LegacyCard__Section {
		padding-bottom: 0;
		transition: padding-top 350ms;
	}

	&.showing_tabs {
		> .Polaris-LegacyCard > .Polaris-LegacyCard__Section {
			padding-top: 0;
		}
	}

	.Polaris-TextField__Backdrop {
		border-radius: 25px;
	}

	.saved-filter-tabs {
		transition: opacity 250ms, height 500ms;
		opacity: 0;
		height: 0px;
		pointer-events: none;
		overflow: hidden;

		&.show {
			height: 55px;
			overflow: unset;
			pointer-events: all;
			opacity: 1;
		}
	}

	> .Polaris-LegacyCard {
		padding: 0 0.625rem;
		border-radius: $borderRadius;

		> .Polaris-Card__Section,
		.Polaris-LegacyCard__Section {
			padding-left: 0.625rem;
			padding-right: 0.625rem;
		}
	}

	.board-header-search-inner {
		display: flex;
		gap: 1.875rem;

		.board-header-search-filters {
			margin-right: -8px;
			margin-left: -8px;
			flex: 1;

			.Polaris-TextField__Backdrop {
				box-shadow: none !important;
			}

			.Polaris-Filters-ConnectedFilterControl__Wrapper {
				box-shadow: 0px 4px 8px 0px #00000030 !important;
				border-radius: 1.25rem;
			}
		}
	}

	// .board-header-search-filters [id^="Activator"] {
	.board-header-search-filters .Polaris-Filters-ConnectedFilterControl__Item > div > button {
		background: transparent !important;
	}

	.Polaris-Filters-ConnectedFilterControl {
		border-radius: 1.25rem;

		.Polaris-TextField {
			border-top-left-radius: 1.25rem;
			border-bottom-left-radius: 1.25rem;

			.Polaris-TextField__Input:focus ~ .Polaris-TextField__Backdrop::after {
				opacity: 0 !important;
			}

			.Polaris-TextField__Input,
			.Polaris-TextField {
				background: transparent;
			}
		}
	}
}

html body {
	.Polaris-Tag {
		color: black;
	}
}

.Polaris-Filters__TagsContainer {
	.Polaris-Tag {
		background: var(--main1);
		color: var(--textColor);
		padding: 5px 10px;
		border-radius: 25px;

		.Polaris-Tag__Button {
			transition: filter 250ms;

			svg {
				transition: filter 250ms, fill 250ms;
				color: $textColor;
			}
		}

		&.Polaris-Tag--removable {
			padding-right: 5px;
			--p-icon: var(--textColor);

			.Polaris-Tag__Button:hover,
			.Polaris-Tag__Button:focus,
			.Polaris-Tag__Button:active {
				background: transparent;
				filter: brightness(1.2);

				svg {
					color: $textColor;
					fill: $textColor;
				}
			}

			.Polaris-Icon {
				height: 16px;
				width: 16px;
			}
		}

		.Polaris-Tag__TagText {
			font-size: 0.9em;
		}
	}
}

.new-row-button-wrapper {
	background-color: $main3;
	box-shadow: $boxShadow;
	border-radius: 25px;
	font-weight: bold;
	color: $textColor;
	padding: 0 0.625rem;
}

.kanban-column-scroll {
	padding-bottom: 50px;
}

.board-container-small {
	.kanban-column {
		background: $main3;
	}
}

.Polaris-DatePicker__Day {
	color: $textColor;

	&:not(.Polaris-DatePicker__Day--selected):hover {
		background-color: $accent;
		color: #ffffff;
	}
}

.Polaris-DatePicker__EmptyDay {
	&:not(.Polaris-DatePicker__Day--selected):hover {
		background-color: transparent;
	}
}

.timeline {
	margin-bottom: 20px;

	.timeline-header {
		margin-bottom: 20px;
		padding: 20px;
		display: flex;
		justify-content: space-between;
	}

	.timeline-rows {
		.timeline-new {
			position: relative;

			@media (max-width: 587px) {
				padding-left: 10px;
				padding-right: 10px;
			}

			> .Polaris-Stack {
				> .Polaris-Stack__Item:first-child {
					padding-left: 10px; //Margin left

					@media (max-width: 587px) {
						padding-left: 0px;
					}
				}
			}

			.Polaris-TextField {
				padding: 5px;
				padding-left: 0;

				.Polaris-TextField__Suffix {
					margin-right: 5px;
					pointer-events: auto;

					.Polaris-Button--plain {
						position: relative;
						top: 4px;
					}
				}
			}

			.Polaris-Avatar {
				z-index: 2;
			}

			.timeline-new__input-row {
				margin-top: 8px;
				display: flex;
				gap: 1.25rem;

				> div {
					flex: 1;
				}

				.Polaris-Avatar {
					width: 32px;
					height: 32px;
				}
			}
		}

		.timeline-row {
			position: relative;
			margin-top: 40px;
			margin-bottom: 40px;

			.mytimeline-event {
				padding-left: 15px; //Margin left

				.Polaris-Avatar {
					z-index: 2;
				}

				.Polaris-Button--plain {
					margin: 0;
				}
			}

			.timeline-card-wrapper {
				z-index: 2;
				margin-top: 5px;
				position: relative;

				> .Polaris-LegacyCard {
					> .Polaris-Card__Section,
					.Polaris-LegacyCard__Section {
						padding: 15px;
						padding-left: 10px; //Margin left
						padding-right: 10px;

						> .Polaris-Stack {
							> .Polaris-Stack__Item:first-child {
								width: 38px;

								.timeline-ball {
									position: relative;
									background-color: #007ace;
									border-radius: 50%;
									height: 40px;
									width: 40px;
									border: 3px solid #ebeef0;
									z-index: 2;

									&.default {
										background-color: #637381;
									}

									&.info {
										background-color: #47c1bf;
									}

									&.critical {
										background-color: #de3618;
									}

									&.success {
										background-color: #50b83c;
									}

									&.attention {
										background-color: #eec200;
									}

									.Polaris-Icon {
										width: 20px;
										height: 20px;
										position: absolute;
										top: 7px;
										left: 7px;
									}
								}
							}
						}

						.timeline-content {
							position: relative;
						}
					}
				}
			}
		}

		.timeline-row,
		.timeline-new {
			&:after {
				content: " ";
				position: absolute;
				top: 20px;
				bottom: -40px;
				left: 29px;
				width: 3px;
				z-index: 1;
				background: #e3e6e9;
			}

			&:last-child:after {
				bottom: 0px;
			}
		}
	}
}

body[theme="dark"] {
	.timeline .timeline-rows {
		.timeline-row,
		.timeline-new {
			&:after {
				content: " ";
				position: absolute;
				top: 20px;
				bottom: -40px;
				left: 29px;
				width: 3px;
				z-index: 1;
				background: #303437;
			}

			&:last-child:after {
				bottom: 0px;
			}
		}
	}
}

.saved-tabs-wrapper {
	transition: opacity 250ms, height 500ms;
	height: 55px;
	pointer-events: all;
	opacity: 1;

	&.hidden {
		opacity: 0;
		height: 0px;
		pointer-events: none;
		overflow: hidden;
	}
}

.profile-icon {
	display: flex;
	height: 40px;
	width: 40px;
	position: relative;
	box-shadow: 0 0 17px 1px #00000024;
	border-radius: 50%;

	& > {
		.Polaris-Icon,
		.Polaris-Avatar,
		.Polaris-Icon svg {
			width: inherit;
			height: inherit;
			min-width: unset;
		}
	}
}

.profile-icon__wrapper {
	padding: 10px;
	border-radius: 50%;
	background: $main1;
	display: flex;
	height: 50px;
	width: 50px;
	align-items: center;
	justify-content: center;

	.Polaris-Icon {
		width: 22px;
	}
}

.board-new-row {
	.Polaris-Button__Text {
		text-transform: capitalize;
	}
}

.board-row-title {
	color: $linkColor;
}

.Polaris-Filters-ConnectedFilterControl__Item {
	button.Polaris-Button {
		box-shadow: none;
	}
}

.attachment_files button.Polaris-Button.Polaris-Button--destructive.Polaris-Button--iconOnly {
	min-height: 1.25rem;
	min-width: 1.25rem;
	// opacity: 0.5;
	transition: opacity 250ms, filter 250ms;
	filter: grayscale(0.5);

	.Polaris-Icon {
		width: 0.75rem;
		height: 0.75rem;
	}

	&:hover {
		filter: grayscale(0);
		opacity: 1;
	}
}

.content-wrapper {
	padding: 1rem;
}

.cell-datetime {
	display: flex;
	justify-content: center;
	gap: 0.625rem;
	align-items: center;

	.Polaris-Icon {
		margin: 0;
		width: 0.9375rem;
		height: 0.9375rem;
	}
}

.board-container.view-calendar {
	.fc-toolbar-title {
		text-transform: capitalize;
	}

	.fc-scrollgrid-section-header th,
	td,
	.fc-theme-standard.fc-scrollgrid,
	.fc-scrollgrid.fc-scrollgrid-liquid {
		border: none;
	}

	.fc .fc-daygrid-day-top {
		flex-direction: initial;
		justify-content: flex-start;
	}

	.fc-daygrid-day.fc-day {
		border-radius: 0.625rem;
		transition: background 250ms;
	}

	.fc-scrollgrid-section.fc-scrollgrid-section-body > td,
	.fc-daygrid-day.fc-day {
		border: thin solid #d9d9d9;
		border-radius: 0.3125rem;
	}

	.fc-non-business {
		background: transparent;
	}

	.fc-col-header-cell .fc-scrollgrid-sync-inner {
		justify-content: flex-start;
		display: flex;
		text-transform: capitalize;
	}

	.fc-daygrid-day.fc-day:not(.fc-day-other) {
		&:hover {
			background: rgba(40, 40, 40, 0.1);
			background-color: rgba(40, 40, 40, 0.1);
		}
	}

	.fc-day-today {
		background: rgba(40, 40, 40, 0.15) !important;
		background-color: rgba(40, 40, 40, 0.15) !important;
	}

	.fc-timegrid {
		.fc-col-header-cell .fc-scrollgrid-sync-inner {
			justify-content: center;
		}
	}
}

body[theme="dark"] {
	.board-container.view-calendar {
		.fc-scrollgrid-section.fc-scrollgrid-section-body > td,
		.fc-daygrid-day.fc-day {
			border: thin solid #3f3f3f;
		}

		.fc-day-today {
			background: rgba(115, 115, 115, 0.35) !important;
			background-color: rgba(115, 115, 115, 0.35) !important;
		}

		.fc-daygrid-day.fc-day:not(.fc-day-other) {
			&:hover {
				background: rgba(115, 115, 115, 0.15);
				background-color: rgba(115, 115, 115, 0.15);
			}
		}
	}
}

.cellDropdown-actionlist .Polaris-ActionList__Item {
	.board-dropdowncell-choice {
		display: flex;
		align-items: center;
		gap: 0.625rem;

		.edit-action-buttons {
			opacity: 0;
			transition: opacity 100ms;
			pointer-events: none;
			display: flex;
			gap: 0.3125rem;
			align-items: center;

			.edit-button-inline .Polaris-Icon {
				width: 0.9688rem;
				height: 0.9688rem;
			}
		}
	}

	&:hover {
		.board-dropdowncell-choice .edit-action-buttons {
			opacity: 1;
			pointer-events: auto;
		}
	}
}

.clear-button-inline {
	opacity: 0.6;
	transition: color 250ms, opacity 250ms;

	&:hover {
		opacity: 1;
		color: $red !important;

		.Polaris-Icon {
			fill: $red !important;

			svg,
			path {
				fill: $red !important;
			}
		}
	}
}

.board-empty_screen {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	max-height: 100%;
	// position: absolute;
	gap: 0.625rem;

	h2 {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}
}

.Polaris-LegacyCard {
	background-color: $cardbg;
}

.column-header.hover-bg.active {
	background-color: $main3;
	background: $main3;
}

.column-header:hover:not(.focused):not(.drag-over).hover-bg,
.collapsed-group:hover {
	background-color: $main2;
	background: $main2;
}

.board-item .board-row .fixed-column input[class^="quickadd"] {
	background-color: $main2;
	color: $textColor;
}

.large-upload-icon {
	margin-top: 50px;

	.Polaris-Icon {
		width: 3.125rem;
		height: 3.125rem;

		svg {
			fill: #666;
		}
	}
}

.microsoft-teams-link {
	display: flex;
	gap: 1ch;

	.Polaris-Icon {
		margin: 0;
	}
}

.trigger-description .Polaris-Text--root .entry.focused {
	color: $textColor;
}

.trigger-description .Polaris-Text--root .entry {
	border-color: rgba(0, 0, 0, 0.5);

	&:hover {
		border-color: rgba(0, 0, 0, 1);
	}
}

body[theme="dark"] {
	.trigger-description .Polaris-Text--root .entry {
		border-color: rgba(255, 255, 255, 0.5);

		&:hover {
			border-color: rgba(255, 255, 255, 1);
		}
	}
}

.miniboard_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1.25rem;
	margin-bottom: 0.625rem;
	position: relative;

	.boardfilter > div > .Polaris-Filters__Container {
		background: transparent;
	}

	// .create_button {
	// 	width: 170px;
	// }

	.Polaris-TextField,
	.Polaris-TextField__Input {
		border-radius: 6px;
	}

	.boardfilter *,
	#FILTER * {
		border-radius: 6px;
	}

	.Polaris-Filters-ConnectedFilterControl__AuxiliaryContainer {
		display: flex;
		align-items: center;
		padding-inline: 5px;
	}
}

.miniboard {
	.board-column {
		.Polaris-Button {
			min-height: 1.875rem;
			min-width: 1.875rem;

			.Polaris-Button__Content {
				font-size: 0.8125rem;
			}
		}

		.row-snippet-title {
			color: $textColor;
		}
	}

	.row_snippet .info-view__labels {
		text-align: end;
	}

	.row_snippet__label,
	.row_snippet .info-view__labels {
		color: var(--textColor2);
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
	}

	.row_snippet__value {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.info-view__values {
		font-size: 14px;
		line-height: 16px;
	}
}

.attachment_files {
	border-radius: 0.3125rem;
	padding: 0.625rem;

	&:not(.plain) {
		box-shadow: $boxShadow;
	}

	&.plain {
		width: max-content;
	}

	.Polaris-Button.Polaris-Button--plain {
		text-decoration: none;
	}

	.attachment-title {
		display: inline-flex;
		gap: 0.625rem;
	}

	.open-button {
		height: 100%;
		display: flex;
		align-items: center;
	}
}

.attachments_wrapper {
	margin-bottom: 1.5625rem;

	.attachment_files:nth-child(odd) {
		background: var(--main2);
	}
}

.TabButton.active {
	color: #ffffff;
	background-color: var(--sidebarBg);
}

body[theme="dark"] {
	.TabButton.active {
		color: rgb(15, 15, 15);
		background-color: #ffffff;
	}
}

.board-column {
	.attachment_files {
		margin: 0;
		padding: 0;
		align-items: center;
		min-height: 30px;
		justify-content: center;

		.attachment-title {
			flex: unset;
		}
	}
}

.row_comment {
	.Polaris-Connected__Item {
		padding: 2px;
	}

	.Polaris-Stack__Item--fill {
		overflow: unset;
	}
}

.img_link {
	color: var(--textColor);
	text-decoration: none;

	&:hover {
		color: var(--textColor);
		text-decoration: underline;
	}
}

.info-view__row__label,
.info-view__row__value {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre;
}

.contact_selector {
	.sel > div {
		margin-top: -2px;
		height: 15px;
	}
}

.board_expandable_section {
	.miniboard {
		.row_snippet {
			background-color: var(--main1);
		}

		.miniboard_header {
			.Polaris-TextField,
			.Polaris-TextField__Input {
				// background: var(--main1);
				// background-color: var(--main1);
			}
		}
	}
}

html body .Polaris-DatePicker__Day--disabled {
	background-color: transparent;
	opacity: 0.25;
}

html body .worktime_modal {
	.Polaris-TextField__Backdrop::after {
		opacity: 0 !important;
	}

	.Polaris-TextField__Input {
		text-align: center;

		&:focus {
			border: none;
		}
	}
}

.Polaris-Tooltip__Label {
	color: var(--textColor);
}

.react-switch-handle {
	box-shadow: 0px 0px 3px #00000078;
}

.react-switch-bg {
	box-shadow: inset 3px 2px 4px rgba(0, 0, 0, 0.25);
}

.Polaris-Select__Content {
	min-height: 0.625rem;
}

.Polaris-Backdrop--belowNavigation {
	z-index: 99;
}

.Polaris-TopBar-SearchField .Polaris-TopBar-SearchField__Input {
	&:focus,
	&:focus-within {
		min-width: 200px;
	}
}

.cell-contact {
	&:hover {
		.contact_link {
			opacity: 1;
			// width: 25px;
		}
	}
}

.cell_company_number__confilct_button {
	> div {
		line-height: 100%;
	}

	svg {
		fill: var(--textColor) !important;
	}
}

.cell_company_conflict_li {
	a {
		display: flex;
		gap: 0.3125rem;
		align-items: center;
		color: var(--textColor);
		width: max-content;

		&:hover {
			text-decoration: underline;
		}

		.Polaris-Icon {
			margin: 0;
			height: 0.9375rem;
			width: 0.9375rem;
		}
	}
}

.popover_close_button {
	transform: opacity 250ms;
	&:hover {
		opacity: 1;
	}
}

.Polaris-Choice__Label {
	color: var(--textColor);
}

@media (max-width: 48.0624em) {
	body:not(.nav-active):not([data-lock-scrolling]) {
		--navigation-sidebar-width: 0px;
		.mainmenu-item__secondary__collapse-btn {
			display: none;
		}
	}
}

.board_view_form_content {
	display: flex;
	gap: 0.625rem;
	align-items: center;

	div:first-of-type {
		flex: 1;
	}
}

.integration_close_btn {
	&.web .Polaris-Icon svg {
		fill: rgb(0, 0, 0) !important;
	}
}

.prupose_new_time_modal_times {
	.group_datetime {
		.Polaris-TextField,
		.Polaris-Modal-Dialog__Modal input.time_input_hours,
		.Polaris-TextField__Backdrop {
			background: transparent !important;
			box-shadow: none !important;
		}
		background-color: var(--modals-input-bg);
	}
}

.triggers_wrapper {
	& > div > .Polaris-LegacyCard {
		margin-bottom: 0.625rem;
	}
}

button.Polaris-ActionMenu-MenuAction {
	background: transparent;
	outline: none;
	box-shadow: none;
	display: flex;
	padding: 0.3125rem;
	cursor: pointer;
}

html body {
	.board-container.view-kanban {
		.Polaris-Page.Polaris-Page--fullWidth.kanban {
			padding-top: 0 !important;
		}
	}
	.Polaris-Filters-ConnectedFilterControl.Polaris-Filters-ConnectedFilterControl--right {
		background-color: var(--main3);
		border-radius: 50px;

		.Polaris-TextField__Backdrop {
			border-top-left-radius: 50px;
			border-bottom-left-radius: 50px;
			background-color: var(--main3);
			box-shadow: -2px 2px 9px 0px rgba(0, 0, 0, 0.1882352941) !important;
		}

		.Polaris-Button {
			box-shadow: none;
			background: transparent;
			&::after {
				inset: 0;
				box-shadow: none;
			}
		}
	}
}

.miniboard,
.document_sheet {
	.board-empty_screen {
		max-height: 300px;
		height: 300px;
		margin-top: 20px;

		img {
			height: 100%;
		}
	}
}

.sheet-board-cell {
	*[data-polaris-tooltip-activator] {
		margin-left: 7px;

		& > span {
			margin: 0 !important;
		}
	}
}

@import "./documents.scss";
@import "./circleDiagram.scss";
@import "./boardlogg.scss";
@import "./darkTheme.scss";
@import "./dashboard.scss";
@import "./calendarModalContent.scss";
@import "./todo.scss";
@import "./calendar.scss";
@import "./contactTabs.scss";
@import "./new_datepicker.scss";
@import "./polaris.scss";
