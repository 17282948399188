html body {
	.no_date_suffix {
		input[type="date"],
		input[type="datetime-local"] {
			&::-webkit-inner-spin-button,
			&::-webkit-calendar-picker-indicator {
				display: none;
				-webkit-appearance: none;
			}
		}

		&.time_input {
			display: flex;
			align-items: center;
			height: 100%;

			& > div {
				padding: 0.6250rem 0;
			}
		}
	}

	.new_datepicker__popup_content {
		.react-datepicker__time-container {
			width: 100%;

			.react-datepicker__time-box {
				width: 100%;
			}

			.react-datepicker__time-list-item {
				font-size: 12px;
				height: 35px;
			}

			.react-datepicker-time__header {
				font-size: 12px;
			}
		}
		// .react-datepicker {
		// 	min-width: calc(100% - calc(85px + 0.6250rem));
		// }

		// .react-datepicker__time-container--with-today-button,
		// .react-datepicker__time-container {
		// 	right: -97px;
		// }

		// .react-datepicker,
		// .react-datepicker__month,
		// .react-datepicker__month-container {
		// 	min-width: calc(100% - calc(85px + 0.6250rem));
		// 	margin: 0;
		// }

		.react-datepicker__time-container .react-datepicker__time,
		.react-datepicker-time__header {
			background: transparent;
			color: var(--textColor);
		}

		.react-datepicker__time-container {
			border-left-color: $textColor2;
		}

		.react-datepicker__time-container
			.react-datepicker__time
			.react-datepicker__time-box
			ul.react-datepicker__time-list
			li.react-datepicker__time-list-item:hover {
			background-color: $textColor2;
		}
	}

	.new_datepicker {
		input[type="date"],
		input[type="datetime-local"] {
			&::-webkit-inner-spin-button,
			&::-webkit-calendar-picker-indicator {
				display: none;
				-webkit-appearance: none;
			}
		}

		min-width: 400px;
		max-width: 500px;

		&.fullWidth {
			width: 100%;
		}

		&.expanded {
			width: max-content;
		}
	}

	.new_datepicker__field {
		.Polaris-TextField__Backdrop::after {
			border-color: transparent !important;
		}
		// new_datepicker__popup_content

		.Polaris-TextField__Suffix {
			margin-right: 0.25em;
			cursor: pointer;
		}

		.expand_button {
			cursor: pointer;
			display: flex;

			.Polaris-Icon {
				width: 1em;
			}
			svg {
				width: 1em;
				height: 1em;
				fill: var(--textColor);
			}
		}
	}

	.new_datepicker__field {
		flex: 1;
		display: flex;
	}
	.new_datepicker__calendar,
	.no_date_suffix > div {
		// background: var(--cardbg);
		padding: 0.6250rem;
	}

	.react-datepicker__day--disabled {
		opacity: 0.5;
	}

	.react-datepicker__day:not(.react-datepicker__day--disabled):not(.react-datepicker__day--selected):not(.react-datepicker__day--in-range):not(
			.react-datepicker__day--selecting-range-end
		):hover,
	.react-datepicker__month-text:hover,
	.react-datepicker__quarter-text:hover,
	.react-datepicker__year-text:hover {
		// background: lighten($secondaryBackgroundColor, 20%);
		background: transparent;

		.day-inner {
			// border-color: #ffffff38;
			color: $textColor;
		}
	}

	.react-datepicker__day,
	.react-datepicker__day-names,
	.react-datepicker__day-name,
	.react-datepicker__current-month {
		color: #ffffff;
		font-size: 0.7500rem;
		font-weight: bold;
		flex: 1;
	}
	// For when showing week number
	// .react-datepicker__day-name:first-child {
	// 	flex: initial;
	// }
	.react-datepicker__current-month {
		font-size: 0.8125rem;
	}

	.react-datepicker,
	.react-datepicker__header {
		background-color: transparent;
		border-color: transparent;
	}

	.react-datepicker,
	.react-datepicker__month,
	.react-datepicker__month-container {
		min-width: 100%;
		margin: 0;
	}

	.react-datepicker__day-names,
	.react-datepicker__week {
		justify-content: space-around;
		display: flex;
	}

	// Days to cover the width on not only be clickable in the circle
	.react-datepicker__week {
		align-items: stretch;
	}

	.react-datepicker__day--keyboard-selected,
	.react-datepicker__month-text--keyboard-selected,
	.react-datepicker__quarter-text--keyboard-selected,
	.react-datepicker__year-text--keyboard-selected {
		background: transparent;
		border-color: transparent;
	}
	.react-datepicker__current-month {
		text-transform: capitalize;
	}
	.react-datepicker__day.calendar-day,
	.react-datepicker__day-name {
		text-transform: capitalize;

		&.react-datepicker__day--selected {
			color: $textColor;
			background: transparent;
			.day-inner {
				color: $textColor;
				border-color: $textColor;
			}
		}
	}

	.react-datepicker .react-datepicker__week-number {
		display: flex;
		align-items: center;
	}

	.react-datepicker__day-name,
	.react-datepicker__current-month {
		color: $textColor;
	}

	.react-datepicker__day.calendar-day {
		display: flex;
		justify-content: center;
		color: #7a7585;

		.day-inner {
			height: 30px;
			width: 30px;
			max-width: 30px;
			background: transparent;
			border-radius: 50%;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			-ms-border-radius: 50%;
			-o-border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			transition: border-color 250ms, color 250ms;

			border-width: 2px;
			border-style: solid;
			border-color: transparent;

			// flex-direction: column;

			.dot-container {
				height: 7px;
				display: flex;
				flex-wrap: wrap;
				overflow: hidden;
				padding: 0 2px;
				justify-content: center;

				position: absolute;
				bottom: 0;
			}
		}

		&.react-datepicker__day--in-range {
			color: var(--textColor);
		}

		&.react-datepicker__day--selected .day-inner {
			border-color: $textColor;
		}

		&:hover:not(.active) {
			border-color: rgb(150, 150, 150);
		}
	}

	.react-datepicker__day.calendar-day,
	.react-datepicker__day-name {
		width: 35px;
		height: 30px;
		align-items: center;
	}

	.react-datepicker__month-read-view--selected-month {
		color: var(--textColor);
		font-size: 0.6250rem;
		text-transform: capitalize;
	}

	.react-datepicker__month-read-view--down-arrow {
		top: 4px;
	}

	.react-datepicker__day--keyboard-selected,
	.react-datepicker__day--range-start {
		background-color: #1d5d90;
	}
}

html body {
	.react-datepicker__header.react-datepicker__header--time {
		display: none;
	}

	// .time_input {
	// }
}

html body .inheritActivator {
	display: flex;
	gap: 0.6250rem;

	.no_date_suffix > div {
		padding: 0;
	}

	.Polaris-TextField__Input {
		padding-block: 0;
	}
}

html body .leaderboard_small .inheritActivator {
	.Polaris-TextField__Input {
		min-height: unset;
	}
}

html body {
	.react-datepicker__month-read-view {
		visibility: hidden !important;
		pointer-events: none;
	}

	.react-datepicker__year-option,
	.react-datepicker__month-option,
	.react-datepicker__month-year-option {
		font-size: 13px;
		color: var(--textColor);

		&:hover {
			background-color: var(--main2);
		}
	}

	.react-datepicker__year-dropdown,
	.react-datepicker__month-dropdown,
	.react-datepicker__month-year-dropdown {
		background-color: var(--main3);
		box-shadow: var(--boxShadow);
		border-color: var(--main2);
	}
}

.time_input {
	width: 4.0625rem;
}

.react-datepicker__time-list-item--disabled {
	opacity: 0.75;
}
