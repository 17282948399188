html body #root {
	.fixed_calendar {
		// padding-top: 50px;

		.Polaris-Page-Header .sync_calendar_btn {
			margin-left: 100px;

			.Polaris-Button__Content {
				font-size: 13px;
				line-height: 15px;
			}

			.Polaris-Icon {
				width: 1.0938rem;
				height: 1.0938rem;
			}
		}

		.fc-timegrid-slot.fc-timegrid-slot-label {
			border-color: transparent;
		}

		.fc-timegrid-slot.fc-timegrid-slot-label[data-time="00:00:00"] {
			display: none;
		}

		.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
			transform: translateY(-50%);
		}

		.fc-event-main {
			// padding-left: 5px;
			padding: 5px;
		}

		.fc-timegrid-slot-minor {
			border-color: transparent !important;
		}

		.fc-timegrid-event-harness-inset .fc-timegrid-event,
		.fc-timegrid-event.fc-event-mirror {
			box-shadow: none !important;
		}

		.fc-timegrid-event.fc-v-event.fc-event,
		.fc-daygrid-event.fc-daygrid-block-event.fc-event {
			border-top-color: transparent !important;
			border-right-color: transparent !important;
			border-bottom-color: transparent !important;
			border-left-width: 5px;
		}

		.fixed_calendar_body {
			// padding: 0 60px;
			background-color: var(--main2);
			border-radius: 6px;
			box-shadow: 1px 4px 9px rgba(0, 0, 0, 0.25);

			.fc-daygrid-event-harness.fc-daygrid-event-harness-abs,
			.fc-timegrid-event-harness,
			.fc-timegrid-event-harness {
				// &::before {
				// 	content: "";
				// 	width: 2px;
				// 	height: 100%;
				// 	background-color: red;
				// 	position: absolute;
				// }
			}

			.fc .fc-non-business {
				background: rgba(0, 0, 0, 0.2);
			}

			.fc-daygrid-block-event .fc-event-time,
			.fc-daygrid-block-event .fc-event-title {
				padding: 1px 5px;
			}

			.fc-event-title {
				font-weight: 400;
			}

			.fc-event-time {
				font-weight: bold;
			}

			.fc-daygrid-event.fc-daygrid-block-event {
				border-radius: 0 6px 6px 0;
			}

			.fc-day-today:not(.fc-more-popover) {
				background: var(--main3-50);
			}

			thead {
				th {
					border: none;
				}

				.fc-scroller {
					border-radius: 6px;
				}

				tbody {
					height: 60px;
					background: var(--main3);
					border-radius: 6px;

					.fc-col-header-cell .fc-scrollgrid-sync-inner {
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;

						a {
							font-weight: 700;
							font-size: 14px;
							line-height: 17px;
							text-transform: capitalize;
						}
					}
				}
			}

			.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
				display: none;
			}

			.fc-scrollgrid {
				border: none;
			}

			.fc-theme-standard td:not(.fc-timegrid-slot.fc-timegrid-slot-label),
			.fc-theme-standard th {
				border-color: var(--main3);
			}
		}

		div.fc-popover {
			background: var(--main2);
			box-shadow: 2px 2px 14px 0px #161616;
			border: none;
		}

		.fc-daygrid-more-link {
			color: var(--textColor);
			font-weight: bold;
		}
	}

	.calendar_header {
		display: flex;
		justify-content: space-between;
		background: var(--main2);
		padding: 0.6250rem;
		// margin: 10px 0;
		// margin-bottom: 30px;
		box-shadow: 1px 4px 9px rgba(0, 0, 0, 0.25);
		border-radius: 6px;
		position: relative;
		margin-bottom: 1.5625rem;
		// padding: 0 60px;
	}

	.calendar_view_header {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;
		gap: 1.2500rem;
		// height: 60px;
		background: var(--main2);
		position: absolute;
		inset: 0;
		border-radius: 6px;

		h2 {
			font-weight: bold;
			font-size: 22px;
			line-height: 26px;
			text-transform: capitalize;
		}

		button.Polaris-Button.Polaris-Button--iconOnly {
			background: transparent;
			height: 100%;
			box-shadow: none;
			border-radius: 0;

			.Polaris-Icon svg {
				fill: var(--textColor);
			}
		}
	}

	.calendar_header__button {
		// height: 100px;
		min-width: 75px;
		border: none;
		background: transparent;
		color: var(--textColor);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0.6250rem;
		align-items: center;
		transition: background 250ms, filter 250ms;
		border-radius: 6px;
		cursor: pointer;

		&:hover {
			filter: brightness(1.2);
		}

		.calendar_header__button__icon {
			height: 35px;
			padding: 10px;

			.Polaris-Icon,
			svg,
			img {
				height: 100%;
				width: 100%;
			}
		}

		&.active {
			background-color: var(--main3);
			box-shadow: inset 2px 4px 4px rgba(0, 0, 0, 0.25);
		}
	}

	.calendar_header__buttogroup {
		display: flex;
		flex-direction: row;
		z-index: 1;
		// gap: 0.6250rem;
	}

	.calendar_header__actions {
		margin-bottom: 0.6250rem;
		padding: 0 0.3125rem;
	}
}
.calendar .react-datepicker__day.calendar-day,
.react-datepicker__day-name {
	font-size: 13px;
}
.calendar > div {
	max-width: 600px;
	margin: 0 auto;
	.react-datepicker__navigation--next {
		right: calc(50% - 160px);
		top: 5px;
	}
	.react-datepicker__navigation--previous {
		left: calc(50% - 160px);
		top: 5px;
	}
	.react-datepicker__current-month {
		font-size: 16px;
		margin-bottom: 20px;
	}
}

.react-datepicker__day--outside-month {
	opacity: 0.35;
}
