.link_preview {
	.title {
		font-weight: 700;
	}

	.description_wrapper {
		display: flex;
		gap: 1.2500rem;
	}

	.image {
		border-radius: 6px;
		max-height: 150px;
	}

	iframe {
		aspect-ratio: 16/9;
		max-height: 150px;
		width: auto;
	}

	.cover {
		max-width: 100px;
		object-fit: contain;
		border-radius: 6px;
	}
}
