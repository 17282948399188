.contact_tabs {
	.contact_tabs_bar {
		background-color: var(--cardbg);
		border-radius: 0.4375rem;
		display: flex;
		padding: 5px 10px 0px 10px;
		justify-content: space-around;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		margin-bottom: 1.875rem;
		overflow: auto;

		.contact_tab {
			border-radius: 10px 10px 0px 0px;
			// min-width: 125px;
			flex: 1;
			display: flex;
			// flex-direction: column;
			padding: 0.625rem;
			transition: background 250ms, box-shadow 250ms;
			box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0);
			cursor: pointer;
			background-color: transparent;
			position: relative;
			align-items: center;
			min-height: 100px;
			justify-content: center;

			.contact_tab__count {
				display: flex;
				justify-content: flex-end;
				position: absolute;

				transform: translate(100%, -80%);
				top: 0;
				right: -2px;
				// span {
				// 	border-radius: 2px;
				// 	color: $green;
				// 	background-color: rgba($green, 0.35);
				// 	min-width: 26px;
				// 	min-height: 26px;
				// 	padding: 3px;
				// 	font-size: 12px;
				// 	text-align: center;
				// }

				&.disabled {
					span {
						color: rgb(150, 150, 150);
						background-color: var(--main1);
					}
				}
			}

			.contact_tab__icon {
				display: flex;
				justify-content: center;

				svg,
				.Polaris-Icon {
					height: 25px;
				}

				svg,
				path {
					fill: var(--textColor) !important;
				}
			}

			h3 {
				text-align: center;
				margin-top: 0.2188rem;
				font-size: 13px;
				line-height: 15px;
			}

			&.active {
				background-color: var(--main1);
				box-shadow: inset 2px 4px 7px rgba(0, 0, 0, 0.25);
			}
		}
	}

	.Polaris-Box {
		padding-top: 0;
	}

	.documents {
		padding: 0;
		margin-top: 1rem;
	}

	.styled_document_item {
		margin: 0.3125rem 0;
		width: 100%;
	}

	.fortnox_invoice_view {
		padding-top: 0 !important;

		& > .Polaris-Page {
			padding: 0;
		}
	}
}
