$startWidth: 100px;
$triggerHeight: 60px;
$iconSize: 24px;

nav.Polaris-Navigation {
	min-width: 100%;
	width: 100%;
}

div#AppFrameNav {
	width: var(--navigation-sidebar-width);
	min-width: var(--navigation-sidebar-width);
	transition: width var(--mainmenu-transition-time), min-width var(--mainmenu-transition-time);
	// z-index: 500;
	overflow: hidden;

	&[hidden] {
		--navigation-sidebar-width: 0;
	}
}

.menu-items {
	// min-height: calc(100% - 1px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: auto;
	padding-left: 0;
	padding-bottom: 0.625rem;
	padding-top: 0.9375rem;
	height: 100%;
}

.mainmenu-item__secondary__section {
	.mainmenu-item__secondary__section_title {
		padding-bottom: 0.625rem;
		font-weight: 600;
	}

	.mainmenu-item__secondary__item {
		margin-left: 0.5rem;
	}

	&.haveDivider {
		padding-bottom: 2rem;

		.mainmenu-item__secondary__section_title {
			border-bottom: 1px solid rgb(64, 70, 110);
		}
	}
}

.mainmenu-item {
	display: flex;
	align-items: center;
	justify-content: center;
	width: $startWidth;
	padding: 0.625rem 0;

	&.logo {
		svg {
			margin-left: 5px;
			margin-top: 7px;
		}

		svg[filltype="pathfill"] path,
		svg[filltype="pathfill"] circle {
			fill: $accent;
		}
	}

	// position: relative;

	.mainmenu-item-trigger svg,
	.mainmenu-item-trigger img {
		width: $iconSize;
		height: $iconSize;
		object-fit: cover;
		transition: transform 250ms, fill 250ms, filter 250ms, stroke 250ms;
		z-index: 1;

		&:not([filltype="stroke"]) {
			fill: #ffffff;
		}

		&[filltype="stroke"] path {
			transition: stroke 250ms;
			stroke: #ffffff;
		}
		&[filltype="pathfill"] path {
			transition: stroke 250ms;
			fill: #ffffff;
		}
	}

	img.navigation-sidebar__logo {
		height: 28px;
		width: 28px;
	}

	.bottom-scroll-shadow {
		display: none;
		width: calc(var(--navigation-sidebar-width) - #{$startWidth});
		left: $startWidth;
		transition: opacity 250ms;
		position: fixed;
	}

	.mainmenu-item-trigger {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		// padding: 0.6250rem 0;
		height: $triggerHeight;
		position: relative;
		cursor: pointer;
		flex-direction: column;
		gap: 0.5em;

		.mainmenu-item-trigger_title {
			color: #ffffff;
			font-size: 0.82em;
			transition: opacity 250ms, color 250ms, filter 250ms, color 250ms;
			opacity: 0.7;
			user-select: none;
		}

		// &::after {
		// 	content: "";
		// 	position: absolute;
		// 	height: calc(#{$iconSize} + 26px);
		// 	width: calc(#{$iconSize} + 26px);
		// 	background-color: var(--sidebarBg2);
		// 	border-radius: 0.6250rem;
		// 	z-index: 0;
		// 	opacity: 0;
		// 	transform: scale(0.75);
		// 	transition: opacity 250ms, transform 250ms;
		// 	box-shadow: inset 2px 2px 2px 2px #000000;
		// }

		&::before {
			content: "";
			height: 15px;
			width: 15px;
			position: absolute;
			left: 0rem;
			background-color: $accent;
			border-radius: 50%;
			align-self: center;
			transform: translateX(-50%);
			box-shadow: 0 0 10px 3px $accent;
			opacity: 0;
			transition: opacity 250ms;
		}

		// &::after {
		// 	content: attr(data-content);
		// 	position: absolute;
		// 	padding: 0.3125rem;
		// 	background-color: var(--sidebarBg);
		// 	color: #ffffff;
		// 	left: $startWidth;
		// 	opacity: 0;
		// 	transform: translateX(-25%);
		// 	transition: opacity 100ms, transform 250ms;
		// }

		// &::after {
		// 	left: 100px;
		// 	transform: translateX(-23px) rotate(270deg);
		// 	transition: opacity 100ms;
		// 	content: "";
		// 	position: absolute;
		// 	width: 0;
		// 	height: 0;
		// 	border-left: 15px solid transparent;
		// 	border-right: 15px solid transparent;
		// 	border-bottom: 15px solid var(--main1);
		// 	clear: both;
		// 	opacity: 0;
		// }

		&:hover {
			// &::after {
			// 	opacity: 1;
			// 	transform: translateX(0);
			// }

			.mainmenu-item-trigger_title {
				opacity: 1;
				color: $accent;
				filter: brightness(0.8);
			}

			svg:not([filltype="stroke"]) {
				fill: $accent;
				filter: brightness(0.8);
			}

			svg[filltype="stroke"] path {
				stroke: $accent;
				filter: brightness(0.8);
			}

			svg[filltype="pathfill"] path,
			svg[filltype="pathfill"] circle {
				fill: $accent;
				filter: brightness(0.8);
			}
		}
	}

	.mainmenu-item__secondary {
		// opacity: 0;
		// transform: translateX(-100%);
		// transition: transform var(--mainmenu-transition-time), opacity 150ms, width var(--mainmenu-transition-time), display 0ms, padding-left 250ms;
		flex-direction: column;
		position: fixed;
		top: 0;
		color: #ffffff;
		left: $startWidth;
		padding: 0;
		padding-top: 35px;

		background: var(--sidebarBg2);
		// background: linear-gradient(90deg, rgba(0, 0, 0, 0.182), #00000038, 15%, var(--sidebarBg2));
		// border-right: 1px solid #797979;
		height: 100%;
		overflow: hidden;
		visibility: hidden;
		// display: none;
		// width: 0px;
		// width: calc(var(--navigation-sidebar-width) - #{$startWidth});
		width: calc(400px - #{$startWidth}) !important;
		box-shadow: 0px 0px 5px 1px #0000007a;

		.mainmenu-item__secondary__header {
			padding-right: 1.25rem;
			display: flex;
			justify-content: space-between;

			.mainmenu-item__secondary__header__title {
				color: $accent;
				font-weight: 600;
				font-size: 1.0938rem;

				display: flex;
				align-items: center;
				gap: 0.625rem;
			}
		}

		.mainmenu-item__secondary__actions {
			display: flex;
			align-items: center;
			gap: 0.625rem;
		}
		.search-trigger,
		.Polaris-Navigation__Action {
			background: transparent;
			border: none;
			cursor: pointer;
		}

		.section-serch {
			padding: 0.4688rem 0;
			width: 100%;
		}
		.mainmenu-item__secondary__item {
			padding: 0.4688rem 0;
			display: flex;
			align-items: center;
			flex: 1;
			justify-content: space-between;
			cursor: pointer;
			height: 40px;
			color: #ffffff;

			.Polaris-Navigation__Action {
				opacity: 0;
				transition: opacity 250ms;
			}
			> div,
			.mainmenu-item__secondary__item_inner {
				display: flex;
				gap: 0.625rem;
				align-items: center;
				word-break: break-all;
			}

			&,
			.Polaris-Icon,
			svg {
				transition: fill 250ms, color 250ms;
			}

			.Polaris-Icon {
				margin: 0;
			}

			svg,
			.Polaris-Icon,
			img {
				width: 20px;
				height: 20px;
				fill: #ffffff;
			}

			&:hover {
				color: $accent;

				.Polaris-Navigation__Action {
					opacity: 1;
				}

				svg,
				.Polaris-Icon {
					fill: $accent;
				}
			}

			&.active {
				color: $accent;
				font-weight: 600;

				svg,
				.Polaris-Icon {
					fill: $accent;
				}
			}
		}
		.Polaris-Navigation__ListItem {
			.Polaris-Navigation__Action {
				opacity: 0;
				transition: opacity 250ms;
			}

			&,
			.Polaris-Icon,
			svg {
				transition: fill 250ms, color 250ms;
			}

			svg,
			.Polaris-Icon,
			img {
				width: 20px;
				min-width: 20px;
				min-height: 20px;
				height: 20px;
				fill: #ffffff;
			}

			&.have_actions {
				.Polaris-Navigation__Badge {
					transform: translateX(100%);
				}
			}

			.Polaris-Navigation__Badge {
				transition: transform 250ms;
				margin: 0;
			}

			&:hover {
				color: $accent;

				.Polaris-Navigation__Badge {
					transform: translateX(0);
				}

				.Polaris-Navigation__Action {
					opacity: 1;
				}

				svg,
				.Polaris-Icon {
					fill: $accent;
				}
			}
		}
	}
	&.animating .mainmenu-item__secondary {
		visibility: visible !important;
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	&.url-active {
		.mainmenu-item-trigger::before {
			opacity: 1;
		}

		.mainmenu-item-trigger_title {
			opacity: 1;
			color: $accent;
		}

		.mainmenu-item-trigger {
			svg:not([filltype="stroke"]) {
				fill: $accent;
			}

			svg[filltype="stroke"] path {
				stroke: $accent;
			}

			svg[filltype="pathfill"] path,
			svg[filltype="pathfill"] circle {
				fill: $accent;
			}
		}
	}
	&.active {
		.mainmenu-item-trigger::after {
			opacity: 1;
			transform: scale(1);
		}
		.mainmenu-item__secondary {
			display: initial;
			overflow: auto;
			width: calc(var(--navigation-sidebar-width) - #{$startWidth});
			// transition: transform var(--mainmenu-transition-time), opacity 150ms, width var(--mainmenu-transition-time), display 250ms, padding-left 250ms;
			visibility: visible;

			.mainmenu-item__secondary__items {
				pointer-events: auto;

				.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action {
					padding: 0;
					margin: 0;
				}
			}
		}

		.mainmenu-item-trigger {
			svg:not([filltype="stroke"]) {
				fill: $accent;
			}

			svg[filltype="stroke"] path {
				stroke: $accent;
			}

			svg[filltype="pathfill"] path,
			svg[filltype="pathfill"] circle {
				fill: $accent;
			}
		}
	}

	.mainmenu-item__secondary__items {
		// margin-top: 0.6250rem;

		.workspace-items {
			// padding-left: 0.6250rem;
		}

		.Polaris-Navigation__Section {
			margin-bottom: 0.625rem;
			padding-top: 0 !important;

			.workspace-section-header {
				.Polaris-Navigation__Action {
					opacity: 1;
					transition: opacity 250ms;
				}

				&:hover {
					.workspace-section-title {
						color: $accent;
					}

					.Polaris-Navigation__Action {
						opacity: 1;

						svg {
							fill: $accent;
						}
					}
				}
			}
		}

		.Polaris-Navigation__SectionHeading {
			padding-left: 0 !important;
		}
	}
}
#AppFrameNav:not(.have_active) .mainmenu-item.url-active {
	.mainmenu-item-trigger::after {
		opacity: 1;
		transform: scale(1);
	}
}
// &:hover,
// &:focus,
// &:focus-visible,
// &:focus-within {

.Polaris-Navigation
	.Polaris-Navigation__PrimaryNavigation.Polaris-Scrollable.Polaris-Scrollable--vertical.Polaris-Scrollable--verticalHasScrolling
	div.navigation-sidebar,
.Polaris-Navigation {
	background: $sidebarColorBg !important;
	background-color: $sidebarColorBg !important;
	scrollbar-width: thin;

	.Polaris-Navigation__Text {
		color: $sidebarColor;
		font-weight: 600;
	}

	.Polaris-Navigation__SectionHeading span.Polaris-Navigation__Text {
		font-weight: 500;
		color: #cccedd;
	}

	.Polaris-Navigation__Item {
		margin: 0;
		position: relative;
		padding: 0.4688rem 0;
		font-size: 0.8125rem;
		display: flex;
		gap: 0.625rem;
		align-items: center;

		// &:before {
		// 	content: "";
		// 	height: 15px;
		// 	width: 15px;
		// 	position: absolute;
		// 	left: -0.9375rem;
		// 	background-color: $sidebarColorAccent;
		// 	border-radius: 50%;
		// 	align-self: center;
		// 	transform: translateX(-50%);
		// 	box-shadow: 0 0 10px 3px $sidebarColorAccent;
		// 	opacity: 0;
		// }

		&:focus,
		&:hover,
		&:active,
		&.active {
			&:before {
				opacity: 1;
			}
		}

		&:active,
		&.active {
			color: $accent;
		}

		&:hover {
			color: #ffffff;
			background: none !important;
		}

		&.Polaris-Navigation__Item--selected.Polaris-Navigation--subNavigationActive {
			&:before {
				opacity: 1;
			}
		}

		// .Polaris-Navigation__Item {
		// 	padding: 0.2188rem 0.5000rem;
		// 	font-size: 0.8125rem;
		// }
	}

	.Polaris-Navigation__Item--selected {
		color: $accent !important;
		.Polaris-Navigation__Icon svg,
		.Polaris-Icon svg {
			fill: $accent !important;
		}
	}
	@media (min-width: 40em) {
		.Polaris-Navigation__SectionHeading {
			font-size: 0.8438rem;
			padding-left: 0.4688rem;
		}
	}

	.Polaris-Navigation__SectionHeading {
		.Polaris-Navigation__Action {
			.Polaris-Icon {
				height: 1.0938rem;
				width: 1.0938rem;

				&,
				svg {
					fill: #ffffff;
				}
			}
		}
	}
}

.MainMenuTriggerTooltip_wrapper {
	> .mainmenu-item {
		padding: 0;
		margin-top: 0.625rem;
		margin-bottom: 0.625rem;
	}
}

.Polaris-TopBar {
	background: $mainBackgroundColor;

	.Polaris-TopBar-Menu__Activator:active,
	.Polaris-TopBar-Menu__Activator[aria-expanded="true"] {
		background: transparent;
	}
}
.Polaris-Frame--hasTopBar .Polaris-Frame__Navigation:not(.Polaris-Frame__Navigation--newDesignLanguage) {
	top: 0 !important;
	// z-index: 999;
	height: 100%;
}

.Polaris-TopBar-SearchField__Input {
	border-radius: 1.25rem;

	&::placeholder {
		color: rgb(130, 130, 130);
	}
}
.Polaris-TopBar-SearchField .Polaris-TopBar-SearchField__Icon svg {
	fill: rgb(130, 130, 130);
}
.Polaris-TopBar-SearchField__Backdrop {
	box-shadow: $boxShadow;
}

html .notification-indicator-container {
	border-radius: 25px;
	flex-direction: row;
	padding: 0.4em 0.6em;
	display: flex;
	gap: 10px;
	align-items: center;
	box-shadow: $boxShadow;
	background: $main2;
	min-width: 45px;
	justify-content: center;
	cursor: pointer;

	&,
	svg {
		transition: fill 150ms, color 150ms background 150ms;
	}

	svg {
		height: 20px;
		width: 20px;
	}

	&.active {
		svg {
			fill: $green;
		}
	}

	&:hover {
		svg {
			fill: $accent !important;
		}
	}
}

.Polaris-TopBar__SecondaryMenu {
	.Polaris-TopBar-Menu__Activator:hover {
		svg {
			fill: $accent !important;
		}
	}

	.Polaris-TopBar-Menu__Activator:hover {
		background: transparent;
	}
}

.Polaris-Navigation {
	border: none !important;
}

.Polaris-TopBar-UserMenu__Details {
	p {
		color: $textColor;
	}
}

.Polaris-TopBar-Menu__Activator {
	&:hover {
		background-color: $main2;
	}
}

// .Polaris-TopBar__SecondaryMenu {
// 	svg {
// 		fill: $textColor;
// 	}
// }

.Polaris-Navigation__Item {
	background: none !important;
}

.Polaris-TopBar__SecondaryMenu {
	margin-right: 1.875rem;
}

.Polaris-TopBar__SecondaryMenu,
.Polaris-TopBar__SecondaryMenu > .Polaris-Stack {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1.875rem;

	.Polaris-Stack__Item,
	.Polaris-Stack,
	.Polaris-TopBar-Menu__Activator {
		margin: 0;
		padding: 0;
	}
}

.Polaris-Frame__Navigation .bottom-scroll-shadow {
	background: linear-gradient(rgba(0, 0, 0, 0), $sidebarColorBg);
	height: 100px;
}
.Polaris-TopBar__LogoContainer {
	padding-left: 10px;
	padding-top: 10px;
	// display: none;
}
.navigation-sidebar {
	height: 100%;
}
.navigation-sidebar__logo__wrapper {
	margin: 0;
	width: $startWidth;

	img.navigation-sidebar__logo {
		padding: 30px;
		width: $startWidth;
	}
}

.Polaris-Frame__TopBar {
	width: calc(100% - var(--navigation-sidebar-width)) !important;
	margin-left: var(--navigation-sidebar-width) !important;
	transition: margin-left var(--mainmenu-transition-time), width var(--mainmenu-transition-time);
}

.Polaris-Navigation__Text,
.Polaris-Navigation__Icon {
	margin: 0 !important;
}

.mainmenu-item__secondary__item__text {
	font-weight: 600;
	color: var(--sidebarColor);
	line-height: 1.25rem;
}
.Polaris-Navigation__Item--selected {
	color: $accent;
	background: transparent;
}

.Polaris-Navigation__Action__Wrapper {
	display: flex;
	align-items: center;
}
body {
	main#AppFrameMain {
		padding-left: var(--navigation-sidebar-width) !important;
		// padding-left: 8.1250rem !important;
		// padding-top: 5.0000rem;

		@media (max-width: 767px) {
			padding-left: 0 !important;
		}
	}

	&.nav-active {
		--navigation-sidebar-width: 400px;
	}
}

// .Polaris-Navigation__PrimaryNavigation {
// 	overflow: visible;
// }

.mainmenu-item__secondary__collapse-btn {
	position: fixed;
	left: 100px;
	top: 10px;
	cursor: pointer;
	transition: transform var(--mainmenu-transition-time), left var(--mainmenu-transition-time), filter 250ms;
	border-radius: 50%;
	transform: translateX(-50%);

	z-index: 99999;
	filter: drop-shadow(1px 1px 5px #00000030);
	background: var(--sidebarBg);
	visibility: hidden;
	pointer-events: none;

	.Polaris-Icon {
		width: 30px;
		height: 30px;
		fill: #ffffff;
	}

	&:hover {
		transform: translateX(-50%), scale(1.2);
	}
}

body[data-lock-scrolling] {
	.mainmenu-item__secondary__collapse-btn {
		filter: brightness(0.2);
	}
}

body.nav-selected {
	.mainmenu-item__secondary__collapse-btn {
		left: var(--navigation-sidebar-width);
		transform: translateX(-50%);
		visibility: visible;
		pointer-events: auto;
		// box-shadow: $boxShadow;
		// background: #ffffff;
	}
}
body.nav-active {
	.mainmenu-item__secondary__collapse-btn {
		left: var(--navigation-sidebar-width);
		transform: translateX(-50%) rotateY(180deg);
		visibility: visible;
		pointer-events: auto;
		// box-shadow: $boxShadow;
		// background: #ffffff;
	}

	.mainmenu-item.active {
		.mainmenu-item-trigger::after {
			opacity: 1;
		}

		.mainmenu-item-trigger_title {
			opacity: 1;
			color: $accent;
		}

		.mainmenu-item__secondary {
			padding-right: 1.25rem;
			padding-left: 1.25rem;
		}
		.bottom-scroll-shadow {
			display: flex;
		}
	}
}

.Polaris-Navigation__RollupSection {
	list-style: none;
}

.mainmenu-item__secondary__searchbar {
	margin: 0.625rem 0;
	margin-bottom: 1.25rem;
}

.mainmenu-item__secondary__search-actions_wrapper {
	margin: 1.25rem 0;
	display: flex;
	flex-direction: row;
	gap: 0.625rem;

	.mainmenu-item__secondary__searchbar {
		margin: 0;
	}

	.Polaris-Navigation__Action {
		padding: 0;
		display: flex !important;
		transition: color;

		.Polaris-Icon {
			height: 1.5625rem;
			width: 1.5625rem;
		}
	}
}

.mainmenu-item__secondary__actions {
	.Polaris-Navigation__Action {
		display: flex;
		align-items: center;
		gap: 0.625rem;
		color: #ffffff;

		.Polaris-Icon,
		svg {
			fill: #ffffff;
		}
	}
}
.workspace-section-header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	> span[aria-describedby^="PolarisTooltipContent"] {
		overflow: hidden;
	}

	.Polaris-Navigation__Action .Polaris-Icon {
		fill: #ffffff;
	}

	> div:not([class]),
	> button.Polaris-Navigation__Action {
		display: flex;
		align-items: center;
	}
}
.workspace-section-title {
	font-weight: 500;
	color: #cccedd;
	flex: 1 1 auto;
	line-height: 1.25rem;
	font-size: 0.8438rem;
	text-transform: uppercase;
	overflow: hidden;
	text-overflow: ellipsis;
}

body[theme="light"] {
	.mainmenu-item .mainmenu-item-trigger::after {
		box-shadow: inset 2px 2px 2px 2px #969696d9;
	}
}

.Polaris-TopBar__SecondaryMenu {
	a,
	button {
		&:hover {
			svg {
				fill: var(--accent);
			}
		}
	}

	svg {
		fill: var(--textColor);
	}
}

.Polaris-TopBar {
	background: var(--main1) !important;
	background-color: var(--main1) !important;
}
