$sidebarTopSectionHeight: 180px;
$chatlistWidth: 300px;

.mainDashboard.chats {
	height: 100%;
}

.chats-list,
.chat-window,
.chat-view {
	button {
		transition: filter 250ms;
		cursor: pointer;

		&:hover {
			filter: brightness(1.15);
		}
	}

	.profile {
		object-fit: cover;
	}

	.Polaris-TextField,
	.Polaris-TextField__Input {
		border-radius: 0.6250rem;
		margin: 0;
	}

	.Polaris-TextField__Backdrop {
		box-shadow: none !important;
		display: none;
		&:after {
			opacity: 0;
		}
	}
}

.chat-view {
	height: 100%;
	display: flex;
	flex-direction: row;
	gap: 1.2500rem;
	min-height: 400px;
}

.no-selected-chat {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 1 auto;
	flex-direction: column;
	gap: 0.6250rem;

	.buttons-wrapper {
		display: flex;
		flex-direction: column;
		gap: 0.6250rem;
	}

	button {
		background-color: $cardbg;
		border-radius: 0.6250rem;
		padding: 1.2500rem 1.8750rem;
		font-size: 1.1250rem;
		display: flex;
		border: none;
		color: $textColor;
		cursor: pointer;
		transition: filter 250ms;
		justify-content: space-between;

		&:hover {
			filter: brightness(1.1);
		}

		.Polaris-Icon {
			margin: 0;
			margin-left: 1.2500rem;

			svg {
				fill: $green;
			}
		}
	}

	h1 {
		font-size: 1.8750rem;
		line-height: 1.8750rem;
		margin-bottom: 1.8750rem;
	}
}

.new-chat .no-selected-chat button {
	background-color: $cardbg;
}

.Polaris-Sheet {
	.chat-window .messages .message.skeleton {
		div:not(.row) {
			background: $main1 !important;
			animation: $pulseAnimation;
		}
		.chats-list .SkeletonLoader {
			animation: $pulseAnimation;
			.profile {
				background: $main1;
			}
			.name {
				background: $main1;
			}
		}
	}
}

.chat-window {
	display: flex;
	flex-direction: column;
	height: 100%;
	flex: 1 1 auto;
	overflow: hidden;

	.CommentInputContainer {
		align-items: center;
		display: flex;
		justify-content: space-between;
		width: 100%;
		background: $cardbg;
		padding: 0.9375rem;
		border-radius: $borderRadius;
		gap: 0.4688rem;

		.profile {
			width: 30px;
			height: 30px;
			border-radius: 50%;
		}

		.Polaris-TextField__Input {
			border: none;
			background: transparent;
			margin: 0;
			border-color: #ffffff1a;
			border-radius: 0;
			border-width: 2px;
			border-right-style: solid;
			border-left-style: solid;
		}

		.Polaris-TextField {
			background: none;
		}

		.Polaris-TextField__Backdrop {
			display: none;
		}

		.Polaris-TextField__Suffix {
			margin-right: 0;
		}

		.Polaris-Stack {
			align-items: center;
		}

		button.send {
			background: rgba($green, 0.85);
			color: #ffffff;
			border: none;
			font-weight: 700;
			display: flex;
			padding: 0.3750rem 0.9375rem;
			align-items: center;
			justify-content: center;
			border-radius: $borderRadius;

			.Polaris-Icon {
				margin-left: 3px;
				svg {
					fill: #ffffff;
				}
			}
		}
	}

	.messages {
		display: flex;
		flex-direction: column;
		margin-bottom: 11px;
		padding: 1.0000rem;
		height: 100%;
		overflow-y: scroll;

		&.skeleton {
			justify-content: flex-end;
		}

		.chat-window & {
			background: transparent !important;
		}

		.message {
			margin-top: 15px;
			display: flex;
			flex-direction: column;

			.media {
				display: flex;
				gap: 0.6250rem;
			}

			&.skeleton {
				div:not(.row) {
					background: $main2 !important;
					border-radius: $borderRadius;
					animation: $pulseAnimation;
				}

				.name {
					height: 0.5000rem;
					width: 20%;
					margin-bottom: 5px;
				}
				.row {
					display: flex;
					align-items: center;
					gap: 15px;
					width: 100%;

					.text {
						height: 1.8750rem;
						min-width: 30%;
					}
					.profile {
						min-width: 40px;
						height: 40px;
						border-radius: 0.2188rem;
					}
				}

				&.me .row {
					justify-content: flex-end;
				}
			}

			.first-row,
			.name-date {
				width: max-content;
			}

			.first-row {
				display: flex;
				gap: 15px;
				max-width: 60%;

				.Polaris-Avatar {
					height: max-content;
				}

				.profile {
					width: 40px;
					height: 40px;
					border-radius: 0.2188rem;
				}

				.text {
					background: var(--main2);
					border: thin solid rgba(125, 125, 125, 0.2);
					padding: 0.6250rem;
				}
			}
			&.mention .text {
				background: #948313;
				color: #ffffff;
			}

			.name-date {
				text-align: right;
				color: $textColor2;
				font-size: 0.5938rem;
				margin-bottom: 5px;
				position: relative;

				.Polaris-Spinner {
					position: absolute;
					transform: translateX(-115%);
					opacity: 0.75;
				}

				.error {
					color: $red;
					font-weight: bold;
					font-size: 1.5625rem;
					line-height: 1.5625rem;
					position: absolute;
					transform: translateX(-200%);
				}
			}
			&.me {
				align-items: flex-end;

				.text {
					border-radius: 15px 5px 15px 15px;
					background: rgba($blue, 0.95);
					color: #ffffff;

					a {
						color: #ffffff;
						text-decoration: underline;
					}
				}
			}

			&.other {
				align-items: flex-start;

				.text {
					border-radius: 5px 15px 15px 15px;
				}
			}

			&.system {
				.text {
					border-radius: 15px 15px 15px 15px;
					color: $textColor2;
				}
			}
		}
	}
}

.select-new-chat {
	width: 100%;
}

.chat-window .header {
	justify-content: space-between;

	.header-left {
		display: flex;
		gap: 0.6250rem;
		align-items: center;
	}
}

.chat-window .header,
.new-chat .header,
.select-new-chat .header {
	display: flex;
	background: $cardbg;
	border-radius: $borderRadius;
	padding: 0.6250rem;
	gap: 0.6250rem;

	.group-members {
		flex: 1 1 auto;
		overflow: hidden;

		.title {
			font-weight: 600;
			font-size: 0.9375rem;
			line-height: 0.9375rem;
			margin-bottom: 3px;
		}

		.members {
			overflow-x: scroll;
			display: flex;
			height: 22px;
			gap: 0.3125rem;

			.profile,
			.Polaris-Avatar {
				width: 22px;
				min-width: 22px;
				height: 22px;
				min-height: 22px;
				border-radius: 50%;
			}
		}
	}

	.chat-title {
		align-self: center;
		margin-right: 1.8750rem;

		color: $textColor;
		font-weight: 600;
		font-size: 1.2500rem;
		line-height: 1.2500rem;
	}

	.actions {
		display: flex;
		gap: 0.6250rem;
	}

	.actions button,
	& button {
		padding: 0.6250rem;
		background: $main3;
		border-radius: 0.3125rem;

		&.Polaris-Button--plain {
			margin: 0 !important;
			color: transparent;
		}

		.Polaris-Button__Icon {
			display: flex;
		}

		.Polaris-Icon {
			svg,
			img {
				height: 24px;
				width: 24px;
				fill: $green;
			}
		}
	}
}

.confirm-leave-chat {
	padding: 0.6250rem;
	display: flex;
	gap: 0.6250rem;
	flex-direction: column;

	.buttons-wrapper {
		display: flex;
		justify-content: space-between;
	}
}

.chats-list {
	max-height: 100%;
	width: $chatlistWidth;
	min-width: $chatlistWidth;
	background: $cardbg;
	overflow: hidden;
	border-radius: $borderRadius;
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 0.6250rem;

	.Polaris-Sheet & {
		width: 100%;
	}

	.SkeletonLoader {
		padding: 0.6250rem;
		animation: $pulseAnimation;
		direction: ltr;

		.profile {
			background: $main3;
		}
		.name {
			background: $main3;
			height: 20px;
			border-radius: 7px;
			width: 50%;
			margin-bottom: 7px;
		}
	}

	.Polaris-Connected {
		margin: 1em 0.0000rem;

		.Polaris-TextField {
			background-color: var(--main3);
			border: 1px solid $textColor;
			border-radius: 0.6250rem !important;
			// overflow: hidden;

			&,
			.Polaris-TextField__Input,
			.Polaris-TextField__Backdrop {
				border-radius: 0.6250rem !important;
			}
		}

		.add-new-chat {
			padding: 0.6250rem;
			border-radius: $borderRadius !important;
			background: var(--main3);
			border: none;

			.Polaris-Icon {
				height: 20px;
				width: 20px;

				svg {
					fill: rgba($green, 0.85);
				}
			}
		}
	}

	.me {
		padding: 0.7812rem;
		display: flex;

		.Polaris-Avatar {
			margin-right: 0.6250rem;
			height: max-content;
		}

		.profile {
			height: 50px;
			width: 50px;
			border-radius: 0.6250rem;
			margin-right: 0.6250rem;
		}

		.name {
			flex-grow: 1;
			font-weight: 700;
			font-size: 1em;
		}

		.subtext {
			color: rgb(125, 125, 125);
		}
		.settings-button {
			display: flex;
			height: fit-content;
			border: none;
			background: rgba($green, 0.2);
			padding: 0.3125rem;
			border-radius: 0.4688rem;
			cursor: pointer;
			align-self: flex-start;

			.Polaris-Icon {
				height: 15px;
				width: 15px;

				svg {
					fill: rgba($green, 0.85);
				}
			}
		}
	}

	.chats-list-item,
	.SkeletonLoader {
		display: flex;
		flex-direction: row;
		width: 100%;
		// justify-content: space-between;
		align-items: center;
		// padding-left: 1.2500rem;

		.number {
			height: fit-content;
		}

		.profile,
		.Polaris-Avatar {
			height: 30px;
			width: 30px;
			min-width: 30px;
			border-radius: 50%;
			margin-right: 0.6250rem;
		}

		.name {
			// flex-grow: 1;
			position: relative;
			font-weight: 700;
			font-size: 1em;
		}
	}

	.chats-list-item {
		transition: filter 250ms, background 250ms;
		cursor: pointer;
		background: rgba($green, 0);
		padding: 0.6250rem;
		padding-left: 1.2500rem;
		position: relative;

		.text {
			width: calc(#{$chatlistWidth} - (60px + 0.6250rem));
		}

		.number {
			position: absolute;
			right: 10px;
		}
		&.active {
			background: $main1;

			&::before {
				content: "";
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 2px;
				background-color: $green;
			}
		}

		&:hover {
			background: rgba($green, 0.05);
			z-index: 1;
		}
	}

	.List {
		display: flex;
		flex-direction: column;
	}
}

.members-list-popup {
	display: flex;
	flex-direction: column;
	padding: 0.6250rem;
	gap: 0.6250rem;
	// position: absolute;
	// right: 0;
	// top: 0;
	// transform: translateX(100%);
	pointer-events: none;
	opacity: 0;
	transition: opacity 500ms 500ms;
	background-color: $main3;
	border-radius: $borderRadius;

	.member {
		display: flex;
		align-items: center;
	}

	.profile {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		margin-right: 3px;
	}

	.name {
		font-weight: 600;
	}
}
.newchat-wrapper {
	width: 100%;
	padding: 0.6250rem;
}

.list-section-header {
	display: flex;
	cursor: pointer;
	justify-content: space-between;
	padding: 0.6250rem 0;

	h2 {
		font-size: 1.0000rem;
		font-weight: 600;
	}

	.open-newchat-button .Polaris-Icon svg {
		fill: rgb(200, 200, 200) !important;

		&:hover {
			fill: $textColor !important;
		}
	}

	.open-icon {
		margin-left: 0.6250rem;

		.Polaris-Icon {
			margin: 0;
			height: 12px;
			width: 12px;
			transform: rotate(180deg);
			transition: transform 250ms;
		}
	}

	&[aria-expanded="true"] {
		color: $green;
		.open-icon .Polaris-Icon {
			transform: rotate(270deg);
		}
	}
}

.chats-list-group-item {
	color: #919191;
	display: flex;
	padding: 0.2500rem 0.6250rem;
	padding-left: 1.2500rem;

	transition: filter 250ms, background 250ms, color 250ms;
	cursor: pointer;
	background: rgba($green, 0);
	position: relative;

	.number {
		position: absolute;
		right: 10px;
	}

	// .popup-members {
	// 	transition: opacity 250ms;
	// 	pointer-events: none;
	// 	opacity: 0;
	// 	position: absolute;
	// 	right: 0;
	// 	transform: translateX(110%);
	// 	background: $main2;
	// 	border-radius: $borderRadius;
	// 	padding: 0.6250rem;

	// 	.popup-members-item {
	// 		display: flex;
	// 		align-items: center;
	// 		gap: 0.6250rem;
	// 	}

	// 	&:hover {
	// 		pointer-events: all;
	// 		opacity: 1;
	// 	}
	// }

	.name {
		font-weight: 600;
		position: relative;
	}

	&.active {
		background: $main1;
		color: $textColor;

		&::before {
			content: "";
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: 2px;
			background-color: $green;
		}
	}

	&:hover {
		background: rgba($green, 0.05);
		color: $textColor;
		z-index: 1;

		// .popup-members {
		// 	pointer-events: all;
		// 	opacity: 1;
		// }
	}
}
.chat-list-wrapper {
	overflow-y: auto;
	height: calc(100% - #{$sidebarTopSectionHeight});
}

.chat-list-tabs {
	display: flex;
	padding: 0.6250rem;
	gap: 0.9375rem;
	font-weight: 600;
	color: $textColor;
	transition: color 250ms, opacity 250ms;

	.tab {
		position: relative;
		cursor: pointer;
		opacity: 0.75;
		transition: color 250ms, opacity 250ms;

		&::after {
			content: "";
			transition: opacity 250ms;
			width: 100%;
			height: 3px;
			position: absolute;
			background: $green;
			bottom: -2px;
			left: 0;
			right: 0;
			border-radius: 2px;
			opacity: 0;
		}

		&:hover {
			opacity: 1;
		}

		&.active {
			opacity: 1;

			&:after {
				opacity: 1;
			}
		}
	}
}

.chat-member-avatar {
	cursor: pointer;
}
.chat-member-info-wrapper {
	padding: 0.6250rem;

	.header {
		display: flex;
		gap: 0.6250rem;

		.name {
			font-weight: 600;
		}
	}
}

.chats-list-item,
.chats-list-group-item {
	position: relative;
	display: flex;
	flex-direction: column;

	.number {
		transition: right 250ms;
	}

	.leave-chat-button {
		position: absolute;
		right: 10px;
		pointer-events: none;
		opacity: 0;
		transition: opacity 250ms;
		display: flex;
		top: 0;
		height: 100%;

		.Polaris-Button.Polaris-Button--plain {
			color: transparent;
		}

		&:hover {
			opacity: 1;
		}
	}

	&:hover,
	&.leaving {
		.leave-chat-button {
			opacity: 0.4;
			pointer-events: all;

			.Polaris-Icon__Svg {
				fill: $green;
			}
		}
		.number {
			right: 45px;
		}
	}
}
