.leaderboard_small {
	.Polaris-Card__Section,
	.Polaris-LegacyCard__Section {
		padding-left: calc(1.25rem + 15px);
	}

	img.Polaris-EmptyState__Image,
	img[src*="/assets/images/empty_state"] {
		height: 100px;

		.Polaris-DisplayText {
			font-size: 1em;
			color: var(--textColor2);
		}
	}
}

table.leaderboard_small_table {
	border-collapse: collapse;
	width: 100%;

	.spacer {
		height: 10px;
	}
}

.leaderboard_small_header {
	margin-left: -15px;
	display: flex;
	gap: 1.25rem;
	.Polaris-Select {
		width: max-content;
	}

	.inheritActivator {
		margin-top: -5px;
		min-width: unset;
	}
}

tr.leaderboard_small_table_header {
	text-align: end;
	height: 50px;
	font-size: 13px;
	position: relative;

	&::after {
		content: "Position";
		position: absolute;
		left: -15px;
		height: 100%;
		display: flex;
		align-items: center;
		color: #9d9d9d;
	}

	th {
		color: #9d9d9d;
		font-weight: 400;

		.Polaris-Select {
			width: auto;
		}

		.Polaris-Select__Content {
			.Polaris-Select__SelectedOption {
				color: #9d9d9d;
			}
			.Polaris-Icon svg {
				fill: #9d9d9d;
			}
		}
	}

	th:last-of-type {
		padding-right: 1.25rem;
	}

	th:nth-child(1) {
		text-align: start;
	}
	th:nth-child(2) {
		text-align: start;
	}
	th:nth-child(3) {
		text-align: start;
	}
}

tr.leaderboard_small_row {
	text-align: end;
	border-radius: 64px;
	position: relative;
	font-size: 13px;
	background: linear-gradient(99.71deg, #171d21 -9.63%, #8c7968 155.37%);
	color: #ffffff;

	&::after {
		content: attr(data-position);
		position: absolute;
		left: -15px;
		height: 100%;
		display: flex;
		align-items: center;
	}

	// td:first-child,
	td:first-of-type {
		border-top-left-radius: 25px;
		border-bottom-left-radius: 25px;
	}
	// td:last-child,
	td:last-of-type {
		border-top-right-radius: 25px;
		border-bottom-right-radius: 25px;
		padding-right: 1.25rem;
	}

	td:nth-child(1) {
		text-align: start;
		width: 50px;
	}
	td:nth-child(2) {
		text-align: start;
	}
	td:nth-child(3) {
		text-align: start;
	}

	.leaderboard_small_position {
		position: absolute;
	}

	&.index-0 {
		background: linear-gradient(108deg, #20282e -7.66%, #dcbe6f 150.09%);
	}
	&.index-1 {
		background: linear-gradient(100.52deg, #20282e -17.86%, #d2d2cf 172.63%);
	}
	&.index-2 {
		background: linear-gradient(99.71deg, #20282e -9.63%, #ccb29c 155.37%);
	}

	// .leaderboard_small_row_title {
	// }

	.leaderboard_small_row_value {
		&.index-0 {
			width: 15%;
		}
		&.index-1 {
			width: 35%;
		}
	}
}
