$headerHeight: 90px;

.boardlogg {
	padding: 1.2500rem;
	height: 100%;
	min-height: 100%;

	.boardlogg_header {
		height: $headerHeight;

		.boardlogg_close {
			position: absolute;
			right: 0.6250rem;
			top: 0.6250rem;
			cursor: pointer;
		}
		.boardlogg_header__title {
			text-align: center;
			font-weight: 600;
			font-size: 1.2em;
			margin-bottom: 0.6250rem;
		}
		.boardlogg_header__count {
			padding: 0.3125rem;
			opacity: 0.75;
			transition: opacity 250ms;
			display: flex;
			align-items: center;

			&:hover {
				opacity: 1;
			}
		}
	}

	.boardlogg_scroll {
		display: flex;
		flex-direction: column;
		gap: 2.1875rem;
		height: calc(100% - #{$headerHeight});
		min-height: calc(100% - #{$headerHeight});
		overflow: auto;
		padding-top: 1.2500rem;
	}

	.boardlogg_wrapper {
		display: flex;
		flex-direction: row;
		gap: 0.6250rem;
		transition: opacity 250ms;

		.boardlogg_inner {
			width: calc(100% - 2.5000rem);
		}

		&.loading {
			opacity: 0.5;
		}

		.boardlogg_title {
			display: flex;
			justify-content: space-between;
			gap: 0.6250rem;
		}

		.boardlogg_text {
		}
	}

	.Polaris-Pagination {
		width: 100%;
		justify-content: center;
		padding: 1.2500rem 0 0.6250rem 0;
	}
}
