.scrolling-carousel > [data-arrow="left"],
.scrolling-carousel > [data-arrow="right"] {
	bottom: 0;
	// height: 42px;
	margin-bottom: auto;
	margin-top: auto;
	width: 88px;
	position: absolute;
	top: 0;
	opacity: 1;
	cursor: pointer;

	display: flex;
	height: 100%;
	align-items: center;
	z-index: 99;
}

.scrolling-carousel > [data-arrow="right"] {
	background: linear-gradient(270deg, var(--modals-bg), 70%, rgba(47, 52, 55, 0));
	right: 0;
}

.scrolling-carousel > [data-arrow="left"] {
	background: linear-gradient(90deg, var(--modals-bg), 70%, rgba(47, 52, 55, 0));
	left: 0;
}

.scrolling-carousel > [data-arrow="right"] > button {
	background: url("./../../public/assets/arrow.svg") no-repeat;
	border: none;
	cursor: pointer;
	height: 40px;
	outline: 0;
	position: absolute;
	right: -10px;
	transform: rotate(270deg) scale(0.7);
	-webkit-transform: rotate(270deg) scale(0.7);
	-moz-transform: rotate(270deg) scale(0.7);
	-ms-transform: rotate(270deg) scale(0.7);
	-o-transform: rotate(270deg) scale(0.7);
	width: 50px;
}
.scrolling-carousel > [data-arrow="left"] > button {
	background: url("./../../public/assets/arrow.svg") 50% no-repeat;
	border: none;
	cursor: pointer;
	height: 40px;
	left: -10px;
	outline: 0;
	transform: rotate(90deg) scale(0.7);
	-webkit-transform: rotate(90deg) scale(0.7);
	-moz-transform: rotate(90deg) scale(0.7);
	-ms-transform: rotate(90deg) scale(0.7);
	-o-transform: rotate(90deg) scale(0.7);
	width: 50px;
	position: absolute;
}

html body[theme="dark"] {
	.scrolling-carousel {
		[data-arrow="right"] > button,
		[data-arrow="left"] > button {
			background: url("./../../public/assets/arrow_white.svg") no-repeat;
		}
	}
}

.scrolling-carousel {
	.item {
		color: $textColor;
		display: block;
		width: 150px;
		text-align: center;
		font-weight: bold;
		cursor: pointer;
		transition: transform 250ms, scale 250ms;

		&:hover {
			scale: 1.05;
		}
	}
}
