// body[theme="dark"] .calendar_modal {
// 	$color: "red";
// }
.calendar_modal__header {
	display: flex;
	// box-shadow: 0px 7px 20px inset rgba(0, 0, 0, 0.2), 0px -7px 20px inset rgba(0, 0, 0, 0.2);
	justify-content: space-between;
	overflow: auto;
	font-size: 12px;
	padding: 0 0 1.2500rem 0.6250rem;

	.calendar_modal__header__buttons_wrapper {
		display: flex;
		gap: 0.6250rem;
		padding: 0.3125rem;

		.calendar_modal__header__button {
			transition: filter 250ms, background 250ms;

			.Polaris-Icon,
			img,
			svg {
				margin: 0;
				height: 15px;
				width: unset;
			}

			.Polaris-Button__Text {
				font-size: 12px;
				line-height: 14px;
			}

			svg[fill="none"],
			path[fill="none"],
			svg[fill="black"],
			path[fill="black"] {
				fill: var(--textColor);
			}

			&:hover {
				filter: brightness(1.25);
			}
		}
	}
}

.calendar_modal__header_button_border {
	border-right: 1px solid #80808061;
	margin: 14px 0px;

	&:last-child {
		border: none;
	}
}
.calendar_modal {
	// background-color: var(--main2);

	//TEMP
	// margin: 3.1250rem;

	.calendar_modal_fields_and_send_button_wrapper {
		display: flex;
		gap: 1.2500rem;
	}
	.calendar_modal__body {
		padding: 1.2500rem;
		display: flex;
		gap: 1.2500rem;
		// border-radius: 0.6250rem;
		flex-direction: column;
		font-size: 12px;
		line-height: 12px;
		// box-shadow: 0px -13px 10px rgba(0, 0, 0, 0.25);
		position: relative;
		border-top: 1px solid #464646;

		input,
		textarea,
		select,
		.Polaris-Choice__Label {
			font-size: 12px;
			line-height: 12px;
		}

		// background: var(--main2);

		.DraftEditor-editorContainer {
			min-height: 150px;
		}

		.toolbar {
			border-top-left-radius: $borderRadius;
			border-top-right-radius: $borderRadius;
		}

		.RichEditor-root {
			background-color: var(--main3);
			border: none;
			border-radius: 6px;

			.Polaris-TextField {
				background: transparent !important;
			}

			.Polaris-TextField__Backdrop {
				border-bottom-left-radius: $borderRadius;
				border-bottom-right-radius: $borderRadius;
			}
		}

		.RichEditor-controls {
			.Polaris-Button {
				svg {
					path,
					g {
						fill: var(--textColor);
					}
					fill: var(--textColor);
					opacity: 1;
				}
			}
			.Polaris-Button:not(.Polaris-Button--pressed) {
				svg {
					path,
					g {
						fill: var(--textColor);
					}
					fill: var(--textColor);
					opacity: 0.5;
				}
			}
		}

		.calendar_modal__body__send_button {
			background-color: var(--main3);
			cursor: pointer;
			border-radius: 5px;
			display: flex;
			flex-direction: column;
			gap: 0.6250rem;
			align-items: center;
			font-size: 0.8125rem;
			width: 100px;
			height: 100px;
			justify-content: center;

			svg {
				height: 35px;
				width: 35px;
			}
		}
		.calendar_modal__body__content {
			display: flex;
			flex-direction: column;
			// gap: 1.2500rem;
			flex: 1;

			.Polaris-TextField {
				border-radius: 0 !important;
				padding-left: 0 !important;

				input {
					min-height: unset;
					padding: 0;
					border-radius: 0;
				}
			}

			.calendar_modal__body__field__label {
				width: 75px;
				text-align: end;
				display: flex;
				justify-content: flex-end;
				align-items: flex-end;
			}

			.calendar_modal__body__field {
				display: flex;
				gap: 1.2500rem;
				align-items: flex-end;
				flex: 1;
				margin: 0.3125rem 0;
				transition: margin 250ms;

				&.collapsed {
					margin: 0;
				}

				&:not(.collapsed) {
					min-height: 32px;
				}

				&.disabled {
					pointer-events: none;

					.attendees_list {
						border-bottom: none;
					}
				}

				.Polaris-Collapsible.Polaris-Collapsible--open.Polaris-Collapsible--fullyOpen {
					z-index: 9999;
					position: relative;
				}

				& > *:not(.calendar_modal__body__field__label) {
					flex: 1;
				}

				.sel {
					&,
					& > div {
						width: max-content;
					}
				}

				.resource_picker_btn .Polaris-Button {
					width: max-content;
				}

				.Polaris-TextField {
					background: transparent;
					outline-width: 0;

					input {
						background: none;
					}

					.Polaris-TextField__Input {
						border: none;
					}
				}
				&:not(.disabled) .Polaris-TextField {
					border-bottom: 0.5px solid rgba(217, 217, 217, 0.5);
				}

				.Polaris-TextField__Backdrop {
					box-shadow: none !important;
					background: transparent !important;

					&::after {
						border: none;
					}
				}

				// input {
				// 	border: none;
				// 	font-size: 0.9375rem;
				// 	background: none;
				// 	outline: none;
				// 	flex: 1;

				// 	&:not(.pain) {
				// 		border-bottom: 1px solid var(--textColor);
				// 	}
				// }
			}
		}
	}
}
.attendees_list {
	border-bottom: 0.5px solid rgba(217, 217, 217, 0.5);
	align-items: flex-end;

	.Polaris-TextField {
		min-width: 200px;
		border: none !important;
	}

	.attendee {
		padding: 0px 10px;
		padding-right: 6px;
		font-size: 11px;
		background: $main3;
		border-radius: 10px;
		height: max-content;
		color: #ffffff;
		display: flex;
		align-items: center;
		gap: 4px;
		margin-bottom: 1px;

		.remove_btn {
			cursor: pointer;

			.Polaris-Icon {
				padding: 4px;
			}
		}

		.Polaris-Badge {
			border-width: thin;
			border-color: #ffffff;
			font-size: 0.7188rem;
		}

		&.accepted {
			background: rgba($green, 0.25);
			color: $green;

			.Polaris-Icon {
				fill: $green;
			}
		}

		&.declined {
			background: rgba($orange, 0.25);
			color: $orange;

			.Polaris-Icon {
				fill: $orange;
			}
		}

		&.tentative,
		&.tentativelyAccepted {
			background: rgba($lightblue, 0.25);
			color: $lightblue;

			.Polaris-Icon {
				fill: $lightblue;
			}
		}

		&.invalid {
			background: rgba($red, 0.25);
			color: $red;

			.Polaris-Icon {
				fill: $red;
			}
		}
	}
}

.calendar_modal__body__answer_buttons_wrapper {
	display: flex;
	gap: 1.2500rem;

	button {
		border: none;
		background: var(--textColor);
		color: var(--main1);
		border-radius: 5px;
		display: flex;
		align-items: center;
		gap: 0.6250rem;
		padding: 7px 15px;
		cursor: pointer;

		.Polaris-Icon {
			width: 0.9375rem;
			height: 0.9375rem;
		}

		&.accept {
			transition: opacity 250ms, color 250ms;
			opacity: 1;
			background-color: $green;
			color: #ffffff;

			&.inactive {
				opacity: 0.7;
			}

			.Polaris-Icon {
				fill: #ffffff;
			}
		}
		&.pre {
			transition: opacity 250ms, color 250ms;
			opacity: 1;
			background-color: $lightblue;
			color: #ffffff;

			&.inactive {
				opacity: 0.7;
			}

			.Polaris-Icon {
				fill: #ffffff;
			}
		}
		&.decline {
			transition: opacity 250ms, color 250ms;
			opacity: 1;
			background-color: $red;
			color: #ffffff;

			&.inactive {
				opacity: 0.7;
			}

			.Polaris-Icon {
				fill: #ffffff;
			}
		}
		&.new_time {
			transition: opacity 250ms, color 250ms;
			opacity: 1;
			background-color: rgba(150, 150, 150, 1);
			color: #ffffff;

			&.inactive {
				opacity: 0.7;
			}

			.Polaris-icon {
				fill: #ffffff;
			}
		}

		.disclosure .Polaris-Icon {
			fill: #ffffff;
		}
	}
}

.calendar_modal__reminder_button_wrapper {
	display: flex;
	align-items: flex-end;
}
.calendar_modal__reminder_button {
	// margin: 14px 0px;
	align-items: flex-end;
	// padding: 1.2500rem;
	cursor: pointer;

	.Polaris-Icon {
		margin: 0;
		height: 13px;

		svg {
			max-height: unset;
			height: 20px;
			width: 20px;
			top: -3px;
		}
	}
}

body[theme="dark"] {
	::-webkit-calendar-picker-indicator {
		filter: invert(1);
		// height: 16px;
	}
}

.list {
	position: absolute;
	min-height: 200px;
	max-height: 300px;
	overflow: auto;
	background: var(--main3);
	border-radius: 5px;
	z-index: 9999;
	min-width: 100%;
	box-shadow: 0px 3px 5px 1px #00000030;

	.item {
		display: flex;
		flex-direction: row;
		margin: 0.3125rem 0;
		transition: background 250ms;
		padding: 3px 10px;
		gap: 0.6250rem;
		cursor: pointer;
		align-items: center;

		&.selected,
		&:hover {
			background: rgba(150, 150, 150, 0.2);
		}
	}
}
.calendar_modal__email__content {
	//border-top: 0.5px solid rgba(217, 217, 217, 0.5);
}

.calendar_modal__body .calendar_modal__body__content .calendar_modal__body__field .calendar_modal__date_picker {
	align-items: center;
	position: relative;
	top: 4px;

	&.end .time_checkbox {
		display: none;
	}

	.Polaris-TextField {
		border-bottom: 1px solid transparent !important;
		padding-left: 0 !important;
	}

	input {
		min-height: 20px;
		background: var(--modals-input-bg) !important;
		border-radius: 9px !important;
		padding: 0px 0.6250rem;
		// color: #000;
	}

	.no_date_suffix {
		gap: 1.2500rem;

		.time_input input {
			width: 60px;
			text-align: center;
		}

		.Polaris-TextField__Suffix {
			padding: 0;
		}
		& > div {
			padding: 0;
		}
	}
	.Polaris-Choice {
		align-items: center;
		height: 13px;

		.Polaris-Choice__Control {
			margin: 0;
			margin-right: 0.5000rem;

			.Polaris-Checkbox__Backdrop {
				background: var(--textColor);
				border-color: var(--textColor);
				box-shadow: 1px 1px 2px #0000002e;
			}
		}

		.Polaris-Choice__Label {
			line-height: 20px;
		}
	}
}

.public-DraftEditorPlaceholder-inner {
	position: absolute;
	color: rgb(150, 150, 150);
}

.group_datetime {
	display: flex;
	background-color: var(--main3);
	border-radius: 9px;
	width: 70px;
	padding: 0 5px;

	#time_input_hours {
		text-align: end;
	}

	#time_input_minutes {
		text-align: start;
	}

	input {
		padding: 0 2px !important;
	}

	.Polaris-TextField__Segment {
		display: none;
	}

	.seperator {
		display: flex;
		align-items: center;
		margin-top: -4px;
		font-size: 12px;
		line-height: 12px;
	}
}

.time-icon {
	// padding-left: 7px;
	display: flex;
	align-items: center;

	.Polaris-Icon {
		height: 0.7500rem;
		width: 0.7500rem;
	}
}

.show_optional_attendees {
	.Polaris-Button {
		// min-height: 100%;

		.Polaris-Button__Content {
			font-size: 12px;
			line-height: 12px;
		}
	}
}
