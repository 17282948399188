.outer-container {
	position: relative;
}
.inner-container {
	position: absolute;
	background-color: #2f3437;
	z-index: 99999;
	min-width: max-content;
	max-height: calc(100vh - 65px);
	max-width: 100vw;
	overflow: auto;
	animation: fadeIn 300ms;
	-webkit-animation: fadeIn 300ms;
	box-shadow: 0px 3px 10px 2px #0000000f;
}

body[theme="dark"] {
	.inner-container {
		svg {
			fill: #788998;
			color: #fff;
		}
		color: rgb(240, 240, 240);
	}
}
.activator {
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
