@import "./variables.scss";
body[theme="dark"] {
	.Polaris-Badge {
		border-color: #000;
	}

	.Polaris-Banner--statusSuccess {
		background-color: #63755e;
	}
	.Polaris-Card__Section,
	.Polaris-LegacyCard__Section + .Polaris-Card__Section,
	.Polaris-LegacyCard__Section {
		border-top: 0.0625rem solid #222;
	}
	background-color: $mainBackgroundColor;
	.Polaris-ProgressBar {
		&.company {
			.Polaris-ProgressBar__Indicator {
				background-color: $blue;
			}
		}
		&.people {
			.Polaris-ProgressBar__Indicator {
				background-color: $blue;
			}
		}
	}
	.Polaris-PageActions {
		border-top: 0.0625rem solid #202020;
	}
	.Polaris-Card__Section,
	.Polaris-LegacyCard__Section {
		&.company {
			[class*="Polaris-Text--heading"] {
				font-size: 25px;
				margin-bottom: 12px;
			}
		}
		&.people {
			.Polaris-ProgressBar__Indicator {
				background-color: $blue;
			}
			[class*="Polaris-Text--heading"] {
				font-size: 25px;
				margin-bottom: 12px;
			}
		}
	}

	.dashboard-widget {
		background-color: $secondaryBackgroundColor;
	}
	.Polaris-DataTable__Cell--total {
		background-color: #000;
	}
	.Polaris-Modal-Section:not(:last-of-type) {
		border-color: $borderColor !important;
	}
	.Polaris-Modal-Header {
		border-color: $borderColor !important;
	}
	.Polaris-Modal-Footer {
		border-color: $borderColor !important;
	}
	.darkBadge {
		margin-top: 15px;
		.Polaris-Badge {
			background-color: #3f3f3f !important;
			color: white !important;
		}
	}
	// .Polaris-Tabs__Panel .Polaris-LegacyCard {
	// 	background-color: $bgHoverColor !important;
	// 	border-radius: 10px;
	// 	-webkit-border-radius: 10px;
	// 	-moz-border-radius: 10px;
	// 	-ms-border-radius: 10px;
	// 	-o-border-radius: 10px;
	// }

	.Polaris-Popover__Wrapper {
		box-shadow: 0px 3px 10px #1d1b1b4f;
	}

	.Polaris-LegacyCard,
	.Polaris-Popover__Wrapper,
	.Polaris-Sheet,
	.Polaris-Modal-Dialog__Modal {
		color: $textColor;

		/*
		input {
			background: $bgHoverColor;
			// background: transparent;
		}
		*/

		input,
		.Polaris-ActionList__Item:not(.Polaris-ActionList--destructive):not(.Polaris-ActionList--disabled) {
			color: $textColor;
			.Polaris-ActionList__Content {
				color: $textColor;
			}
			.Polaris-ActionList__Image svg {
				fill: $svgHoverFill;
			}
		}

		.Polaris-Banner--withinContentContainer {
			background-color: $bgHoverColor !important;
		}

		.collapsed-group,
		.Polaris-Banner--withinPage.Polaris-Banner--statusInfo {
			background-color: $bgHoverColor;
		}
	}

	.Polaris-DataTable__Heading,
	.Polaris-Select,
	.Polaris-Tabs__Title,
	[class*="Polaris-Text--heading"] {
		color: $textColor;
	}

	.greyRoundedCard .subHeader {
		color: $textColorDark;
	}

	.Polaris-Stack > .Polaris-Stack__Item {
		fill: $textColor;
	}

	.board-spacer {
		background: $main1;
	}

	.collapsed-group,
	.Polaris-Banner--withinPage.Polaris-Banner--statusInfo {
		background-color: $main3;
		color: $textColor;
	}

	.board-status,
	.Polaris-ResourceList__HeaderWrapper,
	.Polaris-ResourceList__HeaderOuterWrapper {
		background-color: transparent;
	}

	.Polaris-Card__Header + .Polaris-Card__Section--subdued {
		background-color: #444;
		border-color: #333;
	}
	.Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term {
		border-color: #222;
	}
	.Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description {
		border-color: #222;
	}

	.stuck {
		.column-header,
		.board-spacer {
			background-color: $bgHoverColor;
		}
	}

	.datatable.haveselection .Polaris-ResourceList__HeaderOuterWrapper,
	.datatable.haveselection .Polaris-ResourceList__HeaderWrapper .Polaris-Filters,
	.datatable.haveselection .Polaris-ResourceList__HeaderWrapper .Polaris-Select:not(.Polaris-Select--newDesignLanguage) .Polaris-Select__Backdrop,
	.Polaris-Select:not(.Polaris-Select--newDesignLanguage) .Polaris-Select__Backdrop::after,
	.datatable.haveselection .Polaris-ResourceList__HeaderWrapper,
	.datatable.haveselection .Polaris-ResourceList__HeaderWrapper .datatable tbody tr.selected td,
	.datatable.haveselection .Polaris-ResourceList__HeaderWrapper .datatable tbody tr.selected th {
		background: $secondaryBackgroundColor;
		background-color: $secondaryBackgroundColor;
		color: $textColor;
	}
	// .Polaris-Scrollable.Polaris-Scrollable--vertical.Polaris-Scrollable--verticalHasScrolling > div:not(.messages) {
	// 	background: $secondaryBackgroundColor !important;
	// 	background-color: $secondaryBackgroundColor !important;
	// }

	.Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton__CheckableButton--selected,
	.Polaris-Select:not(.Polaris-Select--newDesignLanguage) .Polaris-Select__Backdrop {
		background: transparent;
		color: $textColor;
		border-color: $main3;
	}

	.Polaris-DataTable__Footer {
		background: $secondaryBackgroundColor;
		color: $textColorSubdued;
	}

	.Polaris-DataTable__TableRow:hover .Polaris-DataTable__Cell {
		background: $bgHoverColor;
	}

	.Polaris-DataTable__Pip {
		background: #212b36;
	}
	.Polaris-DataTable__Pip--visible {
		background: #919eab;
	}

	.Polaris-ResourceList__LoadingOverlay {
		background-color: transparent;

		.Polaris-ResourceList__SpinnerContainer {
			padding: 0;
			align-items: center;
		}
	}

	.Polaris-TextStyle--variationSubdued {
		color: $textColorSubdued;
	}

	.datatable tbody th,
	.datatable tbody td,
	.datatable .Polaris-DataTable__Cell--header,
	.board-item *,
	.Polaris-Navigation,
	.Polaris-Navigation__Section--withSeparator,
	.board-column {
		border-color: $borderColor;
	}

	.Polaris-Tabs__Tab.Polaris-Tabs__Tab--selected,
	.Polaris-Tabs__Tab.Polaris-Tabs__Tab--active {
		.Polaris-Tabs__Title {
			color: $textColor;
		}
	}

	.Polaris-Breadcrumbs__Breadcrumb:hover,
	.Polaris-Breadcrumbs__Breadcrumb:active {
		color: $textColorSubdued;

		.Polaris-Breadcrumbs__Icon .Polaris-Icon svg {
			fill: $textColorSubdued;
		}
	}

	.column-header,
	.fixed-column {
		.Polaris-Icon--colorInkLighter svg {
			fill: $textColor;
		}

		&.dragging {
			background-color: $bgFocusColor;
		}
	}
	.drag-over {
		background-color: $bgHoverColor !important;
	}

	.column-header.focused {
		background-color: $bgFocusColor;
		border-color: transparent;

		* {
			color: $textColor;
			background-color: transparent;
		}
	}

	.overflow-ellipsis.board-editable {
		background-color: $main2;
	}

	.column-header,
	.board-main-header {
		.overflow-ellipsis.board-editable {
			background-color: transparent !important;
		}
	}

	.Polaris-Popover__Section + .Polaris-Popover__Section {
		border-color: $borderColor;
	}

	.Polaris-DatePicker__Weekday {
		color: $textColorSubdued;
	}

	.Polaris-ResourceList__HeaderWrapper--overlay {
		background-color: transparent;
	}

	.Polaris-TopBar-SearchField__Input:focus {
		background-color: transparent !important;

		& ~ .Polaris-TopBar-SearchField__Backdrop {
			background-color: $bgFocusColor;
		}
	}
	.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input,
	.Polaris-TopBar-SearchField__Input:focus {
		color: $textColor;
	}

	.Polaris-Label label.Polaris-Label__Text,
	label {
		color: $textColorSubdued;
	}

	.Polaris-ResourceItem:hover {
		background: $bgHoverColor;
		background-image: none !important;
	}

	.Polaris-Pagination__Button svg {
		fill: $textColorSubdued;
	}
	.Polaris-Pagination--plain .Polaris-Pagination__Button:disabled svg {
		fill: $textColorDisabled;
	}

	.Polaris-Pagination--plain .Polaris-Pagination__Button:hover:not(:disabled) svg,
	.Polaris-Pagination--plain .Polaris-Pagination__Button:active:not(:disabled) svg {
		fill: $textColor;
	}

	.timelog-row {
		&:hover {
			background-color: $bgHoverColor;
		}
	}

	.bulkToolbar .bulkbar {
		background-color: $bgHoverColor;

		.bulkActionButton,
		.bulkCancel {
			color: $textColor;

			&:hover {
				background-color: $bgFocusColor;
			}
		}
	}

	.BoardDescription {
		& {
			transition: border-color 250ms;
			-webkit-transition: border-color 250ms;
			-moz-transition: border-color 250ms;
			-ms-transition: border-color 250ms;
			-o-transition: border-color 250ms;
		}

		textarea.Polaris-TextField__Input,
		.Polaris-TextField,
		.Polaris-TextField--multiline {
			background: transparent !important;
			border-color: transparent;
			font-size: 0.9em;
			transition: background 250ms;
			-webkit-transition: background 250ms;
			-moz-transition: background 250ms;
			-ms-transition: background 250ms;
			-o-transition: background 250ms;
		}

		.Polaris-TextField__Backdrop {
			border-color: transparent;
			box-shadow: none;
		}

		.Polaris-Labelled__HelpText {
			font-size: 0.7em;
			color: #c4ae11;
			margin-left: "25px";
		}

		&.focused {
			textarea.Polaris-TextField__Input,
			.Polaris-TextField,
			.Polaris-TextField--multiline {
				background: #414548 !important;
				border-color: transparent;
			}
		}
	}

	.BoardSalesDashboardLists {
		.Polaris-Card__Section.Polaris-Card__Section--subdued {
			background-color: transparent;
			border-color: transparent;
			border-bottom: 0.0625rem solid var(--p-border-subdued, #dfe3e8);

			// > & {
			margin-top: 1.25rem;
			// }
		}

		.Polaris-Card__Section.Polaris-Card__Section--subdued ~ .Polaris-Card__Section.Polaris-Card__Section--subdued {
			margin-top: 0;
		}

		.Polaris-Card__Header {
			padding: 0;
		}
	}

	.Polaris-Page-Header__ActionMenuWrapper {
		.Polaris-ActionMenu-MenuAction {
			color: #637381;

			svg {
				fill: #637381;
			}

			&:hover {
				color: $textColorSubdued;
			}
		}
	}

	.Polaris-ActionMenu-MenuAction:hover svg {
		fill: $textColorSubdued;
	}

	.switch-line {
		background: #757b86;
	}

	.switch-dark {
		fill: #ffffff;
		color: #ffffff;
	}
}

//New styles specificly for just dark theme, so light theme can use their default Polaris style.
body[theme="dark"] {
	[class="Polaris-Badge"] {
		background: $main3;
		box-shadow: $boxShadow;
		border-color: transparent;
		color: $textColor;
	}
}
