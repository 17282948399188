@import "../../../node_modules/draft-js-image-plugin/lib/plugin.css";
@import "../../../node_modules/draft-js-focus-plugin/lib/plugin.css";

.RichEditor-root {
	.toolbar {
		padding: 5px;
		background: var(--modals-input-bg);
		border-bottom: 1px solid #ffffff26;

		.Polaris-ButtonGroup--segmented {
			box-shadow: none;
		}

		.Polaris-Button:not(.Polaris-Button--primary):not(.Polaris-Button--plain):not(.Polaris-Button--destructive) {
			background: transparent !important;
			border-radius: 0;
		}
	}
	&.fullheight {
		padding-top: 40px;
		height: 100%;
		.toolbar {
			z-index: 21;
			position: absolute;
			height: 40px;
			white-space: nowrap;
			overflow: auto;
			width: 100%;
			top: 0;
			left: 0;
			right: 0;
			background-color: #fafbfc;
		}
	}
	background-color: #fafbfc;
	border: 1px solid #d3dbe2;
	border-bottom: 0;

	.Polaris-TextField__Backdrop {
		border-left: 0;
		border-right: 0;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-top: 1px solid #d3dbe2;
	}
}
.rte-html-view textarea {
	background-color: #000;
	color: #fff;
}
.rte {
	color: #637381;
	font-size: 1.0000rem;
	font-weight: 400;
	line-height: 1.5000rem;
	text-transform: initial;
	letter-spacing: initial;
	position: relative;
	a,
	a:link,
	a:visited,
	a:active {
		color: #1a0dab;
		text-decoration: underline;
	}
	h1 {
		display: block;
		font-size: 2em;
		margin-block-start: 0.67em;
		margin-block-end: 0.67em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}
	h2 {
		display: block;
		font-size: 1.5em;
		margin-block-start: 0.83em;
		margin-block-end: 0.83em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}
	h3 {
		display: block;
		font-size: 1.17em;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}
	h4 {
		display: block;
		margin-block-start: 1.33em;
		margin-block-end: 1.33em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}
	h5 {
		display: block;
		font-size: 0.83em;
		margin-block-start: 1.67em;
		margin-block-end: 1.67em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}
	h6 {
		display: block;
		font-size: 0.67em;
		margin-block-start: 2.33em;
		margin-block-end: 2.33em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}
}
.DraftEditor-root {
	font-size: 1.0000rem;
	font-weight: 400;
	line-height: 1.5000rem;
	text-transform: initial;
	letter-spacing: initial;
	position: relative;
	z-index: 20;
	display: block;
	flex: 1 1 0%;
	width: 100%;
	min-width: 0;
	min-height: 2.2500rem;
	margin: 0;
	padding: 0.3125rem 0.7500rem;
	background: none;
	border: 0.0625rem solid transparent;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	appearance: none;
	.DraftEditor-editorContainer {
		a,
		a:link,
		a:visited,
		a:active {
			color: #1a0dab;
			text-decoration: underline;
		}
		h1 {
			display: block;
			font-size: 2em;
			margin-block-start: 0.67em;
			margin-block-end: 0.67em;
			margin-inline-start: 0px;
			margin-inline-end: 0px;
			font-weight: bold;
		}
		h2 {
			display: block;
			font-size: 1.5em;
			margin-block-start: 0.83em;
			margin-block-end: 0.83em;
			margin-inline-start: 0px;
			margin-inline-end: 0px;
			font-weight: bold;
		}
		h3 {
			display: block;
			font-size: 1.17em;
			margin-block-start: 1em;
			margin-block-end: 1em;
			margin-inline-start: 0px;
			margin-inline-end: 0px;
			font-weight: bold;
		}
		h4 {
			display: block;
			margin-block-start: 1.33em;
			margin-block-end: 1.33em;
			margin-inline-start: 0px;
			margin-inline-end: 0px;
			font-weight: bold;
		}
		h5 {
			display: block;
			font-size: 0.83em;
			margin-block-start: 1.67em;
			margin-block-end: 1.67em;
			margin-inline-start: 0px;
			margin-inline-end: 0px;
			font-weight: bold;
		}
		h6 {
			display: block;
			font-size: 0.67em;
			margin-block-start: 2.33em;
			margin-block-end: 2.33em;
			margin-inline-start: 0px;
			margin-inline-end: 0px;
			font-weight: bold;
		}
	}
}
.Polaris-TextField--focus > .DraftEditor-root,
.DraftEditor-root:focus {
	outline: none;
	border: border(transparent);
}

.Polaris-TextField--focus > .Polaris-TextField__Backdrop {
	border: 0.0625rem solid #5c6ac4;
	box-shadow: inset 0 0 0 0 transparent, 0 0 0 1px #5c6ac4;
}
