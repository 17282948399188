@import "@shopify/polaris/build/esm/styles.css";
// @import "react-quill/dist/quill.snow.css";
@import "./quill.sellfinity.scss";

@import "./board.scss";
// @import "react-big-calendar/lib/sass/styles.scss";
// @import "react-big-calendar/lib/addons/dragAndDrop/styles.scss"; // if using DnD

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

@import "./animations.scss";
@import "./main.scss";
@import "./boardSalesDashBoard.scss";
@import "./BoardTemplateCarousel.scss";
@import "./Announcements.scss";
@import "./modals.scss";
@import "./chat.scss";
@import "./fortnox.scss";
@import "./contact.scss";
@import "./notifications.scss";
@import "./tooltip.scss";
@import "./IconsOverview.scss";
@import "./userinviter.scss";
@import "./introjsTooltip.scss";
@import "./documents.scss";
@import "./new_datepicker.scss";
@import "./navigation.scss";
@import "./emailModalContent.scss";
html,
body,
button {
	font-family: Roboto, sans-serif, -apple-system !important;
}

a {
	transition: filter 0.15s;

	&:hover {
		filter: brightness(1.25);
	}
}

.read-more-btn {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	margin: 0;
	padding: 10px 0;
	cursor: pointer;

	background-image: linear-gradient(to bottom, transparent, $main1);
}

.Polaris-Tabs__Tab.Polaris-Tabs__Tab--selected,
.Polaris-Tabs__Tab.Polaris-Tabs__Tab--active {
	.Polaris-Tabs__Title {
		border-bottom-color: $blue;
	}
}

.ReactModal__Overlay,
.ReactModal__Content {
	background-color: transparent !important;
	border-color: transparent !important;
}

// .Polaris-Modal-Dialog__Container:not(.custom) {
// 	z-index: 99999 !important;
// }
// .Polaris-Modal-Dialog__Container.front {
// 	z-index: 99999999 !important;
// }

@keyframes ellipsis {
	to {
		width: 20px;
	}
}

@-webkit-keyframes ellipsis {
	to {
		width: 20px;
	}
}

.Polaris-TextField__Suffix {
	padding: 0.5em;
}

@media (min-width: 769px) {
	.Polaris-Sheet__Container.fortnox {
		max-width: 56.25rem !important;
		.Polaris-Sheet {
			width: 56.25rem;
		}
	}
}

.Button_Group {
	display: flex;
	height: 100%;
	gap: 2px;
	button {
		transition: all 0.25s;
		height: 100%;
		color: var(--textColor);
		background-color: var(--modals-input-bg);
		flex: 1;
		border: none;
		&:not(:disabled) {
			&:hover {
				filter: brightness(1.1);
			}
			cursor: pointer;
		}
		&:disabled {
			background-color: var(--accent);
			color: #0f0f0f;
		}
	}
}

.plain {
	background-color: transparent !important;
	border: none !important;
	box-shadow: none !important;
	cursor: pointer;

	&:hover {
		background-color: transparent !important;
	}
}
img[src*="/empty_state/"] {
	max-height: 50vh;
}
