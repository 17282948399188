.react-flow__handle {
	width: 8px;
	height: 8px;
	background-color: #bbb;
}

.react-flow__handle-top {
	top: -4px;
}

.react-flow__handle-bottom {
	bottom: -4px;
}

.react-flow__handle-left {
	left: -4px;
}

.react-flow__handle-right {
	right: -4px;
}

.react-flow__node-main {
	background: var(--cardbg);
	border: 1px solid var(--borderColor);
	border-radius: 3px;
	color: var(--textColor);
	font-size: 12px;
	text-align: center;
	width: 150px;
	heigt: 50px;
	cursor: pointer;
	.inner_wrapper {
		position: relative;
		padding: 10px;
		heigt: 100%;
		.add-btn {
			display: none;
			height: 25px;
			width: 25px;
			padding-top: 2px;
			border-radius: 25px;
			position: absolute;
			background-color: var(--accent);
			left: 62px;
			bottom: -15px;
			border: 1px solid var(--borderColor);
			cursor: pointer;
		}
		&:hover,
		&.main.leaf {
			.add-btn {
				display: initial;
			}
		}
	}
}
