.board_view_callist {
	color: var(--textColor);
	height: 100%;

	.board-status {
		padding: 0 0.625rem;
		line-height: 30px;
		height: 30px;
		font-size: 12px;
		min-width: 100px;
	}

	h2:not(.board-empty_screen__title) {
		font-weight: 400;
		font-size: 19px;
		line-height: 22px;
		margin-bottom: 1.25rem;
	}

	.board_view_callist_grid {
		display: grid;
		gap: 32px;
		margin-left: 32px;
		margin-right: 32px;
		grid-template-areas: "graph actions" "list details";
		grid-template-columns: 1.2fr 1fr;
		grid-template-rows: min-content;

		margin-top: calc(2.5rem - 15px);

		// .left {
		// 	flex: 1.75;
		// 	display: flex;
		// 	flex-direction: column;
		// 	gap: 32px;
		// }
		// .right {
		// 	flex: 1;
		// }

		.board_view_callist_graph {
			grid-area: graph;

			.Polaris-LegacyCard {
				height: 100%;

				.Polaris-Card__Section,
				.Polaris-LegacyCard__Section {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			.board_view_callist_graph_inner {
				display: flex;
				align-items: center;
				justify-content: center;
				// max-width: 700px;
				width: 100%;
				gap: 3.75rem;
			}

			table {
				height: min-content;

				tbody {
					tr {
						cursor: pointer;

						&:hover {
							font-weight: bold;
						}

						&:nth-child(odd) {
							background-color: var(--main1);
						}

						td {
							min-width: 50px;
							text-align: center;
							padding: 0.3125rem 0.625rem;

							&:first-of-type {
								text-align: start;
								min-width: 150px;
								display: flex;
								gap: 0.625rem;
							}
						}
					}
				}
			}
		}

		.board_view_callist_card {
			height: 100%;
			position: relative;
			overflow: auto;

			&.actions {
				grid-area: actions;

				.Polaris-LegacyCard .Polaris-TextField__Backdrop,
				.Polaris-LegacyCard .Polaris-Select .Polaris-Select__Content {
					border-radius: 7px;
				}

				.Polaris-LegacyCard {
					&,
					.Polaris-Card__Section,
					.Polaris-LegacyCard__Section {
						height: 100%;
					}
				}

				.Polaris-LegacyCard .Polaris-Card__Section,
				.Polaris-LegacyCard__Sectionh2 {
					margin: 0;
				}

				textarea {
					min-height: 76px;
					max-height: 76px;
				}
			}

			&.details {
				grid-area: details;
				overflow: scroll;

				.Polaris-LegacyCard {
					.Polaris-LegacyCard__Section {
						height: 100%;
					}
				}

				.Polaris-Scrollable {
					height: 100%;
				}

				.Banner {
					.timeline__row__date,
					.timeline__row__content {
						padding: 0;
					}
					.timeline__row__content {
						padding-left: 0.9375rem;
					}
				}
			}

			.timeline__rows__input {
				.Polaris-TextField {
					border: 1px solid transparent;

					&.Polaris-TextField--error {
						border-color: $red;
					}
				}
			}

			.field {
				display: flex;
				align-items: center;
				gap: 0.625rem;

				.Polaris-Icon {
					margin: 0;
				}

				.attachment_files {
					padding-left: 0;
				}
			}

			label {
				color: var(--textColor);
				font-weight: 400;
			}

			& > .Polaris-LegacyCard {
				height: 100%;

				& > .Polaris-Card__Section,
				.Polaris-LegacyCard__Section {
					height: 100%;
					overflow: auto;
				}
			}

			.info-view__row__value {
				color: var(--textColor);
				opacity: 0.5;
			}

			.card_detail {
				display: flex;
				gap: 0.625rem;
				flex-direction: column;

				&.status {
					margin-block: 0.625rem;

					& > div {
						display: flex;
						gap: 0.625rem;
						align-items: center;

						.overflow-ellipsis.board-editable {
							width: max-content !important;
						}
					}
				}
			}

			.pagination_wrapper {
				width: 100%;
				display: flex;
				justify-content: flex-start;
				margin-top: 0.625rem;
				gap: 0.625rem;
				align-items: center;

				.label {
					font-weight: 400;
					font-size: 16px;
					line-height: 19px;
					margin-right: 0.625rem;
				}
			}

			.timeline__rows__input {
				margin-block: 0.625rem;
				// textarea {
				// 	min-height: 150px;
				// }
			}

			.Polaris-ProgressBar--sizeSmall {
				position: absolute;
				animation: 0;
				width: 100%;
				top: 0;
				z-index: 1;
				left: 0;
				border-radius: 6px;
			}
		}
	}
}

.board_view_callist_list {
	grid-area: list;
	height: 100%;

	.Polaris-LegacyCard {
		height: 100%;

		.Polaris-LegacyCard__Section {
			height: 100%;
			// overflow: auto;
		}
	}

	table {
		width: 100%;

		thead {
			th {
				user-select: none;
				padding: 0.625rem;
				background-color: var(--main1);

				&:first-child {
					border-top-left-radius: 7px;
					border-bottom-left-radius: 7px;
				}

				&:last-child {
					border-top-right-radius: 7px;
					border-bottom-right-radius: 7px;
				}

				& > span {
					display: flex;

					.Polaris-Icon {
						margin: 0;
					}
				}

				&.active {
					.Polaris-Icon svg {
						fill: var(--accent);
					}
				}

				&:nth-of-type(1) {
					width: 60%;
					text-align: start;
				}
				&:nth-of-type(2) {
					width: 20%;
				}
				&:nth-of-type(3) {
					width: 20%;
				}
			}
		}

		tbody {
			tr {
				transition: background-color 250ms;

				&:nth-child(even) {
					td {
						background-color: var(--main1);
					}
				}

				td {
					padding: 0.3125rem;

					&:first-child {
						border-top-left-radius: 7px;
						border-bottom-left-radius: 7px;
					}

					&:last-child {
						border-top-right-radius: 7px;
						border-bottom-right-radius: 7px;
					}
				}

				&.selected {
					td {
						background-color: rgba($blue, 0.15);
					}

					td:nth-of-type(1) {
						font-weight: bold;
					}
				}
			}
		}
	}

	& > .Polaris-LegacyCard {
		height: 100%;
		& > .Polaris-Card__Section,
		.Polaris-LegacyCard__Section {
			height: 100%;
		}
	}
}

.board_view_callist_list:not(.stats-card-table-modal) {
	table tr:hover {
		td {
			background-color: var(--main3);
		}
	}
}

.board_view_callist_graph_inner {
	.highcharts-label.highcharts-data-label text {
		font-size: 10px !important;
		fill: #ffffff !important;

		.highcharts-text-outline {
			display: none;
		}
	}
}
.stats-card-table-modal {
	min-height: 500px;
	height: 75vh;
	max-height: 80vh;

	table {
		width: 100%;
	}
}
