$svgHoverFill: #788998;

$blue: #4991cc;
$lightblue: #6eafe4;
$green: #5ac57d;
$accent: rgb(2 244 196);
$orange: #edab51;
$red: rgb(255 107 107);
$pink: #e477b9;
$yellow: #ffcb00;
$yellow2: #ffd66b;
$purple: #af78f4;
$grey: #a4a4a433;
$white: #f0f0f0;

:export {
	blue: $blue;
	lightblue: $lightblue;
	green: $green;
	accent: $accent;
	orange: $orange;
	red: $red;
	pink: $pink;
	yellow: $yellow;
	yellow2: $yellow2;
	purple: $purple;
	grey: $grey;
	white: $white;
}

.Toastify {
	.Toastify__toast-theme--dark,
	.Toastify__toast-theme--colored.Toastify__toast--default {
		background: var(--primary);
		border: 1px solid rgb(72, 78, 116);
		color: var(--textColor);
	}
}

body {
	--mainmenu-transition-time: 250ms;
	--mainmenu-transition-time-content: 350ms;

	--red: #ff6b6b;
	--yellow: #ffcb00;
	--green: #5ac57d;
	--blue: #4991cc;
	--purple: #af78f4;
	--orange: #edab51;
	--pink: #e477b9;
	--grey: #a4a4a4;

	--accent: #02f4c4;
	--accent-hover: rgb(0, 255, 204);
	--accent-subdued: #0d9e81e8;
	--actionColor: #5ac57d;

	--p-text-critical: #ff6b6b;
	--p-icon-critical: #ff6b6b;
	--p-surface-success: #5ac57d;

	--linkColor: #168bdc;
	--cardbg: rgb(255, 255, 255);
	// --main1: rgb(231, 233, 241);
	--main1: rgba(250, 250, 250, 1);
	--main1-0: rgba(231, 233, 241, 0);
	--main1-95: rgba(250, 250, 250, 0.95);
	--main2: rgb(236, 240, 245);
	--main2-0: rgba(236, 240, 245, 0);
	--main2-50: rgba(236, 240, 245, 0.5);
	--main2-95: rgb(245, 245, 245, 0.95);
	--main3: rgb(255, 255, 255);
	--main3-50: rgba(255, 255, 255, 0.5);
	--main3-0: rgba(255, 255, 255, 0);
	--main4: rgb(77, 82, 112);
	--textColor: #4a4a4a;
	--textColor2: #b8b8b8;

	//temp so copying from t24 is easier;
	--subdued: var(--main2);
	--primary: var(--main1);
	--secondary: var(--main2);
	--secondary-50: var(--main2-50);
	--tertiary: var(--main3);
	// quaternary, quinary, senary, septenary, octonary, nonary, denary.

	--color-primary: var(--textColor);
	--color-secondary: var(--textColo2);
	--color-subdued: var(--textColor2);
	--color-dark: rgb(10, 10, 10);
	--color-link: var(--linkColor);

	//end

	--sidebarBg: linear-gradient(180deg, #010622 0.04%, #1f1e59 100.04%);
	// --sidebarBg2: rgb(33, 37, 61);
	--sidebarBg2: transparent;
	--sidebarColorAccent: rgb(0, 200, 117);
	--sidebarColor: #ffffff;
	--borderColor: #dfe3e8;
	--rowHoverBg: rgb(200, 200, 200);

	--modals-bg: #ffffff;
	--modals-input-bg: rgb(231, 233, 241);
	--pulseAnimation: pulseDark 3s ease-in 0s infinite;

	--navigation-sidebar-width: 100px;

	--diagramBackground: var(--main2);
	--boxShadow: 1px 4px 12px 0px #00000030;

	--p-surface: var(--main3);
	--p-surface-hovered: var(--modals-input-bg);
	--sheet-width: 85vw;
	--sheet-width-pinned: min(85vw, 48.125rem);

	--p-color-bg: var(--main1);
	--p-color-bg-hover: var(--main2);
	--p-color-bg-app: var(--main1);
	--p-color-bg-app-active: var(--main3);
	--p-color-bg-app-hover: var(--main3);
	--p-color-bg-disabled: var(--main3);
	--p-color-bg-info-strong: var(--main3);
	--p-color-bg-info-subdued-active: var(--main3);
	--p-color-bg-info: var(--main3);
	--p-color-bg-info-subdued: var(--main3);
	--p-color-bg-interactive-selected: var(--main1);
	--p-color-bg-interactive: var(--main3);
	--p-color-bg-subdued: var(--main2);
	--p-color-bg-primary: var(--accent);
	--p-color-bg-interactive-subdued-hover: var(--main3);
	--p-color-bg-subdued-active: var(--main3);
	--p-color-bg-critical-subdued: var(--main3);
	--p-color-bg-interactive-subdued-active: var(--accent-subdued);
	// --p-color-bg-strong-hover: var(--main3);
	// --p-color-bg-strong-active: var(--main3);
	// --p-color-bg-strong: var(--main3);
	--p-color-bg-interactive-disabled: var(--main3);
	--p-color-bg-app-selected: var(--main3);
	--p-color-bg-active: var(--main3);
	--p-color-bg-subdued-hover: var(--main3);
	--p-color-bg-inset: var(--main3);
	--p-color-bg-input: var(--main3);
	--p-color-bg-info-subdued-hover: var(--main3);

	//text colors
	--p-text: var(--textColor);
	--p-color-text-interactive: var(--color-link);
	--p-color-text: #e7e7e7;
	--p-color-text-subdued: #b7b4b4;
	--p-color-bg-primary-subdued-hover: var(--accent);
	--p-color-text-primary: var(--color-dark);

	//border colors
	--p-color-border-input: var(--tertiary);
	--p-color-border-hover: var(--tertiary);
	--p-color-border-strong: var(--tertiary);
	--p-color-border: var(--tertiary);
	--p-color-border-disabled: var(--tertiary);
	--p-color-border-subdued: var(--tertiary);
	--p-color-border-interactive-disabled: var(--tertiary);
	--p-color-border-interactive-focus: var(--accent-subdued);

	//icons
	--p-color-icon: var(--textColor2);
	--p-color-icon-hover: var(--textColor);
	--p-color-icon-interactive: var(--accent);

	--p-color-bg-strong-hover: var(--tertiary);
	--p-color-bg-strong-active: var(--tertiary);
	--p-color-bg-strong: var(--tertiary);

	.Polaris-Box {
		--pc-box-background: var(--secondary);
		--pc-box-border-radius: 6px;
		--pc-box-border-color: var(--secondary) !important;
	}

	input {
		color-scheme: dark;
	}

	.Polaris-Page {
		& > .Polaris-Box {
			&,
			.Polaris-Box {
				--pc-box-background: transparent;
			}
		}
	}

	.Polaris-IndexTable__TableRow {
		--p-color-bg: var(--secondary);
		border: none;

		// &.Polaris-IndexTable__TableRow--hovered {
		// 	--p-color-bg: var(--tertiary);
		// }

		// &.Polaris-IndexTable__TableRow--striped {
		// 	--p-color-bg: var(--secondary-50);
		// }
	}

	.highcharts-background {
		fill: var(--secondary);
	}

	.highcharts-grid.highcharts-yaxis-grid path {
		stroke: var(--tertiary);
	}

	.Polaris-AlphaTabs__Tab {
		--p-color-bg: var(--secondary);
	}

	.Polaris-IndexFilters-Container {
		--p-color-border-subdued: transparent;
	}

	.Polaris-TextField__Backdrop {
		border-top-color: var(--p-color-border-input);
		border-color: transparent;
	}

	.Polaris-LegacyCard {
		// --p-color-bg: var(--secondary);

		// .Polaris-TextField__Backdrop {
		// 	--p-color-bg: var(--primary);
		// }
	}
	.Polaris-TextField__Backdrop {
		--p-color-bg: var(--tertiary);
	}

	.Polaris-Layout__Annotation .Polaris-TextContainer {
		.Polaris-Box {
			--pc-box-background: transparent;
		}
	}

	.Polaris-Badge {
		&.Polaris-Badge--statusSuccess,
		&.Polaris-Badge--statusAttention,
		&.Polaris-Badge--statusNew,
		&.Polaris-Badge--statusWarning,
		&[class="Polaris-Badge"] {
			--p-color-text: var(--color-dark);
			color: var(--color-dark);
		}
	}

	#PolarisPortalsContainer {
		.Polaris-Modal-Dialog__Modal {
			background: var(--secondary);
		}
		// .Polaris-Box {
		// 	--pc-box-border-radius: none;
		// }
	}

	.Polaris-Button {
		--p-color-bg: var(--tertiary);
	}

	.Polaris-Button--primary {
		--p-color-text-on-color: var(--color-dark);
		box-shadow: none;

		&:not(.Polaris-Button--destructive):not(.Polaris-Button--primaryPlain):hover {
			background: var(--accent-hover);
			border-color: var(--accent-hover);
		}

		&.Polaris-Button--disabled {
			background: var(--accent-subdued);
			border-color: var(--accent-subdued);
		}
	}

	.Polaris-Modal-Dialog__Modal {
		& > .Polaris-Box {
			// --pc-box-background: var(--secondary) !important;

			.Polaris-Modal-CloseButton {
				&:hover {
					background-color: transparent;
				}
			}
		}
	}

	.Polaris-Checkbox__Backdrop {
		--p-color-border-input: var(--tertiary);
		--p-color-bg: var(--secondary);
	}

	.Polaris-IndexTable__ZebraStriping .Polaris-IndexTable__TableRow:nth-child(2n + 1) {
		background: var(--tertiary);
	}

	.Polaris-Select__Backdrop {
		--p-color-bg: var(--secondary);
		--p-color-border-input: var(--secondary);
	}

	.Polaris-IndexTable__TableRow {
		--p-color-bg-interactive-subdued-hover: var(--primary);
		--p-color-bg-hover: var(--primary);

		&.Polaris-IndexTable__TableRow--selected {
			--p-color-bg-subdued: var(--primary);
			background: var(--primary) !important;
			background-color: var(--primary) !important;
		}

		&.Polaris-IndexTable__TableRow--hovered {
			--p-color-bg-subdued: var(--primary);
			background: var(--primary) !important;
			background-color: var(--primary) !important;
		}

		.Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
			transition: none;
		}

		.Polaris-IndexTable__TableCell:first-child,
		.Polaris-IndexTable__TableCell:last-child {
			background: transparent !important;
		}
	}

	.Polaris-IndexTable__TableHeadingSortButton {
		svg {
			fill: var(--color-primary);
		}
	}

	.Polaris-Banner {
		color: var(--color-dark);

		&.Polaris-Banner--withinContentContainer {
			color: var(--color-primary);
		}

		&.Polaris-Banner--statusInfo {
			color: var(--color-primary);
		}

		.Polaris-Box {
			--pc-box-background: transparent;
		}
	}

	.Polaris-Button {
		--p-border-radius-1: 1000px;
	}

	.Polaris-Tag {
		--p-color-text: var(--color-dark);
	}

	.Polaris-LegacyCard {
		--p-color-bg: var(--secondary);
	}

	.Polaris-Popover .Polaris-Box {
		--pc-box-background: var(--tertiary);
	}

	.Polaris-Icon--applyColor {
		--p-color-bg: var(--color-primary);
	}

	.Polaris-SortButton-DirectionButton {
		color: var(--color-primary);
	}

	.Polaris-SortButton-DirectionButton.Polaris-SortButton-DirectionButton__DirectionButton--active {
		--p-color-bg-interactive-selected: var(--secondary);
	}
	.Polaris-OptionList-Option {
		--p-color-bg-interactive-selected: var(--secondary);
	}

	.Polaris-AlphaFilters-FilterPill__PopoverWrapper {
		.Polaris-TextField__Backdrop {
			--p-color-bg: var(--secondary);
		}
	}
	.filter-search {
		background: var(--secondary);
	}

	button:not(.Polaris-Button--disabled):not(.Polaris-Button--primary):not(:hover):not(.Polaris-Button--destructive) {
		.Polaris-Button__Text.Polaris-Button--removeUnderline:not(:hover) {
			color: var(--color-primary);
		}
	}

	.Polaris-Spinner--sizeSmall {
		&,
		svg {
			height: 18px;
			width: 18px;
		}
	}

	.Polaris-Checkbox__Backdrop::before {
		--p-color-border-disabled: var(--accent-subdued);
		--p-color-bg-interactive-disabled: var(--accent-subdued);
		--p-color-border-interactive: var(--accent);
		--p-color-bg-interactive: var(--accent);
	}

	.Polaris-RadioButton__Input[checked] + .Polaris-RadioButton__Backdrop,
	.Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop {
		--p-color-border-disabled: var(--accent-subdued);
		--p-color-bg-interactive-disabled: var(--accent-subdued);
		--p-color-border-interactive: var(--accent);
		--p-color-bg-interactive: var(--accent);
	}
	.Polaris-Checkbox__Icon svg {
		--p-color-icon-on-color: var(--color-dark);
	}

	.Polaris-DatePicker {
		--p-color-bg-interactive: var(--accent-subdued);
		--p-color-bg-interactive-hover: var(--accent-subdued);
		--p-color-text-on-color: var(--color-primary);

		// .Polaris-DatePicker__Day.Polaris-DatePicker__Day--today {
		// 	background-color: var(--accent-subdued2);
		// }
	}

	.Polaris-Sheet {
		--p-color-bg: var(--secondary);
	}

	.Polaris-IndexTable__Table.Polaris-IndexTable__Table--scrolling.Polaris-IndexTable__Table--sticky {
		.Polaris-IndexTable__TableCell.Polaris-IndexTable__TableCell--first {
			background: var(--main2) !important;
		}
	}

	.Polaris-IndexTable__IndexTableWrapper.Polaris-IndexTable__IndexTableWrapperWithBulkActions .Polaris-IndexTable-ScrollContainer {
		padding-top: 36px;
	}

	&,
	div {
		--p-border-radius-base: 0.4375rem;
		--p-interactive: #5ac57d;
		--p-interactive-critical: #ff6b6b;
		--p-surface: var(--main2);
		--p-border-neutral-subdued: var(--borderColor);
		--p-border-shadow-subdued: var(--borderColor);
		--p-border-subdued: var(--borderColor);
		--p-text: var(--textColor);
		--p-icon: var(--textColor);

		--p-action-secondary-hovered: var(--main3);
		--p-surface-hovered: var(--main2-50);
		--p-background-hovered: var(--main2-50);
		--p-surface-neutral: var(--textColor);
		--p-surface-neutral-hovered: var(--textColor);
		--p-surface-neutral-pressed: var(--textColor);

		--p-background-pressed: var(--main3);
		--p-surface-pressed: var(--main1);
		--p-surface-selected: var(--main2);
		--p-surface-subdued: var(--main2);

		--p-shadow-from-dim-light: rgba(255, 255, 255, 0.1);
		--p-shadow-from-ambient-light: rgba(23, 24, 24, 0.05);
		--p-shadow-from-direct-light: rgba(255, 255, 255, 0.08);
		--p-hint-from-direct-light: rgba(185, 185, 185, 0.1);

		--p-popover-shadow: -1px 0px 10px var(--p-shadow-from-ambient-light), 0px 1px 5px var(--p-shadow-from-direct-light);
		--p-modal-shadow: 0px 2px 5px var(--p-shadow-from-dim-light), 0px 0px 1px var(--p-shadow-from-dim-light);

		--p-decorative-one-surface: #ffcb00;
		--p-decorative-two-surface: #ff6b6b;
		--p-decorative-three-surface: #edab51;
		--p-decorative-four-surface: #5ac57d;
		--p-decorative-five-surface: #4991cc;
		--p-decorative-six-surface: #af78f4;

		--p-progressbar-indicator: var(--accent);
		--pc-progress-bar-indicator: var(--accent);

		// .Polaris-Button--main1.Polaris-Button--disabled:not(.Polaris-Button--loading),
		// .Polaris-Button--destructive.Polaris-Button--disabled:not(.Polaris-Button--loading) {
		// 	--p-text-disable: rgba(15, 15, 15, 0.75);
		// 	color: rgba(15, 15, 15, 0.75);

		// 	svg {
		// 		fill: rgba(15, 15, 15, 0.75);
		// 	}
		// }

		button,
		.Polaris-Badge {
			--p-border-radius-base: 50px;
		}

		.Polaris-Navigation__Item--selected {
			--p-background-selected: transparent;
		}
	}

	.Polaris-Modal-Dialog__Container {
		color: var(--textColor);
	}
}

body[theme="dark"] {
	--linkColor: #2cc5ff;

	//background
	--cardbg: rgb(20, 24, 48);
	--main1: rgb(0, 5, 34);
	--main1-0: rgba(0, 5, 34, 0);
	--main1-50: rgba(0, 5, 34, 0.5);
	--main1-95: rgba(0, 5, 34, 0.95);
	--main2: rgb(20, 24, 48);
	--main2-0: rgba(20, 24, 48, 0);
	--main2-50: rgba(20, 24, 48, 0.5);
	--main2-95: rgba(20, 24, 48, 0.95);
	--main3: rgb(33, 37, 61);
	--main3-50: rgba(33, 37, 61, 0.5);
	--main3-0: rgba(33, 37, 61, 0);
	--rowHoverBg: rgb(33, 37, 61);

	--modals-bg: rgb(33, 37, 61);
	--modals-input-bg: rgb(44, 50, 79);

	//text
	--color: rgb(240, 240, 240);
	--textColor: rgb(240, 240, 240);
	--textColor2: rgb(150, 150, 150);
	--textColor3: #b7bdc2;
	--textColorDark: rgb(15, 15, 15);

	--sidebarBg: rgb(20, 24, 48);
	--sidebarBg2: rgb(33, 37, 61);
	--sidebarColorAccent: $green;
	--sidebarColor: #ffffff;

	// --borderColor: rgba(36, 38, 50, 0.744);
	--borderColor: rgba(117, 117, 117, 0.12);
	--pulseAnimation: pulse 3s ease-in 0s infinite;

	--diagramBackground: var(--main1);
}

body[branding="acordo"] {
	--accent: #00ccf2;
	--actionColor: #2b5ee7;
	--actionColorLight: #{lighten($accent, 1.2)};
}

$accent: var(--accent);
$actionColor: var(--actionColor);

$mainBackgroundColor: var(--main1);
$secondaryBackgroundColor: var(--main2);
$darkerBackground: var(--main2);
$textColor: var(--textColor);
$textColor2: var(--textColor2);
$textColorSubdued: var(--textColor3);

$buttonPadding: 0.4375rem 1rem;

$pulseAnimation: var(--pulseAnimation);

$textColorDark: rgb(20, 20, 20);
$textColorDisabled: #7b838a;

$linkColor: var(--linkColor);
$borderColor: var(--borderColor);
$bgHoverColor: rgb(44, 50, 79);
$bgFocusColor: rgb(53, 60, 95);

$cardbg: var(--cardbg);
$main1: var(--main1);
$main1-50: var(--main1-50);
$main2: var(--main2);
$main3: var(--main3);
$main1-0: var(--main1-0);
$main2-0: var(--main2-0);
$main2-50: var(--main2-50);
$main3-0: var(--main3-0);
$main3-50: var(--main3-50);
$sidebarColorBg: var(--sidebarBg, --main2);
$sidebarColorAccent: var(--sidebarColorAccent, --accent);
$sidebarColor: var(--sidebarColor);
$borderRadius: 0.4375rem;
$navSidebarWidth: var(--navigation-sidebar-width);
// $boxShadow: 1px 3px 4px 0px #00000030;
$boxShadow: 1px 4px 12px 0px #00000030;
$boxShadowSmall: 1px 3px 4px 0px #00000030;
$main1-95: var(--main1-95);
$main2-95: var(--main2-95);
$modals-bg: var(--modals-bg);

:root {
	// --toastify-color-success: #5ac57d;
	// --toastify-color-error: #ff6b6b;
	// --toastify-color-info: #4991cc;
	// --toastify-color-warning: #edab51;
}
