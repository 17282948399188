table.fortnox {
	background-color: $main3;
	width: 100%;
	border-radius: 5px;
	border: 0;
	border-collapse: collapse;
	position: relative;
	thead {
		tr {
			padding: 2px 0px;
			th {
				padding: 8px 0px;
				text-align: left;
				line-height: 20px;
				font-weight: 700;
				font-size: 11px;
				text-transform: uppercase;
				letter-spacing: 0.34px;
				&.text-right {
					text-align: right;
				}
			}
		}
	}
	tbody {
		tr {
			td {
				position: relative;
				height: 32px;
				label {
					color: var(--textColor);
				}
				input {
					border: 0;
					outline: none;
					width: 100%;
					height: fit-content;
					padding: 8px;
					border-radius: 0;
				}
				> span {
					padding: 8px;
				}
				&.text-right {
					text-align: right;
					input {
						text-align: right;
					}
				}
			}
		}
	}
}

table.Fortnox__Table {
	background-color: $main3;
	width: 100%;
	border-radius: 5px;
	border: 0;
	border-collapse: collapse;
	position: relative;
	thead.Fortnox__Table__Header {
		tr {
			th {
				padding: 8px;
				text-align: left;
				line-height: 20px;
				font-weight: 700;
				font-size: 11px;
				text-transform: uppercase;
				letter-spacing: 0.34px;
				&.text-right {
					text-align: right;
				}
			}
		}
	}
	tbody.Fortnox__Table__Body {
		tr.Fortnox__Table__Row {
			td.Fortnox__Table__Column {
				position: relative;
				height: 32px;
				input {
					border: 0;
					outline: none;
					width: 100%;
					height: 100%;
					padding: 8px;
					border-radius: 0;
				}
				> span {
					padding: 8px;
				}
				&.text-right {
					text-align: right;
					input {
						text-align: right;
					}
				}
			}
		}
	}
}

table.fortnox {
	tbody.Fortnox__Table__Body {
		tr.Fortnox__Table__Row {
			position: relative;
			td:last-of-type {
				text-align: end;
			}
			.Fortnox__Table__Row-Action {
				transition: opacity 500ms ease, visibility 0s ease 500ms;
				opacity: 0;
				visibility: hidden;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;
			}
			&:hover {
				.Fortnox__Table__Row-Action {
					opacity: 1;
					visibility: visible;
					transition: opacity 500ms ease, visibility 0s ease 0s;
				}
			}
		}
	}
}
