button.switch {
	outline: 0;
	border: 0;
	appearance: none;
	cursor: pointer;
}
.switch {
	--font-size: 3.8px; // Change this to control the scaling of the switch

	--height: 7em;
	--width: 18em;
	--margin: 0.5em;

	--color-night: rgba(15, 64, 91, 1);
	--color-day: rgba(91, 169, 211, 1);
	--color-sun: rgba(246, 211, 90, 1);

	--ease: cubic-bezier(0.77, 0, 0.175, 1);
	--duration: 750ms;

	.IntroductionSlide & {
		--font-size: 5px;
	}

	font-size: var(--font-size);
	position: relative;
	width: var(--width);
	height: var(--height);
	border-radius: 999px;
	background-color: var(--color-day);
	overflow: hidden;
	z-index: 2;
	box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.25);

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: var(--color-night);
		opacity: 0;
		z-index: 1;
		transition: opacity var(--duration) var(--ease) !important;
	}

	&__inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		transform-origin: right;
		transform: translate3d(calc(var(--width) - var(--height)), 0, 0);
		transition: transform var(--duration) var(--ease) !important;
		z-index: 2;

		.is-active & {
			transform: translate3d(0, 0, 0);
		}
	}

	&-globe {
		--size: calc(var(--height) - (var(--margin) * 2));
		position: relative;
		height: var(--size);
		width: var(--size);
		margin: var(--margin) 0 0 var(--margin);
		border-radius: 999px;
		background-color: var(--color-sun);
		transform: rotate(90deg);
		transition: transform var(--duration) var(--ease) !important;
		z-index: 1;

		&__circle {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 50%;
			width: 100%;
			border-radius: 999px;
			background-color: var(--color-day);
			transform: translate3d(50%, 0, 0);
			transition: transform var(--duration) var(--ease), background-color var(--duration) var(--ease) !important;

			.is-active & {
				background-color: var(--color-night);
				transform: translate3d(-10%, 0, 0);
			}
		}

		&__moon {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			border-radius: 999px;
			object-fit: cover;
			transform: scaleX(-1);
			opacity: 0;
			transition: opacity var(--duration) var(--ease) !important;

			.is-active & {
				opacity: 1;
			}
		}

		.is-active & {
			transform: rotate(0);
		}
	}

	&__stars {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		object-fit: contain;
		object-position: right;
		transform-origin: right;
		transform: scale(0.25);
		transition: transform var(--duration) var(--ease) !important;
		z-index: 2;

		.is-active & {
			transform: scale(0.9);
		}
	}

	&__cloud {
		position: absolute;
		transition: transform var(--duration) var(--ease) !important;
		width: auto;
		bottom: 0;

		&.first {
			left: 0;
			height: 65%;

			.is-active & {
				transform: translate3d(-1.8750rem, 0.6250rem, 0);
			}
		}

		&.second {
			left: 1.2500rem;
			height: 50%;

			.is-active & {
				transform: translate3d(-0.6250rem, 1.2500rem, 0);
			}
		}
	}

	&.is-active {
		&:after {
			opacity: 1;
		}
	}
}

.shadow {
	box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.25);
	border-radius: 999px;
}
