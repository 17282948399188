$fontSizeH1: 1.3em;
$fontWeightH1: 600;

$fontSizeH2: 1.25em;
$fontWeightH2: 600;
$padding: 1.0938rem;
$fontColor: #ffffff;

.contact-wrapper {
	$gap: 1.875rem;

	h1 {
		font-size: $fontSizeH1;
		font-weight: $fontWeightH1;
	}
	// h2 {
	// 	font-size: $fontSizeH2;
	// 	font-weight: $fontWeightH1;
	// }

	// h2 {
	// 	font-weight: 700;
	// 	font-size: 13px;
	// 	line-height: 15px;
	// 	text-align: center;
	// 	color: var(--textColor);
	// }

	.contact-wrapper__inner {
		display: flex;
		flex-direction: column;
		gap: $gap;
	}

	.Polaris-Layout__Section--secondary {
		// flex: 1 1 25.0000rem;
		// min-width: 400px;
		margin-left: $gap;
		min-width: 530px;
		width: 530px;
		// max-width: 530px;
	}

	.relations-wrapper {
		background: $main1;
		// padding: 2px 0;
	}

	.relations {
		padding: 0.3125rem 0.625rem !important;
		padding-left: 10px !important;
		background: $main1;
		margin: 3px 0px;
		position: relative;
		border: 1px solid var(--main2);
		border-radius: 3px !important;

		.left-status-line {
			width: 4px;
			border-radius: 2px;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
		}

		.seller-selector {
			width: max-content !important;
		}
	}

	.spacer {
		width: 100%;
		height: 2px;
		background: var(--textColor2);
		margin: 0.625rem 0 0.625rem 0;
		opacity: 0.25;
	}

	.pad {
		padding: 1.25rem;
	}

	.Polaris-ResourceList__ItemWrapper {
		.Polaris-Stack__Item {
			display: flex;
			align-items: center;
		}

		.Polaris-Icon {
			width: 1em;
			height: 1em;
		}
	}

	.contacts-list__wrapper {
		// margin-top: 1.2500rem;
		padding-bottom: 1.25rem;

		.pad.contacts-list__header {
			display: flex;
			justify-content: space-between;

			.Polaris-Button {
				height: max-content;
			}

			.star .Polaris-Button {
				.Polaris-Icon svg {
					fill: $yellow;
				}

				&:hover {
					.Polaris-Icon svg {
						fill: grey;
					}
				}
			}
		}

		.spacer {
			margin: 1.25rem 0 1.25rem 0;
		}

		.contacts-list__header {
			.extra-detail {
				display: flex;
				align-items: center;
				gap: 0.625rem;

				.Polaris-Icon {
					margin: 0;
				}
			}
		}

		.people {
			// margin-left: -0.8125rem;

			.Polaris-ResourceList__ItemWrapper:nth-child(odd) {
				background-color: var(--main1);
			}

			.Polaris-ResourceList__HeaderOuterWrapper,
			.Polaris-ResourceList__HeaderWrapper {
				background: transparent;
			}

			.Polaris-ResourceItem__Container {
				align-items: center;
				padding: 0.3125rem;
				padding-left: 0.625rem;

				.Polaris-ResourceItem__Content {
					min-height: 45px;
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
			}
		}
	}

	.contact__parent {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.Polaris-Stack {
			align-items: center;
		}
	}

	.company-search__details {
		margin-top: 0.625rem;

		.company-search__details__header {
			display: flex;
			justify-content: space-between;

			h1 {
				font-size: 1.0938rem;
				font-weight: 600;
			}
		}
	}

	.uploads-wrapper__header {
		padding: 0 1.25rem;
		border-bottom: 1px solid #828282;
		display: flex;
		gap: 0.625rem;
		justify-content: space-between;
		margin-bottom: 0.3125rem;
		padding-bottom: 5px;

		.selects-wrapper {
			display: flex;
			gap: 0.625rem;

			.select {
				width: max-content;
				min-width: 125px;
				display: flex;
				align-items: center;
			}
		}

		.no-found {
			padding: 1.25rem;
		}
	}
	.info-view__row__value {
	}

	.favorite_mark_button {
		&.checked {
			svg {
				fill: $yellow;
			}
		}
	}
}

.timeline_background {
	border-radius: 0 5px 5px 5px;
	position: relative;
	margin-top: 31px;
	background-repeat: no-repeat;
	background-position: center top;
	background-size: 100% auto;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		background-color: transparent;
		height: 35px;
		border-bottom-right-radius: 0.625rem;
		transform: translateY(-90%) rotate(180deg);
		border-style: solid;
		border-color: #295981 transparent transparent #fff0;
		border-width: 35px 0px 0px 35px;
	}

	&.person {
		background-image: url("../../public/assets/person_background2.png");
		background-color: rgb(20, 23, 48);

		&::before {
			border-color: rgb(20, 90, 92) transparent transparent #fff0;
		}
	}

	&.company {
		background-image: url("../../public/assets/company_background2.png");
		background-color: rgb(20, 23, 48);

		&::before {
			border-color: rgb(19, 64, 97) transparent transparent #fff0;
		}
	}
}

.contact-card {
	// background-color: var(--cardbg);
	h1 {
		font-size: $fontSizeH1;
		font-weight: $fontWeightH1;
	}
	h2 {
		font-size: $fontSizeH2;
		font-weight: $fontWeightH1;
	}

	font-size: 13px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	color: var(--textColor);
	// background-image: url("/assets/contact_background.png");
	// background-size: cover;
	padding: $padding;
	// padding-bottom: 0;
	position: relative;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	gap: 1.875rem;
	background-color: var(--cardbg);

	.info-view__labels,
	.info-view__values {
		gap: 0.625rem;
		font-size: 1em;
		line-height: 15px;
	}

	.timeline-wrapper {
		margin-left: -$padding;
		margin-right: -$padding;
	}

	.contact_relationships {
		// margin-left: -$padding;
		// margin-right: -$padding;
		// margin-bottom: -$padding;
	}

	.info-view {
		gap: 0.625rem;
	}

	.relationship-button {
		.number.number_badge {
			padding: 0.2188rem 0.625rem !important;
			font-size: 13px !important;
		}
	}

	// span.Polaris-Icon {
	// 	&,
	// 	svg {
	// 		fill: $fontColor;
	// 	}
	// }

	.contact_section {
		display: flex;
		gap: $padding;

		&.header {
			align-items: center;

			.contact_section_title {
				margin-bottom: 8px;
			}
		}

		.icon-with-background__wrapper {
			background-color: rgba(31, 32, 36, 0.5);

			svg {
				fill: $fontColor !important;
			}
		}

		.contact_section_content {
			width: calc(100% - calc(50px));
			max-width: calc(100% - calc(50px));

			p {
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100%;
			}
		}

		.contact_section_title {
			color: $fontColor;
			font-weight: bold;
			font-size: 16px;
			line-height: 19px;
			margin-bottom: 0.625rem;

			* {
				font-size: inherit;
			}
		}
	}

	.contact-card-info {
		display: flex;
		flex-direction: row;
		gap: $padding;
	}

	.gap {
		display: flex;
		flex-direction: column;
		gap: $padding;
	}
	.contact-card-info__labels {
		color: $textColor2;
		text-transform: capitalize;
		min-width: 125px;
	}

	.contact-card-info__labels,
	.contact-card-info__values {
		display: flex;
		flex-direction: column;
		gap: 1.25rem;
	}

	.info-view__row__label {
		font-weight: bold;
	}

	.header {
		.tags {
			color: rgb(150, 150, 150);
			font-size: 1em;
			display: flex;
			flex-wrap: wrap;
			// gap: 0.9375rem;

			.tag {
				position: relative;
				transition: margin-right 250ms;
				margin: 0;

				// &:not(:first-child) {
				// }
				margin-left: 0.2188rem;

				.Polaris-Button.Polaris-Button--destructive.Polaris-Button--iconOnly {
					opacity: 0;
					position: absolute;
					top: 0;
					right: 0;
					transition: opacity 100ms;

					min-height: unset;
					min-width: unset;
					padding: 0.0625rem;
					transform: translate(100%, -10%);

					.Polaris-Icon {
						width: 0.7812rem;
						height: 0.7812rem;
					}

					&:hover {
						opacity: 1;
					}
				}

				&:hover {
					margin-right: 0.9375rem;

					.Polaris-Button.Polaris-Button--destructive.Polaris-Button--iconOnly {
						opacity: 1;
					}
				}
			}
		}
	}

	.googlemaps_link {
		transition: text-decoration 250ms;
		display: flex;
		color: var(--textColor);

		svg {
			margin: 0 0.625rem;
		}
		&:hover {
			color: var(--textColor);
		}
	}

	.icon-with-background__wrapper {
		svg {
			fill: $fontColor !important;
		}
	}
}

.contact-note__wrapper {
	display: flex;
	justify-content: space-between;

	.Polaris-Button {
		height: max-content;
	}
}
.contact-note__wrapper__inner {
	display: flex;
	gap: 1.25rem;

	.icon-with-background__wrapper {
		background: rgba($green, 0.3);

		svg {
			fill: $green;
		}
	}
	// .contact-note__wrapper__icon {
	// 	border-radius: 50%;
	// 	height: 50px;
	// 	width: 50px;
	// 	background: rgba($green, 0.3);
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;

	// 	svg {
	// 		fill: $green;
	// 		filter: saturate(0.8);
	// 	}
	// }

	.contact-note__wrapper__content {
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: center;

		p {
		}
	}
}

.contact_note {
	// display: flex;
	// gap: 1.8750rem;
	border-radius: 5px;
	color: #ffffff;

	&.note_person {
		background: rgb(20, 23, 48);
	}

	&.note_company {
		background: rgb(20, 23, 48);
	}

	.contact_note__icon {
		// width: 75px;
		display: flex;
		justify-content: center;
		margin-left: 1.25rem;

		.icon-with-background__wrapper {
			background-color: rgba(0, 0, 0, 0.25);
		}
	}
	.contact_note__body {
		// display: flex;
		// flex-direction: column;
		width: 100%;
		// gap: 6px;

		p {
			word-break: break-word;
		}
	}
	.edit-button {
		.Polaris-Icon svg {
			fill: #ffffff;
		}
	}
}
.edit-button {
	font-size: 12px !important;

	.Polaris-Button {
		height: 25px;
		min-height: 25px;
		max-height: 25px;
		margin: 0;
	}
	.Polaris-Icon {
		width: 0.9375rem;
		height: 0.9375rem;
	}
}

.expand-card__btn {
	display: flex;
	gap: 0.3125rem;
	cursor: pointer;
	position: absolute;
	right: 0;
	color: var(--textColor);

	span {
		transform-origin: center center;
		display: flex;
	}

	.Polaris-Icon {
		height: 0.8438rem;
		width: 0.8438rem;
	}
}

.contact_document_header {
	display: flex;
	flex-direction: column;
	position: relative;
	margin-top: 0.9375rem;

	.contact_document_header_titles {
		display: flex;
		justify-content: center;
		position: absolute;
		inset: 0;
		align-items: center;
	}

	.contact_document_header_board_title {
		font-size: 11px;
		opacity: 0.75;
	}
	.contact_document_header__btn_wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		z-index: 1;
	}

	.contact_document_header__back_btn {
		// position: absolute;
		// left: 0;
		z-index: 1;

		button.Polaris-Button {
			color: var(--textColor);
			text-decoration: none;

			.Polaris-Button__Icon .Polaris-Icon svg {
				fill: var(--textColor);
			}
		}
	}
}

.contact-card .integrations-list__wrapper {
	margin-top: 1.25rem;
}

div.integrations-list__wrapper {
	display: flex;
	gap: 0.625rem;
	flex-wrap: wrap;

	button.Polaris-Button:not(.Polaris-Button--primary),
	button {
		// background: rgba(31, 32, 36, 0.5);
		background: var(--main3);
		color: var(--textColor);
		box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.25);
	}
}
.relations__inner {
	// max-width: 750px;
	display: flex;
	gap: 1.25rem;
	align-items: center;
	padding-left: 10px;
	justify-content: space-between;
	min-height: 42px;

	// > * {
	// 	flex: 1;
	// }

	.relationship-button .number_badge {
		padding: 0.1562rem 0.625rem !important;
		font-size: 12px !important;
	}
}

html body .import_option_box {
	// background-color: rgb(62, 73, 85);
	background-color: var(--main1);
	border-radius: 10px;
	padding: 20px;
	box-shadow: rgba(0, 0, 0, 0.25) 3px 4px 5px;
	justify-content: space-between;
	display: flex;
	flex-direction: column;
	gap: 0.7812rem;
	// width: 250px;
	flex: 1;
	font-size: 14px;
	line-height: 16px;
	min-height: 280px;

	.import_option_box__inner {
		display: flex;
		flex-direction: column;
		gap: 0.9375rem;
		overflow: hidden;
	}

	.import_option_box__icon {
		text-align: center;

		svg {
			height: 30px;
			width: 30px;
		}
	}

	.import_option_box__title {
		text-align: center;
		font-weight: 400;
		font-size: 18px;
		line-height: 21px;
	}

	.import_option_box__description {
		text-align: center;
	}
	.import_option_box__button {
		display: flex;
		justify-content: center;
		flex-direction: column;

		img,
		.Polaris-Icon,
		.Polaris-Spinner {
			height: 25px;
			width: 25px;
			object-fit: cover;
			margin-right: 10px;
		}

		.Polaris-Select {
			.Polaris-Select__Content {
				background-color: rgba($green, 0.25);
				height: 25px;
				min-height: 25px;
				border-radius: 12.5px;
				min-width: 150px;
				text-align: center;

				.Polaris-Select__Icon .Polaris-Icon svg {
					fill: var(--textColor);
				}

				.Polaris-Select__SelectedOption {
					overflow: hidden;
					white-space: pre-line;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
				}
			}

			select.Polaris-Select__Input {
				background-color: transparent !important;
			}

			.Polaris-Select__Backdrop {
				box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.25) !important;
				border: none;
				border-radius: 12.5px;
				background-color: transparent !important;
				background: transparent !important;

				&::after {
					border-radius: 12.5px;
					background-color: transparent !important;
					background: transparent !important;
				}
			}
		}
	}
}

.boxes_wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	gap: 1.25rem;
	padding: 0 1.875rem;
}

.box_arrow {
	display: flex;
	align-items: center;

	.Polaris-Icon {
		height: 25px;
		width: 25px;
	}
}

.infoview_result_count {
	.info-view__row__label {
		line-height: 30px;
	}
	.info-view__compoment {
		height: 30px;
	}
}

.excel_export_button {
	.Polaris-Button {
		min-width: 40px;
		min-height: 30px;
		height: 30px;

		img {
			height: 16px;
		}

		.Polaris-Button__Content {
			font-size: 12px;
		}
	}
}

.contact_dashboard {
	display: grid;
	gap: 1.875rem;
	grid-template-columns: 1.5fr 1fr 1fr;
	// grid-template-columns: auto minmax(400px, 500px) minmax(300px, auto);

	// h2 {
	// 	font-weight: 700;
	// 	font-size: 13px;
	// 	line-height: 15px;
	// 	text-align: center;
	// }

	// &:not(.have_integrations) {
	grid-template-areas: "stats timeline upcoming_activity" "stats note late_activity" "graph deals deals";
	// }

	// &.have_integrations {
	// 	grid-template-areas: "stats timeline upcoming_activity" "stats note integrations" "graph deals deals";

	// 	.contact_note__body {
	// 		p {
	// 			-webkit-line-clamp: 2;
	// 			line-clamp: 2;
	// 		}
	// 	}

	// 	.timeline__row__content__text {
	// 		&.preview {
	// 			-webkit-line-clamp: 2;
	// 			line-clamp: 2;
	// 		}
	// 	}
	// }

	.showmore {
		background-color: var(--cardbg);
		padding: 0;
		margin-top: 1.25rem;
		// position: absolute;
		// bottom: 0;
		// left: 0;
		// right: 0;
	}

	.integrations {
		grid-area: integrations;
	}

	.Polaris-LegacyCard {
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;

		.Polaris-Card__Section,
		.Polaris-LegacyCard__Section {
			// height: 100%;
			flex: 1;
		}
	}

	.integrations-list__wrapper {
		gap: 0.3125rem;

		.Polaris-Button__Text {
			font-size: 13px;
		}
	}

	.contact-statsbox-wrapper {
		grid-area: stats;
		display: grid;
		grid-template-columns: 1fr 1fr;
		width: unset;
		grid-template-rows: auto;

		// .statsbox {
		// 	height: 100px;
		// }

		.statsbox__content__text,
		.statsbox .statsbox__content .statsbox__content__title {
			font-size: 13px;
		}
	}
	.contact_dashboard__graph {
		grid-area: graph;
	}

	.timeline {
		grid-area: timeline;

		margin-bottom: 0;
	}

	.contact_note__body {
		p {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 6;
			line-clamp: 6;
			-webkit-box-orient: vertical;
		}
	}

	.contact_deals {
		grid-area: deals;

		.Polaris-Card__Section,
		.Polaris-LegacyCard__Section {
			padding-right: 0;
			padding-left: 0;
		}

		[class*="Polaris-Text--heading"] {
			padding-top: 1.25rem;
			padding-inline: 1.25rem;
		}

		table {
			tr {
				td,
				th {
					&:first-of-type {
						padding-left: 1.25rem;
					}
					&:last-of-type {
						padding-right: 1.25rem;
					}
				}
			}
		}

		.showmore {
			padding-bottom: 1.25rem;
		}
	}

	// .note_and_integrations_buttons {
	// 	grid-area: note;
	// 	height: 100%;
	// 	display: flex;
	// 	flex-direction: column;
	// 	gap: 1.8750rem;

	// 	.Polaris-LegacyCard {
	// 		flex: 1;
	// 		margin: 0;
	// 	}
	// }

	.contact_dashboard__graph {
		flex: 1;
		position: relative;

		.contact_dashboard__graph_header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 0 0.625rem;
			padding-bottom: 1.25rem;
		}
	}

	.board-empty_screen img {
		width: 200px;
		max-width: 100%;
	}
}
.color-year {
	display: flex;
	align-items: center;
	gap: 0.625rem;

	.circle {
		border-radius: 50%;
		height: 10px;
		width: 10px;
	}
}

.contact-statsbox-wrapper {
	display: flex;
	gap: 1.875rem;
	flex-direction: column;
	width: 300px;
}

.contact_selector {
	.missing {
		// border-bottom: 1px solid $red;
		color: $red;
		font-weight: bold;

		.Polaris-Icon.Polaris-Icon--colorRed svg {
			fill: $red !important;
		}
	}

	.contact_link {
		// opacity: 0;
		// width: 0;
		transition: opacity 100ms, width 250ms;

		// .Polaris-Icon {
		// 	max-height: 1.2500rem;
		// 	max-width: 1.2500rem;
		// }
	}

	&:hover {
		.contact_link {
			opacity: 1;
			// width: 25px;
		}
	}
}
.contact_tab__error_icon {
	svg {
		fill: $red;
	}
}

.small_card_title {
	text-align: start;
	display: flex;
	gap: 0.625rem;
}

.card_title {
	text-align: center;
}

.small_card_title,
.card_title {
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: var(--textColor);
	margin-bottom: 0.625rem;
	z-index: 1;
	position: relative;
}
