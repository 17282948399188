@import "./variables.scss";
@import "./modals.scss";
@import "./editor.scss";

.Polaris-Tabs {
	background: $main2;
}

.Polaris-Tabs__Tab,
.Polaris-Tabs__Tab:focus {
	box-shadow: none;

	&:first-letter {
		text-transform: capitalize;
	}
}

.Polaris-Tabs__Title {
	transition: border 250ms, color 250ms, background 250ms;
}

.Polaris-Tabs__Tab:hover .Polaris-Tabs__Title {
	color: $textColor;
	background: transparent;
}

* {
	scrollbar-color: #646464 transparent;
	scrollbar-width: 8px;
}

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background: transparent;
}
::-webkit-scrollbar-thumb {
	background-color: #646464;
	border-radius: 20px;
	border: transparent;
}
::-webkit-scrollbar-thumb:hover {
	background: var(--accent);
}

.oneline,
.one-line {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hideScrollbar,
.hideScroll,
.noScrollbar,
.noScroll,
.noscroll {
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.capitalize {
	text-transform: capitalize;
}

.loading-text:not(.board-container):after {
	overflow: hidden;
	display: inline-block;
	vertical-align: bottom;
	animation: ellipsis steps(3, end) 1800ms infinite;
	-webkit-animation: ellipsis steps(3, end) 1800ms infinite;
	content: "\2026";
	width: 0px;
}

.loading:not(.graph) {
	animation: $pulseAnimation;

	.Polaris-LegacyCard {
		animation: $pulseAnimation;
	}
}

.Polaris-LegacyCard {
	//
	position: relative;
}

.Polaris-Button {
	border-radius: 25px;
	border: 0;
	transition: color 250ms, background 250ms, border 250ms, box-shadow 250ms, filter 250ms;

	&:not(.Polaris-Button--plain) {
		box-shadow: $boxShadow;

		.Polaris-Button__Text {
			font-weight: 600;
		}
	}
	&:not(.Polaris-Button--plain):not(.Polaris-Button--destructive):not(.Polaris-Button--primary) {
		background: $main3;
		color: $textColor;
	}

	&.Polaris-Button--outline:not(.Polaris-Button--destructive) {
		border-style: solid;
		border-width: 1px;
		background: transparent;
		color: $accent;
		svg {
			fill: $accent;
		}

		&.Polaris-Button--primary {
			border-color: $accent;
		}
	}
	&.Polaris-Button--disabled {
		opacity: 0.7;
	}

	&.Polaris-Button--primary:not(.Polaris-Button--destructive):not(.Polaris-Button--outline):not(.Polaris-Button--primaryPlain) {
		transition: filter 250ms;
		background: $accent;
		color: rgb(15, 15, 15);

		svg {
			fill: rgb(15, 15, 15);
		}

		&:hover {
			filter: brightness(1.1);
		}
	}

	&.Polaris-Button--primaryPlain {
		--p-color-icon-on-color: var(--green);
		background: transparent;
		color: $green;

		// margin: calc(var(--pc-button-vertical-padding) * -1) calc(var(--p-space-2) * -1);
		// padding-left: var(--p-space-2);
		// padding-right: var(--p-space-2);

		// padding: 0;

		// svg {
		// 	fill: $accent;
		// }
	}

	&.Polaris-Button--outline {
		&.Polaris-Button--destructive {
			border: 1px solid $red;
			// background: rgba(255, 107, 107, 0.15);
			color: var(--textColor);

			svg {
				fill: $red !important;
			}

			&:hover {
				background: rgba(255, 107, 107, 0.35);
			}
		}

		&.Polaris-Button--primary {
			border: 1px solid $accent;

			color: var(--textColor);
			// background: rgba(2, 244, 196, 0.15);

			svg {
				fill: $accent;
			}

			&:hover {
				background: rgba(2, 244, 196, 0.35);
			}
		}
	}

	&:not(.Polaris-Button--primary):hover {
		filter: brightness(1.2);
	}
}

.Polaris-LegacyCard {
	border-radius: $borderRadius !important;
	box-shadow: $boxShadow !important;

	// 	input,
	// 	textarea,
	// 	select,
	// 	.Polaris-Select .Polaris-Select__Content,
	// 	.Polaris-TextField__Input,
	// 	.Polaris-TextField,
	// 	.Polaris-TextField__Backdrop {
	// 		background: $main1;
	// 	}

	// 	input,
	// 	textarea,
	// 	select,
	// 	.Polaris-TextField {
	// 		box-shadow: $boxShadowSmall;
	// 	}
}

// .Polaris-Badge--statusAttention,
// .Polaris-Badge--statusSuccess,
// .Polaris-Badge--statusWarning,
// .Polaris-Badge--statusInfo {
// 	.Polaris-Badge__Content {
// 		//text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
// 	}
// }

.Polaris-Badge--statusAttention {
	background-color: rgba($yellow, 1);
	color: white;
}

.Polaris-Badge--statusSuccess {
	background-color: rgba($green, 1);
	color: white;
}

.Polaris-Badge--statusWarning {
	background-color: rgba($orange, 1);
	color: white;
}

.Polaris-Badge--statusInfo {
	background-color: rgba($blue, 1);
	color: white;
}

.icon-with-background__wrapper {
	border-radius: 50%;
	height: 50px;
	width: 50px;
	min-width: 50px;
	background-color: $main1;
	display: flex;
	align-items: center;
	justify-content: center;
	fill: $textColor;

	&:not(.not_fill) {
		.Polaris-Icon svg {
			fill: inherit !important;
		}

		svg {
			fill: inherit !important;
		}
	}

	.Polaris-Icon {
		width: 50%;
		height: 50%;
		fill: inherit;
		display: flex;
		align-items: center;

		svg {
			filter: saturate(0.8);
		}
	}
	svg {
		filter: saturate(0.8);
	}
}

.popup-confirm {
	background: $cardbg;
	padding: 0.625rem;
	color: $textColor;
	border-radius: 5px;
	box-shadow: $boxShadowSmall;

	h1 {
		padding-bottom: 0.625rem;
		text-align: center;
	}

	.popup-confirm_buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		.Polaris-Button__Text {
			font-size: 0.9em;
		}
	}
	.Polaris-Button.Polaris-Button--destructive:not(.Polaris-Button--plain) {
		color: #ffffff;
	}
}
.RemoveButtonPopup_activator {
	.Polaris-Button__Text {
		font-size: 12px;
	}
}

.relationship-button,
.Polaris-ActionList__Item {
	&:hover {
		.number {
			filter: brightness(1.2);
		}
	}
}
html body {
	.sheet-medium {
		&,
		.Polaris-Sheet {
			width: 34.375rem !important;
			max-width: 85% !important;
			min-width: 34.375rem;
		}
	}

	.sheet-80 {
		&,
		.Polaris-Sheet {
			width: 50rem !important;
			max-width: 85% !important;
			min-width: 50rem;
		}
	}
	.sheet-90 {
		&,
		.Polaris-Sheet {
			width: 56.25rem !important;
			max-width: 85% !important;
			min-width: 56.25rem;
		}
	}

	.sheet-100 {
		&.Polaris-Sheet__Container,
		.Polaris-Sheet__Container,
		.Polaris-Modal-Dialog__Modal,
		.Polaris-Sheet {
			width: 62.5rem !important;
			max-width: 85% !important;
			min-width: 62.5rem;
			// transition: width 100ms;
		}
	}

	.sheet-110 {
		&.Polaris-Sheet__Container,
		.Polaris-Sheet__Container,
		.Polaris-Modal-Dialog__Modal,
		.Polaris-Sheet {
			width: 68.75rem !important;
			max-width: 85% !important;
			min-width: 68.75rem;
		}
	}
	.sheet-big,
	.sheet-120 {
		&.Polaris-Sheet__Container,
		.Polaris-Sheet__Container,
		.Polaris-Modal-Dialog__Modal,
		.Polaris-Sheet {
			width: 75rem !important;
			max-width: 85% !important;
			min-width: 75rem;
		}
	}
	.sheet-130 {
		&.Polaris-Sheet__Container,
		.Polaris-Sheet__Container,
		.Polaris-Modal-Dialog__Modal,
		.Polaris-Sheet {
			width: 75rem !important;
			max-width: 85% !important;
			min-width: 75rem;
		}
	}
}

[data-buttongroup-segmented="true"] > :first-child .Polaris-Button {
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
}

[data-buttongroup-segmented="true"] > :last-child .Polaris-Button {
	border-top-right-radius: 25px;
	border-bottom-right-radius: 25px;
}

.Polaris-Button.Polaris-Button--plain {
	color: $textColor;

	.Polaris-Icon:not(.Polaris-Icon--isColored) svg {
		fill: $textColor;
	}

	&:not(.Polaris-Button--destructive):hover .Polaris-Icon:not(.Polaris-Icon--isColored) svg {
		fill: $green;
	}
	&.Polaris-Button--destructive:hover .Polaris-Icon:not(.Polaris-Icon--isColored) svg {
		fill: $red;
	}
}

.Polaris-TextField__Backdrop {
	border: none !important;
	box-shadow: $boxShadow !important;
	background: transparent;
}

.Polaris-Select--disabled,
.Polaris-TextField--disabled {
	opacity: 0.75;
}

.Polaris-Tabs__Tab .Polaris-Tabs__Title,
.Polaris-Tabs__Wrapper {
	border-bottom-width: 3px;
}
.Polaris-Tabs__Wrapper {
	border-color: transparent;
}

input:not([class^="Polaris-"]),
textarea,
select,
.Polaris-Select .Polaris-Select__Content,
// .Polaris-TextField__Input,
// .Polaris-TextField,
.Polaris-TextField__Backdrop {
	font-family: Roboto, -apple-system, sans-serif;
	background: $main2;
	background-color: $main2;
	border-radius: 0.3125rem;

	&,
	* {
		color: $textColor;
	}
}
.Polaris-DatePicker {
	* {
		text-transform: capitalize;
	}
}
.sheet-top {
	.Polaris-Sheet__Container {
		z-index: 400001;
	}
	.Polaris-Backdrop {
		z-index: 400000;
	}
}
.modal-top {
	.Polaris-Modal-Dialog__Container {
		z-index: 500001 !important;
	}
	.Polaris-Sheet__Container {
		z-index: 500001 !important;
	}
	.Polaris-Backdrop {
		z-index: 500000 !important;
	}
}
.Polaris-LegacyCard {
	background-color: $cardbg;
}
.Polaris-Frame__Main div.Polaris-Tabs__Wrapper,
.Polaris-Tabs__Wrapper {
	background: $cardbg;
}

.bg {
	background-color: $main1;
}

.Polaris-Frame__Content,
.Polaris-Frame__Main,
.bg {
	color: $textColor;
}

.Polaris-Icon {
	fill: $textColor;
}

.Polaris-Frame {
	background-color: transparent;
}

//To prevent box-shadow clipping on borders.
// .Polaris-LegacyCard .Polaris-Button:not(.Polaris-Button--plain) {
// 	margin-bottom: 5px;
// }
// .Polaris-LegacyCard .Polaris-ButtonGroup .Polaris-Button:not(.Polaris-Button--plain) {
// 	margin-bottom: 0px;
// }

//För punkt att öka padding på "hela systemet (boards,dashboard,lists etc..)"
//Fungerar halvt
// html body div#root {
// 	.mainDashboard,
// 	.Polaris-Page,
// 	.board-container {
// 		padding: 3.7500rem 4.3750rem;
// 	}

// 	.board-container .Polaris-Page {
// 		@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
// 			padding: 0 2.0000rem;
// 		}
// 		@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
// 			padding: 0 1.2500rem;
// 		}
// 		@media (min-width: 46.5em) {
// 			padding: 0 2.0000rem;
// 		}
// 	}
// }

html body .Polaris-Modal-Dialog__Container {
	.Polaris-Modal-Header,
	.Polaris-Modal-Footer {
		border-color: transparent !important;
	}
}

html body .Polaris-Banner--withinPage.Polaris-Banner--statusInfo {
	box-shadow: none;
}

.button_inner,
.inner_button {
	display: flex;
	gap: 0.3125rem;
	align-items: center;

	.Polaris-Icon {
		display: flex;
		height: 100%;
	}
}

.Polaris-Button:not(.Polaris-Button--primary) {
	.Polaris-Icon:not(.Polaris-Icon--isColored) svg {
		fill: var(--textColor);
	}
}

.Polaris-Select {
	option {
		background-color: var(--main2);
	}
}

.Polaris-Popover__Wrapper,
.Polaris-Sheet {
	background: $main3;

	.Polaris-Stack__Item,
	.Polaris-OptionList-Option,
	.Polaris-Filters__FilterTrigger,
	.Polaris-Filters__FilterTriggerTitle,
	.Polaris-Filters__FiltersContainerHeader {
		color: $textColor;
	}
}

.Polaris-Frame__Main {
	background-color: var(--main1);
}

.highcharts-credits {
	display: none;
}

.highcharts-legend .highcharts-legend-item {
	text {
		fill: $textColor !important;
		color: $textColor !important;
	}
}

html body {
	.Polaris-LegacyCard {
		.Polaris-TextField__Backdrop,
		.Polaris-Select .Polaris-Select__Content {
			background-color: var(--main3);
		}
		.Polaris-TextField__Input {
			background: transparent;
		}

		.Polaris-Select {
			width: max-content;
		}
	}
}

.Polaris-TextField.Polaris-TextField--readOnly {
	.Polaris-TextField--focus > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop::after,
	.Polaris-TextField__Input:focus ~ .Polaris-TextField__Backdrop::after {
		opacity: 0;
	}
}

.big_search .Polaris-ResourceList[aria-busy="true"] {
	opacity: 0.5;
}

.Polaris-ProgressBar {
	background-color: var(--main1);
}

.highcharts-loading {
	background-color: $main2 !important;
	background: $main2 !important;
}

.line_chart,
.area_chart {
	position: relative;
}

.clamp1 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	line-clamp: 1;
	-webkit-box-orient: vertical;
}
.clamp2 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}
.clamp3 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
}
.clamp4 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	line-clamp: 4;
	-webkit-box-orient: vertical;
}
.clamp5 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 5;
	line-clamp: 5;
	-webkit-box-orient: vertical;
}

option:disabled {
	color: var(--textColor2);
}

.Polaris-Button__ConnectedDisclosureWrapper {
	.Polaris-Button__ConnectedDisclosure:last-child {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}
