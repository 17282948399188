.todoSectionsList {
	// background-color: $secondaryBackgroundColor;
	// padding: $ContainerPadding;
}

.todoSection {
	margin-bottom: 10px;
	&:last-child() {
		margin-bottom: 0;
	}

	.Polaris-ProgressBar__Indicator {
		background-color: grey;
	}
	.Polaris-ProgressBar {
		background-color: black;
	}

	.header h2.Polaris-Text--root {
		font-size: 1.25rem;
		font-weight: normal;

		.Polaris-TextStyle--variationSubdued {
			font-size: 0.8125rem;
		}
	}

	&.red {
		.Polaris-ProgressBar__Indicator {
			background-color: $red;
		}
		.header h2.Polaris-Text--root {
			color: $red;
		}
	}

	&.blue {
		.Polaris-ProgressBar__Indicator {
			background-color: $blue;
		}
		.header h2.Polaris-Text--root {
			color: $blue;
		}
	}

	&.green {
		.Polaris-ProgressBar__Indicator {
			background-color: $green;
		}
		.header h2.Polaris-Text--root {
			color: $green;
		}
	}

	&.yellow {
		.Polaris-ProgressBar__Indicator {
			background-color: $yellow;
		}
		.header h2.Polaris-Text--root {
			color: $yellow;
		}
	}

	.header {
		// background-color: $darkerBackground;
		background-color: $cardbg;
		padding: 10px;
		border-radius: 10px;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		-ms-border-radius: 10px;
		-o-border-radius: 10px;
		box-shadow: $boxShadow;
		cursor: pointer;
		position: relative;
		transition: filter 250ms;

		.leftIcon img {
			user-select: none;
		}

		.title {
			display: flex;
			flex-direction: column;
			user-select: none;
		}

		&:hover {
			filter: brightness(1.1);
			-webkit-filter: brightness(1.1);
		}
	}

	.Polaris-Stack__Item {
		display: flex;
		align-items: center;
	}
}

.todoSection .header {
	.right-buttons-container {
		position: absolute;
		right: 25px;
		display: flex;
		top: 50%;
		align-items: center;
		gap: 40px;
		transform: translateY(-50%);
	}
}

.todoSectionsList .todoItem,
.calendarList .todoItem {
	// &:last-of-type {
	// 	margin-bottom: 60px !important;
	// }

	> .Polaris-Stack:first-of-type > .Polaris-Stack__Item:first-of-type {
		width: calc(100% - 200px);
	}

	.extraData {
		display: flex;
		flex-direction: column;
		padding-left: 10px;
		row-gap: 10px;
		padding-bottom: 10px;
		font-size: 10px;

		&:empty {
			display: none;
		}

		span,
		p,
		a,
		.name {
			display: flex;
			transition: color 250ms;
			-webkit-transition: color 250ms;
			-moz-transition: color 250ms;
			-ms-transition: color 250ms;
			-o-transition: color 250ms;
			font-size: 1.15em;

			&:hover {
				color: "#ffffff";
				.Polaris-Icon {
					fill: #ffffff;
				}
			}
		}

		.Polaris-Icon {
			margin: 0;
			fill: rgb(200, 200, 200);
			margin-right: 5px;
			height: 1.2em;
			width: 1.2em;
			transition: fill 250ms;
			-webkit-transition: fill 250ms;
			-moz-transition: fill 250ms;
			-ms-transition: fill 250ms;
			-o-transition: fill 250ms;
		}
	}
}

.rightButtonsContainer {
	//height: 100%;
	position: relative;
	display: flex;
	width: 100%;
	// justify-content: flex-end;
	transition: transform 250ms;
	//transform: translate(-100px, -100%);
	-webkit-transition: transform 250ms;
	-moz-transition: transform 250ms;
	-ms-transition: transform 250ms;
	-o-transition: transform 250ms;
}

// .todoStatusModal {
// 	transition: transform 250ms;
// 	-webkit-transition: transform 250ms;
// 	-moz-transition: transform 250ms;
// 	-ms-transition: transform 250ms;
// 	-o-transition: transform 250ms;
// }

.todoItem {
	// background-color: $darkerBackground;
	background-color: transparent;
	cursor: pointer;
	min-height: 50px;
	border-radius: 10px;
	margin-top: 7px;
	margin-bottom: 7px;
	box-shadow: $boxShadow;
	transition: filter 250ms;

	.todoItem_title {
		font-weight: 600;
		font-size: 0.75rem;
	}

	.row {
		min-height: 50px;
		margin-left: 5px;
		display: flex;
		align-items: center;
		width: 100%;

		.row-inner {
			padding: 10px;
			padding-left: 0;
			width: 100%;
			position: relative;

			.Polaris-Subheading {
				text-transform: none;
			}

			.contacts {
				color: rgb(150, 150, 150);
				font-weight: bold;
				font-size: 0.9em;
			}
		}
	}

	.comment-count {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 25px;
		margin-right: 17px;

		.comment-button {
			cursor: pointer;
			padding: 5px;
			display: block;
			position: relative;
		}

		.comment-count-number {
			position: absolute;
			top: 7px;
			left: 7px;
			width: 16px;
			height: 16px;
			border-radius: 8px;
			line-height: 16px;
			color: #ffffff;
			font-size: 9px;
			text-align: center;
			font-weight: 600;
		}
	}

	&:hover {
		filter: brightness(1.1);
		-webkit-filter: brightness(1.1);
	}

	// .Polaris-Subheading .Polaris-Truncate {
	// 	display: flex;
	// 	align-items: center;
	// }
	// &.alert {
	// 	background-color: #fdd7a4;
	// 	.Polaris-Subheading {
	// 		color: rgb(10, 10, 10);

	// 	}
	// }

	.subtextLink {
		transition: filter 150ms, color 150ms;
		-webkit-transition: filter 150ms, color 150ms;
		-moz-transition: filter 150ms, color 150ms;
		-ms-transition: filter 150ms, color 150ms;
		-o-transition: filter 150ms, color 150ms;

		&,
		* {
			text-decoration: none !important;
		}

		&:hover {
			filter: brightness(1.1);
			-webkit-filter: brightness(1.1);
		}
	}

	.subtext {
		&,
		* {
			font-size: 0.9em;
			text-decoration: none;
			color: $textColor2;
		}
	}

	// .Polaris-Stack {
	// 	display: grid;
	// 	grid-template-columns: auto 100px;
	// }

	.editIcon {
		margin: 0 2.5px;
		padding: 0 5px;
		fill: $bgHoverColor;
		display: flex;
		transition: fill 250ms;
		-webkit-transition: fill 250ms;
		-moz-transition: fill 250ms;
		-ms-transition: fill 250ms;
		-o-transition: fill 250ms;

		&:hover {
			fill: $bgFocusColor;
		}
	}

	.checkbox {
		transition: width 250ms, min-width 250ms, opacity 250ms;
		-webkit-transition: width 250ms, min-width 250ms, opacity 250ms;
		-moz-transition: width 250ms, min-width 250ms, opacity 250ms;
		-ms-transition: width 250ms, min-width 250ms, opacity 250ms;
		-o-transition: width 250ms, min-width 250ms, opacity 250ms;
		overflow: hidden;
		width: 7px;
		min-width: 7px;
		height: 100%;
		opacity: 0.5;
		display: flex;
		align-items: center;
		border-radius: 2px;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		-ms-border-radius: 2px;
		-o-border-radius: 2px;

		&:hover {
			width: 35px;
			min-width: 35px;
			opacity: 1;
		}

		.inside {
			padding-left: 10px;
			padding-right: 10px;
		}

		.Polaris-Choice__Control {
			margin: 0;
		}
		&.show {
			width: 35px;
			min-width: 35px;
			opacity: 1;
		}

		&.hide {
			// width: unset;
		}
	}
}
.statusButton {
	margin: 0 2.5px;
	background: none !important;
	border: none !important;
	opacity: 0.9;
	cursor: pointer;
	transition: opacity 500ms transform 250ms;
	-webkit-transition: opacity 500ms transform 250ms;
	-moz-transition: opacity 500ms transform 250ms;
	-ms-transition: opacity 500ms transform 250ms;
	-o-transition: opacity 500ms transform 250ms;
	height: 100%;

	&.close {
		opacity: 0.75;
	}
	&:hover {
		opacity: 1;
		// transform: scale(1.1);
		// -webkit-transform: scale(1.1);
		// -moz-transform: scale(1.1);
		// -ms-transform: scale(1.1);
		// -o-transform: scale(1.1);
	}
}

.leftIcon {
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	height: 50px;
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $cardbg;
	box-shadow: $boxShadow;

	.Polaris-Icon {
		transform: scale(1.3);
		-webkit-transform: scale(1.3);
		-moz-transform: scale(1.3);
		-ms-transform: scale(1.3);
		-o-transform: scale(1.3);
	}

	&.red {
		.Polaris-Icon {
			fill: $red;
		}
	}

	&.blue {
		.Polaris-Icon {
			fill: $blue;
		}
	}
	&.green {
		.Polaris-Icon {
			fill: $green;
		}
	}
	&.yellow {
		.Polaris-Icon {
			fill: $yellow;
		}
	}
	&.grey {
		.Polaris-Icon {
			fill: #343434;
		}
	}
}

body[theme="dark"] .leftIcon {
	background: $main1;
}

.statusModal {
	// top: 0px;
	// position: absolute;
	padding: 5px;
	width: 450px;
	background: $secondaryBackgroundColor;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	// right: 50px;
	// box-shadow: 3px 4px 6px #1f1f1f;
	// display: grid;
	// grid-template-columns: auto;
	display: flex;
	flex-direction: column;
	gap: 10px;
	color: $textColor;
	height: 100%;

	button[type="submit"] {
		color: $textColor;
		background: rgb(36, 147, 210) !important;
		transition: color 250ms, background 250ms, filter 250ms;
		-webkit-transition: color 250ms, background 250ms, filter 250ms;
		-moz-transition: color 250ms, background 250ms, filter 250ms;
		-ms-transition: color 250ms, background 250ms, filter 250ms;
		-o-transition: color 250ms, background 250ms, filter 250ms;

		&:hover {
			filter: brightness(1.1);
			-webkit-filter: brightness(1.1);
		}
	}
	button[disabled] {
		filter: brightness(0.8);
		border-color: transparent;
		-webkit-filter: brightness(0.8);
	}

	& > * {
		grid-row: 1;
	}
}

.to_do_header {
	display: flex;
	justify-content: space-between;
	padding-bottom: 10px;
	align-items: center;
	height: 3.125rem;

	h1 {
		font-size: 1.5625rem;
		font-weight: bold;
	}

	.disconnect_calendar_button {
		cursor: pointer;
		background: #fa919100 !important;
		border: none !important;
		height: 100%;
		fill: rgb(200, 200, 200);
	}

	.rightSection {
		display: flex;
		gap: 50px;
		flex-direction: row;
	}
}

.calendar_dropdown {
	display: flex;
	flex-direction: column;
	// gap: 10px;
	padding: 10px;
	svg {
		transition: transform 250ms;
		-webkit-transition: transform 250ms;
		-moz-transition: transform 250ms;
		-ms-transition: transform 250ms;
		-o-transition: transform 250ms;
	}

	&.open {
		svg {
			transform: rotate(180deg);
			-webkit-transform: rotate(180deg);
			-moz-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			-o-transform: rotate(180deg);
		}
	}

	// > button {
	// 	cursor: pointer;
	// 	background: #fa919100 !important;
	// 	border: none !important;
	// 	height: 100%;
	// }

	> * {
		cursor: pointer;
	}
}

.Polaris-DatePicker__Day--today {
	background-color: #717171;
}

.Polaris-DatePicker__Title.Polaris-DatePicker__Month--current {
	text-transform: capitalize;
}

.Polaris-DatePicker__EmptyDay {
	&:hover {
		background-color: transparent;
	}
}
.Polaris-DatePicker__Day,
.Polaris-DatePicker__EmptyDay {
	border-color: transparent;
}

.Polaris-DatePicker__Day--selected {
	background-color: $blue;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
}
.Polaris-DatePicker__Day--selected:focus {
	box-shadow: none;
}

.calendar {
	background: $cardbg;
	padding: 1.25rem;
	padding-bottom: 0;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	box-shadow: $boxShadow;

	.Polaris-DatePicker__Header {
		button,
		button:hover {
			background-color: transparent;
		}
	}
}

.loadmore {
	text-align: center;
	width: 100%;
	display: flex;
	justify-content: center;
	height: 35px;
	align-items: center;
	position: relative;

	.loadButton {
		position: relative;
		width: max-content;
		height: 100%;
		align-items: center;
		background: transparent;
		border: none;
		color: rgb(150, 150, 150);
		transition: color 250ms;
		-webkit-transition: color 250ms;
		-moz-transition: color 250ms;
		-ms-transition: color 250ms;
		-o-transition: color 250ms;
		padding: 5px 10px;

		&:hover {
			color: rgb(255, 255, 255);
		}

		&.less {
			position: absolute;
			right: 0;
		}
	}

	.Polaris-Spinner {
		position: absolute;
		right: 0;
		top: 0;
		transform: translateX(120%);
		height: 100%;
		display: flex;
		align-items: center;
	}
}

.CompletedStatusModal {
	display: flex;
	flex-direction: row;
	margin-top: 20px;
	justify-content: flex-start;
	margin-bottom: 5px;
	align-items: center;
	font-size: 1.2em;

	.Polaris-Icon {
		margin: 0;
		margin-right: 10px;
		height: 2em;
		width: 2em;
	}

	h1 {
		font-weight: bold;
		font-size: 1em;
	}
}
.weeklyTodoNewDatePicker {
	padding: 7px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 300px;

	> button {
		width: 50%;
		margin: 15px 0 10px 0;
	}
}
