$fontSizeH1: 1.3em;
$fontWeightH1: 600;

$fontSizeH2: 13px;
$fontWeightH2: 600;

.timeline-wrapper {
	$padding: 1.25rem 0.75rem;
	$betweenSpacing: 0.9375rem;

	h1 {
		font-size: $fontSizeH1;
		font-weight: $fontWeightH1;
	}
	h2:not(.small_card_title) {
		font-size: $fontSizeH2;
		font-weight: $fontWeightH1;
	}

	color: #ffffff;

	// background: var(--cardbg);
	// background-color: rgba(0, 0, 0, 0.2);
	// border-radius: $borderRadius;

	// max-height: calc(100vh - 175px);
	// min-height: calc(100vh - 175px);
	// overflow: auto;
	// overflow-x: hidden;

	.timeline__header {
		// margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		// position: absolute;
		// top: -13px;
		left: 2.5rem;
	}

	.timeline__rows {
		&:not(.preview) {
			margin-top: 25px;
		}

		.Polaris-Icon {
			height: 15px;
		}
	}
}

.timeline__rows.preview {
	.loading-skeleton .Polaris-Card__Section,
	.Polaris-LegacyCard__Section {
		padding: 9px;
	}
}

.timeline__row {
	display: flex;
	$betweenSpacing: 0.9375rem;
	$padding: 0.4688rem 0.75rem;

	*:not(textarea):not(input) {
		color: var(--textColor);
	}

	.Polaris-Button:not(.Polaris-Button--destructive):not(.Polaris-Button--primary):not(.Polaris-Button--plain) * {
		color: $textColor;
	}

	.form_buttons {
		.Polaris-Button__Content .Polaris-Button__Text {
			font-size: 13px;
		}
		.Polaris-Button.Polaris-Button--destructive svg {
			fill: #ffffff;
		}
	}

	.details-wrapper {
		display: flex;
		flex-direction: column;
		gap: 0.3125rem;
		padding-bottom: 0.3125rem;
	}

	.timeline__row__date {
		min-width: 75px;
		max-width: 75px;
		text-align: right;
		padding: $padding;
		padding-right: $betweenSpacing;
		padding-left: 0;
		display: flex;
		flex-direction: column;

		.timeline__row__date__date {
			font-weight: 600;
			text-transform: capitalize;
		}
		.timeline__row__date__time {
			font-weight: 600;
			text-transform: capitalize;
		}
		.timeline__row__date__year {
			font-size: 0.8em;
			color: $textColor2;
		}
	}

	.timeline__row__line {
		transition: background 250ms, width 250ms, opacity 250ms;
		left: 0;
		top: 0;
		position: absolute;
		height: 100%;
		width: 1px;
		background: $textColor2;
		transform: translateX(-100%);
		opacity: 0.3;
	}
	.timeline__row__content {
		padding: $padding;
		padding-left: $betweenSpacing;
		position: relative;
		flex: 1;

		// &::before {
		// 	content: "";
		// 	position: absolute;
		// 	width: 90%;
		// 	left: 5%;
		// 	top: 0;
		// 	height: 1px;
		// 	background: $textColor2;
		// 	opacity: 0.3;
		// }

		.Polaris-Icon {
			width: 16px;
			height: 16px;
			min-width: 16px;
			display: flex;
		}

		.timeline__row__content__title__wrapper {
			display: flex;
			justify-content: space-between;

			.timeline__row__content__title {
				display: flex;
				gap: 0.625rem;
				align-items: center;

				h2:not(.small_card_title) {
					font-size: 13px;
					line-height: 15px;
				}

				.Polaris-Icon {
					fill: #ffffff;
				}
			}
			.timeline__row__content__title-inner {
				display: flex;
				gap: 0.625rem;
				align-items: center;
			}

			.Polaris-Icon {
				margin: 0;
				// margin-right: 0.3125rem;
				// margin-top: 2px;
				width: 16px;
				height: 16px;
				min-width: 16px;
				display: flex;
			}
		}

		.timeline__row__content__text {
			padding: 0.625rem 0;
			word-break: break-word;

			&.preview {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 5;
				line-clamp: 5;
				-webkit-box-orient: vertical;

				padding: 0;
			}
		}

		.creator {
			display: flex;
			gap: 0.3125rem;
			padding: 0.3125rem 0;

			.Polaris-Icon {
				margin: 0;
			}
		}
	}

	&.preview {
		.timeline__row__date,
		.timeline__row__content {
			padding-block: 0;
		}
	}

	&:hover {
		.timeline__row__line {
			width: 2px;
			background-color: rgb(175, 175, 175);
			opacity: 0.75;
		}
	}

	&.selected {
		color: $green;

		.timeline__row__line {
			width: 2px;
			background-color: $green;
		}
	}
}

.showmore {
	display: flex;
	width: 100%;
	justify-content: center;
	padding: 1.25rem 0;

	& > button {
		transition: color 250ms, font-weight 250ms;
		cursor: pointer;

		color: rgb(235, 235, 235);

		&:hover {
			color: #ffffff;
			font-weight: bold;
		}
	}
}

.timeline__rows__new,
.timeline__row {
	.Polaris-Select__SelectedOption {
		color: #ffffff;
	}

	.Polaris-Select__Icon svg {
		fill: #ffffff;
	}

	.Polaris-Select__Backdrop {
		border: none;
		background: transparent;

		&::after {
			background: transparent !important;
		}
	}
	.Polaris-TextField {
		background: transparent !important;

		input,
		textarea {
			background: #ffffff1a !important;
		}
	}

	.Polaris-Select__Content {
		background: #ffffff1a !important;
	}
}

.timeline__row__content__buttons {
	display: flex;
	gap: 0.625rem;

	.edit_btn {
		.Polaris-Icon svg {
			fill: #ffffff;
		}
	}

	& > button {
		height: 25px;
		min-height: 25px;
		max-height: 25px;
		margin: 0;

		.Polaris-Button__Text {
			font-size: 12px;
		}
	}
}
.timeline__rows__new,
.timeline__row__content {
	$fontSize: 13px;
	$lineHeight: 16px;
	font-size: $fontSize;
	line-height: $lineHeight;
	display: flex;
	flex-direction: column;
	gap: 6px;

	.mention_title,
	.attachment_files {
		display: flex;
		align-items: center;

		a,
		.mention_title {
			max-width: 250px;
		}

		.removeButton {
			position: relative;
			transform: none;
		}
	}

	.field {
		display: flex;
		flex-direction: row;
		gap: 3px;
		align-items: center;
		height: 20px;
		color: #ffffff;

		.Polaris-Icon svg {
			fill: #ffffff;
		}

		.label {
			display: flex;
			align-items: center;
			gap: 0.625rem;
		}

		.contact_selector .resource_picker_btn .Polaris-Button,
		.attachment-title a {
			color: #ffffff;
		}
	}
	.Polaris-Select {
		width: 225px;
		border: none !important;
		outline: none !important;

		.Polaris-Select__Content {
			height: 36px;
		}

		.Polaris-Select__Input,
		.Polaris-Select__Content {
			font-size: 13px;
			line-height: 15px;
		}

		.Polaris-Select__Content,
		.Polaris-Select__Backdrop::after {
			min-height: unset;
			border-radius: 20px;
		}
	}

	.attachment_files {
		// width: 100%;
		min-height: unset;
		padding: 0;
		margin: 0;

		.removeButton {
			.Polaris-Icon {
				height: 15px;
				width: 15px;
			}
		}

		.attachment_edit_btn {
			margin-top: -3px;
		}

		.attachment-title .Polaris-TextField__Input {
			min-height: unset;
			height: 20px;
		}

		.Polaris-Button {
			min-height: unset;
			// padding: 0;
		}
		.Polaris-Button:not(.Polaris-Button--primary) {
			padding: 0;
			min-height: unset;
		}
		.Polaris-Button.Polaris-Button--primary {
			padding: 2px;

			.Polaris-Button__Content {
				font-size: 10px;
				line-height: 12px;
			}
		}

		.Polaris-TextField__Input {
			min-height: unset;
			padding: 0rem 0.75rem;
			border-radius: 25px;
		}
	}

	.contact_selector {
		.Polaris-Button {
			padding: 0;
		}
		.Polaris-Icon svg {
			fill: #ffffff;
		}

		* {
			margin-block: 0;
		}

		.sel > div {
			margin-top: unset;
			height: unset;
		}

		& > div > div.Polaris-Stack .Polaris-Stack__Item {
			display: flex;
			align-items: center;
		}

		.resource_picker_btn .Polaris-Button {
			padding: 0;
			min-height: unset;
			margin: 0;
		}
	}

	.timeline__rows__input {
		.Polaris-Button__Content {
			font-size: $fontSize;
			line-height: $lineHeight;
		}

		.Polaris-TextField__Suffix {
			margin-right: 0;
		}

		.Polaris-TextField__Suffix > .Polaris-Stack {
			align-items: center;
			& > .Polaris-Stack__Item {
				margin: 0;

				> span {
					display: flex;
					align-items: center;
				}
			}
		}

		.resource_picker_btn {
			display: flex;
			align-items: center;
		}

		.Polaris-TextField__Backdrop {
			border-radius: 30px;
		}

		.Polaris-TextField__Input {
			border-radius: 30px;
		}

		.Polaris-TextField__Suffix {
			padding: 0;

			.Polaris-Button {
				min-height: unset;

				.Polaris-Icon {
					height: 18px;
				}
			}
		}
	}
}

.timeline__rows__new {
	gap: 0.75rem;
}
