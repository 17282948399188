@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

body {
	background-color: #082945;
}

body {
	background-image: url("./images/login_background.png");
	background-size: cover;
}

// html,
// body {
// 	font-size: 13px;
// 	height: 100%;
// 	padding: 0;
// 	margin: 0;
// 	font-family: Roboto, -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Helvetica Neue, sans-serif;
// 	background-color: #082945;
// }
// body {
// 	// background: url(./registration.png) center top no-repeat;
// 	// background-color: $backgroundColor;
// 	background-size: cover;
// }
// * {
// 	box-sizing: border-box;
// }

// .loader,
// .loader:after {
// 	border-radius: 50%;
// 	width: 1em;
// 	height: 1em;
// }
// .loader {
// 	font-size: 10px;
// 	position: relative;
// 	text-indent: -9999em;
// 	border-top: 1.1em solid rgba(255, 255, 255, 0.2);
// 	border-right: 1.1em solid rgba(255, 255, 255, 0.2);
// 	border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
// 	border-left: 1.1em solid #ffffff;
// 	-webkit-transform: translateZ(0);
// 	-ms-transform: translateZ(0);
// 	transform: translateZ(0);
// 	-webkit-animation: load8 1.1s infinite linear;
// 	animation: load8 1.1s infinite linear;
// }
// @-webkit-keyframes load8 {
// 	0% {
// 		-webkit-transform: rotate(0deg);
// 		transform: rotate(0deg);
// 	}
// 	100% {
// 		-webkit-transform: rotate(360deg);
// 		transform: rotate(360deg);
// 	}
// }
// @keyframes load8 {
// 	0% {
// 		-webkit-transform: rotate(0deg);
// 		transform: rotate(0deg);
// 	}
// 	100% {
// 		-webkit-transform: rotate(360deg);
// 		transform: rotate(360deg);
// 	}
// }

// .sk-cube-grid {
// 	width: 40px;
// 	height: 40px;
// 	margin: 100px auto;
// }

// .sk-cube-grid .sk-cube {
// 	width: 33%;
// 	height: 33%;
// 	background-color: #333;
// 	float: left;
// 	-webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
// 	animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
// }
// .sk-cube-grid .sk-cube1 {
// 	-webkit-animation-delay: 0.2s;
// 	animation-delay: 0.2s;
// }
// .sk-cube-grid .sk-cube2 {
// 	-webkit-animation-delay: 0.3s;
// 	animation-delay: 0.3s;
// }
// .sk-cube-grid .sk-cube3 {
// 	-webkit-animation-delay: 0.4s;
// 	animation-delay: 0.4s;
// }
// .sk-cube-grid .sk-cube4 {
// 	-webkit-animation-delay: 0.1s;
// 	animation-delay: 0.1s;
// }
// .sk-cube-grid .sk-cube5 {
// 	-webkit-animation-delay: 0.2s;
// 	animation-delay: 0.2s;
// }
// .sk-cube-grid .sk-cube6 {
// 	-webkit-animation-delay: 0.3s;
// 	animation-delay: 0.3s;
// }
// .sk-cube-grid .sk-cube7 {
// 	-webkit-animation-delay: 0s;
// 	animation-delay: 0s;
// }
// .sk-cube-grid .sk-cube8 {
// 	-webkit-animation-delay: 0.1s;
// 	animation-delay: 0.1s;
// }
// .sk-cube-grid .sk-cube9 {
// 	-webkit-animation-delay: 0.2s;
// 	animation-delay: 0.2s;
// }

// @-webkit-keyframes sk-cubeGridScaleDelay {
// 	0%,
// 	70%,
// 	100% {
// 		-webkit-transform: scale3D(1, 1, 1);
// 		transform: scale3D(1, 1, 1);
// 	}
// 	35% {
// 		-webkit-transform: scale3D(0, 0, 1);
// 		transform: scale3D(0, 0, 1);
// 	}
// }

// @keyframes sk-cubeGridScaleDelay {
// 	0%,
// 	70%,
// 	100% {
// 		-webkit-transform: scale3D(1, 1, 1);
// 		transform: scale3D(1, 1, 1);
// 	}
// 	35% {
// 		-webkit-transform: scale3D(0, 0, 1);
// 		transform: scale3D(0, 0, 1);
// 	}
// }
// .form-error {
// 	color: #ff6b6b;
// 	background-color: lighten(#ff6b6b, 20);
// 	border-radius: 5px;
// 	padding: 10px 15px;
// 	display: inline-block;
// 	font-size: 1em;
// 	font-weight: bold;
// 	word-wrap: break-word;
// }

// .helptext {
// 	font-size: 0.9em;
// 	color: #ffffff;
// }

// .spinner {
// 	position: relative;
// 	width: 24px;
// 	height: 24px;

// 	div {
// 		box-sizing: border-box;
// 		display: block;
// 		position: absolute;
// 		width: 24px;
// 		height: 24px;
// 		/* margin: 3px; */
// 		border: 3px solid #fff;
// 		border-radius: 50%;
// 		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
// 		border-color: #fff transparent transparent transparent;
// 	}
// 	div:nth-child(1) {
// 		animation-delay: -0.45s;
// 	}
// 	div:nth-child(2) {
// 		animation-delay: -0.3s;
// 	}
// 	div:nth-child(3) {
// 		animation-delay: -0.15s;
// 	}

// 	@keyframes lds-ring {
// 		0% {
// 			transform: rotate(0deg);
// 		}
// 		100% {
// 			transform: rotate(360deg);
// 		}
// 	}
// }

// .buttons_wrapper {
// 	display: flex;
// 	justify-content: space-between;
// 	gap: 0.6250rem;
// }

// .flex {
// 	display: flex;
// 	align-items: center;

// 	&.space-between {
// 		justify-content: space-between;
// 	}
// }

// .form_button {
// 	border: none;

// 	.spinner {
// 		display: none;
// 	}

// 	&[loading="true"] {
// 		.spinner {
// 			display: inline-block;
// 		}
// 	}

// 	a {
// 		color: #ffffff;
// 		&:hover {
// 			color: #ffffff;
// 		}
// 	}

// 	&.fullWidth {
// 		width: 100%;
// 		justify-content: center;
// 	}

// 	&:not(.plain) {
// 		padding: 12.5px 25px;
// 		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
// 		background-color: rgb(50, 70, 99);
// 		font-weight: 500;

// 		&.light {
// 			background-color: #02f4c4;
// 			color: #010520;
// 		}
// 	}
// 	&.plain {
// 		padding: 2px;
// 		box-shadow: none;
// 		background-color: transparent;
// 	}

// 	width: max-content;
// 	/* border-radius: 20%/50%; */
// 	border-radius: 50px;
// 	color: #ffffff;
// 	cursor: pointer;
// 	transition: opacity 250ms, filter 250ms, background-color 250ms;
// 	display: flex;
// 	align-items: center;
// 	gap: 0.3125rem;

// 	.Polaris-Icon {
// 		fill: #ffffff;
// 		height: 0.8438rem;
// 		width: 0.8438rem;
// 	}
// 	&:disabled {
// 		cursor: initial;
// 		filter: brightness(0.5);
// 		pointer-events: none;
// 	}

// 	&:hover:not(:disabled) {
// 		background-color: lighten(#02f4c4, 1.2);
// 		// filter: brightness(1.25);
// 	}
// }

// .form_title {
// 	color: #ffffff;
// 	font-size: 1.0000rem;
// 	font-weight: 500;
// }

// .form_input {
// 	padding: 10px;
// 	border-radius: 0.3125rem;
// 	border: none;
// 	width: 100%;
// 	background-color: #ffffff;
// 	color: rgb(0, 0, 0);

// 	&::placeholder {
// 		color: rgb(150, 150, 150);
// 		font-size: 1.03em;
// 	}
// }

// .handle_wrapper {
// 	display: inline-block;
// 	position: relative;

// 	&::after {
// 		position: absolute;
// 		right: 1em;
// 		transition: all 0.05s ease-in-out;
// 		height: 100%;
// 		margin: auto;
// 		display: flex;
// 		top: 0;
// 		align-items: center;
// 	}

// 	/* handle Firefox (arrows always shown) */
// 	@supports (-moz-appearance: none) {
// 		&::after {
// 			right: 2em;
// 		}
// 	}

// 	.form_input {
// 		height: 35px;
// 	}
// 	// &::after {
// 	// 	content: "${({ site }) => site}";
// 	// }
// }

// .form_wrapper {
// 	display: flex;
// 	flex-direction: column;
// 	gap: 0.9375rem;
// 	// max-width: 400px;
// 	// padding: 0.6250rem;
// 	min-width: 400px;
// 	width: 450px;

// 	@media (max-width: 400px) {
// 		min-width: 100%;
// 	}

// 	a {
// 		color: #ffffff;
// 	}

// 	.Polaris-TextField__Backdrop {
// 		border-radius: 50px;
// 	}

// 	.form_wrapper {
// 		width: 100%;
// 	}
// }

// .logo_link {
// 	justify-content: center;
// 	display: none;
// 	position: absolute;
// 	top: 50px;

// 	@media (max-width: 1000px) {
// 		display: flex;
// 	}

// 	@media (max-height: 1000px) {
// 		margin-top: 0;
// 		margin-bottom: 0;
// 	}
// 	@media (max-height: 700px) {
// 		display: none;
// 	}
// }

// .logo {
// 	object-fit: contain;
// 	height: 50px;
// 	// width: 100%;
// }

// .right_wrapper {
// 	// background-color: $backgroundColor;
// 	flex: 1;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	transition: width 500ms;
// 	min-width: 600px;
// 	z-index: 2;
// 	position: relative;
// 	width: 50%;

// 	@media (max-width: 600px) {
// 		max-width: 100%;
// 		min-width: unset;
// 	}
// 	@media (max-width: 750px) {
// 		.Polaris-Tabs__Tab,
// 		.Polaris-Subheading,
// 		.Polaris-Label__Text,
// 		.Polaris-Select__Content,
// 		.Polaris-Choice__Label,
// 		.Polaris-Button__Content {
// 			font-size: 0.6250rem;
// 		}

// 		.Polaris-Tabs__Title {
// 			padding: 0.3125rem 0.6250rem;
// 		}

// 		.Polaris-Select__Input {
// 			font-size: 2.1875rem;
// 		}
// 	}

// 	.info-view {
// 		.info-view__labels {
// 			color: #7cd6c1;
// 		}

// 		.info-view__values {
// 			color: #ffffff;
// 			text-decoration: none;

// 			a {
// 				color: #ffffff;
// 			}
// 		}
// 	}
// }

// .left_wrapper_inner2 {
// 	display: flex;
// 	// align-items: center;
// 	justify-content: center;
// 	gap: 3.1250rem;
// 	flex-direction: column;
// 	transition: opacity 500ms;
// 	opacity: 0;
// 	position: absolute;

// 	&.first {
// 		width: calc(550px - 6.2500rem);

// 		@media (max-width: 1575px) {
// 			width: calc(100% - 6.2500rem);
// 		}
// 	}

// 	&.second {
// 		// max-width: max-content;
// 		max-width: 850px;
// 		min-width: 850px;

// 		@media (max-width: 1500px) {
// 			min-width: unset;
// 			width: calc(100% - 6.2500rem);
// 		}
// 		@media (max-width: 850px) {
// 			max-width: max-content;
// 		}
// 	}

// 	&.active {
// 		opacity: 1;
// 	}
// }

// .left_wrapper {
// 	height: 100%;
// 	// width: 550px;
// 	object-fit: cover;
// 	transition: width 500ms;
// 	// overflow: hidden;
// 	// max-width: 30%;
// 	position: relative;
// 	z-index: 1;
// 	color: #ffffff;
// 	padding: 3.1250rem;

// 	width: 50%;
// 	align-items: center;
// 	justify-content: center;
// 	display: flex;

// 	@media (max-width: 1000px) {
// 		display: none;
// 	}

// 	h1 {
// 		font-weight: 700;
// 		font-size: 76px;
// 		line-height: 110%;
// 	}

// 	p {
// 		font-size: 0.6875rem;
// 		line-height: 0.7812rem;
// 	}

// 	// @media (max-width: 1400px) {
// 	// 	width: 0%;
// 	// 	display: none;
// 	// }
// }

// .wrapper {
// 	height: 100vh;
// 	max-width: 1140px;
// 	min-height: 609px;
// 	margin: auto;
// 	display: flex;
// 	flex-direction: row;
// 	justify-content: space-between;
// }

// input[type="checkbox"] {
// 	height: 1.0938rem;
// 	width: 1.0938rem;
// 	border-radius: 3px;
// 	// appearance: none;
// 	// -webkit-appearance: none;
// }

// .text {
// 	font-size: 1.1em;
// 	font-weight: 500;
// }
// .boxes {
// 	display: flex;
// 	flex-wrap: wrap;
// 	gap: 2.5000rem;

// 	@media (max-width: 1450px) {
// 		display: none;
// 	}

// 	.box {
// 		width: 200px;

// 		h2 {
// 			font-weight: bold;
// 			font-size: 0.8750rem;
// 			margin: 0.6250rem 0;
// 		}

// 		p {
// 			line-height: 1.5em;
// 			font-size: 0.6875rem;
// 		}
// 	}
// }

// .background_video {
// 	width: calc(100% - 500px);
// 	height: 100%;
// 	object-fit: cover;
// 	position: fixed;

// 	@media (max-width: 1000px) {
// 		display: none;
// 	}
// }

// // .slide {
// // 	opacity: 0;
// // 	pointer-events: none;
// // 	transition: opacity 500ms;
// // 	flex-direction: column;

// // 	position: absolute;
// // 	inset: 0;
// // 	display: flex;
// // 	justify-content: center;
// // 	align-items: center;

// // 	&.active {
// // 		opacity: 1;
// // 		pointer-events: all;
// // 	}

// // 	&.second {
// // 		.form_wrapper {
// // 			width: 90%;
// // 			max-width: 90%;
// // 		}

// // 		@media (max-width: 1000px) {
// // 			.form_wrapper {
// // 				width: 100%;
// // 				max-width: 100%;
// // 				padding: 0;
// // 				position: absolute;
// // 				bottom: 0;
// // 			}
// // 		}
// // 	}
// // }

// input[type="checkbox"] {
// 	filter: sepia(0.1) hue-rotate(312deg) brightness(1.35);
// }

// p.label {
// 	color: #ffffff;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// p {
// 	margin: 0;
// }
