.documents {
	padding: 20px;
	position: relative;
	height: 100%;

	.header {
		display: flex;
		align-items: center;
		width: 100%;
		margin-bottom: 2.5000rem;

		.icon-with-background__wrapper svg {
			fill: none !important;
		}
	}

	.closeButton {
		position: absolute;
		right: 0.6250rem;
		top: 0.6250rem;
		padding: 0.6250rem;
	}

	.documents_list {
		display: flex;
		flex-direction: column;
		gap: 1.2500rem;
		padding-top: 1.2500rem;
		overflow: auto;
		height: 100vh;
	}
}

.Polaris-Sheet__Container {
	.documents {
		padding-right: 0;
	}
}
