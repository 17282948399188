@import "./variables.scss";

html body {
	.Polaris-Modal-Dialog__Modal,
	.Polaris-Sheet__Container,
	.Polaris-Popover__Wrapper {
		background: $modals-bg;
		// color: $textColor;

		input:not([class^="Polaris-"]),
		textarea,
		select,
		.Polaris-Select .Polaris-Select__Content,
		.Polaris-TextField__Backdrop {
			& {
				background-color: var(--modals-input-bg) !important;
				border-radius: 0.3125rem;
			}
		}

		.board-column {
			border: none;
			background-color: var(--modals-input-bg);
			border-radius: 0.3125rem;
		}
	}
}

.Polaris-Backdrop {
	background-color: rgba(0, 0, 0, 0.75);
}

body[data-lock-scrolling] {
	.Polaris-Frame__Navigation {
		z-index: 99;
	}
}
