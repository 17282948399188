.Banner {
	border-radius: $borderRadius;
	box-shadow: $boxShadow;
	transition: all 250ms;
	padding: 1.2500rem;
	display: flex;
	gap: 1.2500rem;
	align-items: center;
	position: relative;

	.icon-with-background__wrapper svg {
		filter: brightness(1.1);
	}

	.Banner__content__buttons {
		display: flex;
		align-items: center;
		gap: 1.2500rem;
		margin-top: 1.2500rem;
	}

	.Banner__content {
		display: flex;
		flex-direction: column;
		flex: 1;

		.Banner__content__title {
			font-size: 1.3em;
			font-weight: 600;
			line-height: 1.3em;
		}
	}

	.Banner__close-btn {
		position: absolute;
		padding: 0.6250rem;
		right: 0;
		top: 0;
		cursor: pointer;
		transition: filter 250ms;

		&:hover {
			filter: brightness(1.25);
		}
	}
}
