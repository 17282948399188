.animated {
	animation-duration: 1s;
	animation-fill-mode: both;

	&.bounceIn {
		animation-duration: 0.7s;
	}

	&.bounceOut {
		animation-duration: 0.5s;
	}

	&.bounceIn {
		animation-name: bounceIn;
	}

	&.bounceOut {
		animation-name: bounceOut;
	}

	&.fadeIn {
		animation-name: fadeIn;
		animation-duration: 0.7s;
	}

	&.fadeOut {
		animation-name: fadeOut;
		animation-duration: 0.3s;
	}

	&.bounceInDown {
		animation-name: bounceInDown;
	}

	&.bounceOutUp {
		animation-name: bounceOutUp;
	}
}

@keyframes bounceIn {
	from,
	20%,
	40%,
	60%,
	80%,
	to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	0% {
		opacity: 0;
		transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
	}

	20% {
		transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
	}

	40% {
		transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
	}

	60% {
		opacity: 1;
		transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
	}

	80% {
		transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
	}

	to {
		opacity: 1;
		transform: perspective(1px) scale3d(1, 1, 1);
	}
}

@keyframes bounceOut {
	20% {
		transform: scale3d(0.9, 0.9, 0.9);
	}

	50%,
	55% {
		opacity: 1;
		transform: scale3d(1.1, 1.1, 1.1);
	}

	to {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes bounceInDown {
	from,
	60%,
	75%,
	90%,
	to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	0% {
		opacity: 0;
		transform: translate3d(0, -3000px, 0);
	}

	60% {
		opacity: 1;
		transform: translate3d(0, 25px, 0);
	}

	75% {
		transform: translate3d(0, -10px, 0);
	}

	90% {
		transform: translate3d(0, 5px, 0);
	}

	to {
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes bounceOutUp {
	20% {
		transform: translate3d(0, -10px, 0);
	}

	40%,
	45% {
		opacity: 1;
		transform: translate3d(0, 20px, 0);
	}

	to {
		opacity: 0;
		transform: translate3d(0, -2000px, 0);
	}
}
