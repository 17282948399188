// $CalendarWidth: 550px;
$ContainerPadding: 15px;
$padding: 1.8750rem;
$paddingVertical: 1.2500rem;
$CalendarWidth: 400px;
$dashboardTabsHeight: 65px;

.mainDashboard {
	background: $main1;
	padding: $paddingVertical $padding;
	padding-top: 0.6250rem;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	// .dashboardLeftBox,
	// .calendarContainer,
	// .GoalsMainContainer {
	// 	padding-top: 3.1250rem;
	// }

	&.me {
		.calendar {
			min-height: 371px;
		}
	}

	&.notiser {
		.Polaris-Page-Header.Polaris-Page-Header--hasNavigation {
			padding-top: 0 !important;
		}
	}
}

div.dashboardContainer {
	gap: $padding;
	display: flex;

	h1,
	h2 {
		font-weight: bold;
		font-size: 1.5625rem;
	}

	.left {
		gap: $padding;
		width: 50%;
		display: flex;
		flex: 1;
		flex-direction: column;
		max-width: 650px;
		min-width: 400px;
	}
	.right {
		gap: $padding;
		width: 50%;
		display: flex;
		flex: 1;
		flex-direction: column;
	}

	.GoalsMainContainer {
		grid-area: right;
		padding-top: 10px;

		min-height: 534px;

		.goal-box {
			min-height: 219px;

			.goal-box-diagram-inner {
				min-height: 140px;
			}
		}
	}

	div.WeeklyTodoView {
		grid-area: left;
		grid-row: 2;
	}

	.calendarContainer {
		grid-area: left;
	}

	.subHeader {
		font-size: 1.2500rem;
		margin: 0;
	}

	@media (max-width: 1200px) {
		grid-template-columns: auto;
		grid-template-areas: "goals" "calendar" "todo";
	}
}

div.WeeklyTodoView {
	.Polaris-Page {
		margin: 0;
		max-width: unset;
		padding: 0;

		.Polaris-Page__Content {
			margin: 0;
		}
	}
}

.calendarContainer {
	grid-area: calendar;

	.reconnect {
		position: relative;
		display: flex;
		align-items: center;

		.Polaris-Icon {
			height: 1.5625rem;
			width: 1.5625rem;
			svg {
				fill: $red;
			}
		}
		animation: $pulseAnimation;
	}
	.connect {
		position: relative;
		display: flex;
		align-items: center;

		.Polaris-Icon {
			height: 1.3750rem;
			width: 1.3750rem;
			margin-right: 3px;
			svg {
				fill: $textColor;
			}
		}
	}
}

.upcomingEvents {
	// padding: $ContainerPadding;
	// width: $CalendarWidth;

	.calendarList {
		// padding: 10px;
		display: flex;
		// font-size: 0.6250rem;
		flex-direction: column;
		// gap: 25px;
		// padding-top: 25px;
		margin-top: 2px;

		.Polaris-Spinner {
			align-self: center;
		}

		.calendarListHeader {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 5px 10px;

			h1 {
				font-size: 1.3750rem;
				font-weight: bold;
			}

			a {
				color: $textColor;
				font-size: 0.7500rem;
			}
		}

		.listItem {
			display: grid;
			grid-template-columns: 60px auto max-content;
			grid-template-areas: "icon text arrow";
			gap: 1.2500rem;

			.icon {
				grid-area: icon;
				background-color: $linkColor;
				border-radius: 50%;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				-ms-border-radius: 50%;
				-o-border-radius: 50%;
				position: relative;
				width: 100%;
				padding-top: 100%;

				& > span {
					position: absolute;
					top: 50%;
					right: 50%;
					fill: #ffffff;
					transform: translate(50%, -50%) scale(1.5);
					-webkit-transform: translate(50%, -50%) scale(1.5);
					-moz-transform: translate(50%, -50%) scale(1.5);
					-ms-transform: translate(50%, -50%) scale(1.5);
					-o-transform: translate(50%, -50%) scale(1.5);
				}
			}
			.text {
				grid-area: text;
				display: flex;
				flex-direction: column;
				overflow: hidden;

				h1 {
					font-size: 1.8em;
					font-weight: bold;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				time,
				p {
					font-size: 1.25em;
				}
			}
			.arrow {
				grid-area: arrow;
				fill: #ffffff;
				display: flex;
				transform: scale(1.5);
				-webkit-transform: scale(1.5);
				-moz-transform: scale(1.5);
				-ms-transform: scale(1.5);
				-o-transform: scale(1.5);
			}
		}
	}
}

.calendar {
	// .react-datepicker__day,
	// .react-datepicker__day:hover,
	// .react-datepicker__month-text:hover,
	// .react-datepicker__quarter-text:hover,
	// .react-datepicker__year-text:hover {
	// 	border-radius: 5px;
	// 	-webkit-border-radius: 5px;
	// 	-moz-border-radius: 5px;
	// 	-ms-border-radius: 5px;
	// 	-o-border-radius: 5px;
	// }

	.react-datepicker__day.calendar-day,
	.react-datepicker__day-name {
		width: 47px;
		height: 47px;
	}
}

.DashboardWelcomeHeader {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	// height: 200px;
	// padding: $paddingVertical calc(#{$padding} + 0.3125rem) $paddingVertical;
	// padding: $paddingVertical calc(#{$padding} + 0.3125rem) $paddingVertical;

	.update-profile-image__button {
		position: absolute;
		bottom: 0;
		right: 0;
		opacity: 0.5;
		transition: opacity 250ms;
		transform: translate(25%, 25%);

		&.no_avatar {
			opacity: 0.85;
		}

		&.active,
		&:hover {
			opacity: 1;
		}
	}

	.leftContainer {
		display: flex;
		flex-direction: row;
	}

	.Polaris-Avatar,
	.profile {
		width: 80px;
		height: 80px;
		object-fit: cover;
		border-radius: 50%;
	}

	.text {
		display: flex;
		flex-direction: column;
		padding-left: 50px;

		h1 {
			font-size: 1.8750rem;
			line-height: 1.8750rem;
			font-weight: 700;
			margin-bottom: 0.7500rem;
		}

		p {
			font-size: 0.7812rem;
			line-height: 0.7812rem;
			font-weight: 500;
		}
	}
}

.DashboardTabs {
	// width: calc(100% - #{$padding} * 2);
	width: 100%;
	display: flex;
	background: $cardbg;
	height: $dashboardTabsHeight;
	padding-left: 0.6250rem;
	padding-right: 0.6250rem;
	// overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	color: $textColor;
	border-radius: $borderRadius;
	box-shadow: $boxShadow;
	margin: $paddingVertical auto;
	position: relative;
	transition: height 500ms, margin 500ms, opacity 500ms, visibility 0ms ease-in 500ms;

	&::-webkit-scrollbar {
		display: none;
	}

	.dismiss_button {
		position: absolute;
		right: 5px;
		top: 5px;
		padding: 5px;
		cursor: pointer;

		svg {
			height: 0.9375rem;
			width: 0.9375rem;
		}
	}

	.DashboardTabs-tabs {
		display: flex;
		align-items: center;
		height: 100%;
		width: calc(100% - 40px);
		overflow: hidden;
		// position: relative;

		.overflow-button {
			padding: 0 0.6250rem;
			box-shadow: none;
			height: 100%;

			position: absolute;
			right: 0;
			top: 0;
			width: 40px;
			background: var(--cardbg);

			svg {
				transition: fill 250ms;
			}

			&:hover {
				svg {
					fill: $green;
				}
			}
		}
		.overflow-button-parent {
			height: calc(100% - 1.2500rem);
		}

		> .dropdown-trigger {
			align-self: center;
			height: 100%;
			display: flex;
			align-items: center;

			&.inVisible {
				visibility: hidden;
				order: 100;
				opacity: 0;
				pointer-events: none;
			}

			&.visible {
				order: 0;
				opacity: 1;
				visibility: visible;
				pointer-events: auto;
			}
			// .inVisible & {
			// 	visibility: hidden;
			// 	order: 100;
			// 	opacity: 0;
			// 	pointer-events: none;
			// }

			// .visible & {
			// 	order: 0;
			// 	opacity: 1;
			// 	visibility: visible;
			// 	pointer-events: auto;
			// }
		}

		// > div:not(.item) {
		// 	align-self: center;
		// 	height: 100%;
		// 	display: flex;
		// 	align-items: center;

		// }
	}

	> div {
		align-self: center;
	}

	// > div:not(.item) {

	.item2 {
		position: relative;
		// height: calc(100% - 1.2500rem);
		// top: $dashboardTabsHeight;

		.tab-dropdown {
			position: absolute;
			top: calc(100% + 0.6250rem);
			background: $main2;
			border-radius: $borderRadius;
			box-shadow: $boxShadow;
		}

		.item {
			height: 100%;
		}
		&.visible {
			order: 0;
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
		}
		&.inVisible {
			visibility: hidden;
			order: 100;
			opacity: 0;
			pointer-events: none;
		}
	}

	> div:not(.item) {
		.item.visible & {
			order: 0;
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}
		.item.inVisible & {
			visibility: hidden;
			order: 100;
			opacity: 0;
			pointer-events: none;
		}
	}

	.item {
		height: calc(100% - 1.2500rem);
		position: relative;
		align-items: center;
		display: flex;
		justify-items: center;
		//padding: 1.2500rem 2.5000rem;
		font-size: 0.8125rem;
		font-weight: 600;
		cursor: pointer;
		transition: color 250ms, background-color 250ms;
		border-radius: $borderRadius;
		margin: 0.0000rem 1.8750rem;
		user-select: none;

		// flex-shrink: 0;
		white-space: nowrap;

		.dropdown-open-icon {
			transition: transform 250ms;
			color: $textColor;
			fill: $textColor;

			svg {
				transition: fill 250ms;
			}
		}

		&.active {
			color: $green;
			background-color: var(--cardbg);
			box-shadow: $boxShadow;
			margin: 0;
			padding: 0.0000rem 1.8750rem;

			.dropdown-open-icon {
				svg {
					fill: $green;
				}
			}
		}

		&.visible {
			order: 0;
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}
		&.inVisible {
			visibility: hidden;
			order: 100;
			opacity: 0;
			pointer-events: none;
		}

		&:hover {
			color: $green;

			.dropdown-open-icon {
				svg {
					fill: $green;
				}
			}
		}
	}

	.tab-dropdown {
		position: absolute;
		background: $main2;
	}
}

body[theme="dark"] .item.active {
	background: $main1;
}

.dropdown-item {
	font-weight: 600;
	display: flex;

	&:hover,
	&.active {
		color: $green !important;
	}
}

.dashboard-favorite-button-wrapper {
	display: flex;
	align-items: center;
}

.dashboard-favorite-button {
	cursor: pointer;

	.Polaris-Icon {
		height: 25px;
		width: 25px;

		svg {
			transition: fill 250ms;
		}
	}

	&.active svg {
		fill: $yellow;
	}

	&:hover {
		filter: brightness(1.2);

		svg {
			fill: $yellow;
		}
	}
}

.dashboard-container {
	.dashboard-header__firstrow,
	.dashboard-header__secondrow {
		margin: 5px 0;
	}

	.dashboard-header__firstrow {
		display: flex;
		align-items: center;

		.dashboard-header__title {
			flex-grow: 1;
			display: flex;
			align-items: center;
			gap: 0.6250rem;

			h1,
			.overflow-ellipsis.board-tabbable.board-editable {
				font-size: 1.2500rem;
				font-weight: 600;
				line-height: 1.2500rem;
				width: max-content !important;
			}
		}
	}
	.dashboard-header__secondrow {
		display: flex;
		align-items: center;
		gap: 0.6250rem;

		.restActions-button {
			display: flex;
			gap: 2px;
			cursor: pointer;

			.Polaris-Icon svg {
				color: rgb(135, 135, 135);
			}
		}
	}
}

.dashboard-header.fixed-column {
	z-index: 150;
	margin-bottom: 35px;
}

.dashboard-view-edit-button {
	margin: 0;
	display: flex;
	align-items: center;
	z-index: 101;

	&:not(:disabled) {
		cursor: pointer;
	}

	.Polaris-Icon {
		width: 16px;
		height: 16px;

		svg {
			transition: filter 250ms;
			fill: $textColor;
		}
	}

	&:hover {
		filter: brightness(1.2);
	}
}

.Polaris-SkeletonPage--fullWidth {
	width: 100%;
}

// [id^="PolarisTooltipContent"] {
// 	height: max-content;
// }

html body div#root .mainDashboard .dashboard_tab_selector {
	margin-bottom: 0.6250rem;
	display: flex;
	align-items: center;
	.dashboard_tab_selector_label {
		color: $textColor2;
	}
	.Polaris-Select__Content {
		color: $textColor;
		padding-left: 0.5000rem;
	}
	.dashboard_tab_selector_label,
	.Polaris-Select__Content {
		font-weight: 500;
		font-size: 13px;
		line-height: 15px;
	}

	.Polaris-Select {
		width: max-content;

		.Polaris-Select__SelectedOption {
			padding-right: 0.1875rem;
		}

		.Polaris-Select__Icon svg {
			fill: var(--textColor);
		}

		.Polaris-Select__Backdrop,
		.Polaris-Select__Backdrop::after,
		.Polaris-Select__Content {
			background: transparent;
			border: none;
			box-shadow: none;
		}
	}
}
